import React from 'react';
import { Box } from '@mui/system';

import Button from '../components/Button';
import ContentWrapper from '../components/ContentWrapper';
import PaypalPayment from '../components/PaypalPayment';
import SearchSelectField2 from '../components/SearchSelectField2';
import Txt from '../components/Txt';
import { useAllMemberships } from '../utils/membership';
import { useAllProducts } from '../utils/product';
import { useSnackbar } from '../utils/snackBar';
import { useCurrentUser } from '../utils/user';
import validatePaypalSubscription from '../mutations/validatePaypalSubscription';
import cancelPaypalSubscription from '../mutations/cancelPaypalSubscription';

const CheckoutView = () => {
	const snackBar = useSnackbar();
	const products = useAllProducts();
	const memberships = useAllMemberships();
	const currentUser = useCurrentUser();
	const subscriptions = currentUser?.subscriptions;

	const [loading, setLoading] = React.useState(false);

	const [productIds, setProductIds] = React.useState<string[]>([]);
	const handleChangeProducts = React.useCallback((items: { label: string; value?: string }[]) => {
		const ids = items.map(({ value }) => value) as string[];
		setProductIds(ids);
	}, []);

	const [membershipId, setMembershipId] = React.useState<string | undefined>(undefined);
	const handleChangeMembership = React.useCallback((items: { label: string; value?: string }[]) => {
		const id = !items.length ? undefined : items.map(({ value }) => value)[0];
		setMembershipId(id);
	}, []);

	const handleSuccess = React.useCallback(
		(paymentType: string) => {
			snackBar({ success: paymentType + ' erfolgreich' });
		},
		[snackBar]
	);

	const handleCheckSubscription = React.useCallback(
		async (id) => {
			setLoading(true);
			const { status } = await validatePaypalSubscription({ paymentId: id });
			snackBar({ info: `status: ${status}` });
			setLoading(false);
		},
		[snackBar]
	);

	const handleCancelSubscription = React.useCallback(
		async (id) => {
			setLoading(true);
			const { error } = await cancelPaypalSubscription({ paymentId: id });
			if (error) {
				setLoading(false);
				snackBar({ error: error });
				return;
			}
			snackBar({ info: `Subscription gecancelt` });
			setLoading(false);
		},
		[snackBar]
	);

	return (
		<ContentWrapper center p="1rem">
			{subscriptions?.length ? (
				<>
					<Txt>Subscriptions: </Txt>
					{subscriptions?.map((subscription) => (
						<Box key={subscription} display="flex" flexDirection="row" alignItems="center">
							<Txt m="0 0.5rem 0 0">{subscription}</Txt>
							<Button
								m="1rem"
								variant="secondButton"
								id={subscription}
								onClick={handleCheckSubscription}
								loading={loading}
							>
								Check Status
							</Button>
							<Button
								variant="secondButton"
								id={subscription}
								onClick={handleCancelSubscription}
								loading={loading}
							>
								Cancel Subscription
							</Button>
						</Box>
					))}
				</>
			) : null}
			<SearchSelectField2
				label="Produkte"
				options={products}
				onChange={handleChangeProducts}
				m="1rem"
				width="100%"
				multiple
				labelKey="title"
				valueKey="id"
				disabled={Boolean(membershipId)}
			/>
			<SearchSelectField2
				label="Mitgliedschaften"
				options={memberships}
				onChange={handleChangeMembership}
				m="1rem"
				width="100%"
				labelKey="title"
				valueKey="id"
				disabled={Boolean(productIds.length)}
			/>
			<PaypalPayment
				onSuccess={handleSuccess}
				productIds={productIds}
				membershipId={membershipId}
			/>
		</ContentWrapper>
	);
};

export default CheckoutView;
