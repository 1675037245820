import { isArray, isEqual } from 'lodash';
import Fuse from 'fuse.js';

const deaultOptions = {
	shouldSort: true,
	threshold: 0.2,
	location: 0,
	tokenize: true,
	matchAllTokens: true,
	findAllMatches: true,
	distance: 20,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	includeScore: true,
};

// this caches the last list of items for searches, until called with different items
const cache: {
	fuse?: any;
	items?: unknown[];
	options?: unknown;
} = {};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export default function fuzzySearch<Item>(
	items: Item[],
	query: string,
	keys: string | string[],
	options?: { [prop: string]: boolean | number },
	noDefaultOptions?: boolean
): { item: Item; score?: number }[] {
	const mergedOptions = noDefaultOptions
		? { keys: isArray(keys) ? keys : [keys], ...options }
		: { keys: isArray(keys) ? keys : [keys], ...deaultOptions, ...options };
	if (!cache.fuse || cache.items !== items || !isEqual(cache.options, mergedOptions)) {
		cache.items = items;
		cache.options = mergedOptions;
		cache.fuse = new Fuse(items, mergedOptions);
	}
	return cache.fuse.search(query.trim());
}
