import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import Contents from '../components/Contents';
import EducationFormatCategoriesSection from '../components/EducationFormatCategoriesSection';
import SectionHeader from '../components/SectionHeader';
import CategoriesRow from '../components/CategoriesRow';
import { useCurrentUser } from '../utils/user';
import { useApiFilteredContents, useRecommendedApiContents } from '../utils/content';
import BildungsfreaksFavouritesSection from '../components/BildungsfreaksFavouritesSection';

import teamwork5Image from '../images/BusinessTeamwork5.png';

import { useAvailableCategories } from '../utils/category';
import MarkdownTxt from '../components/MarkdownTxt';
import SectionWithoutBackground from '../components/SectionWithoutBackground';

const LoggedInUserView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();

	// const progressedContents = React.useMemo(
	// 	() =>
	// 		currentUser?.progressedContents
	// 			.filter((c) => c.completion !== 1)
	// 			.sort((a, b) => (a.lastProgressedAt! > b.lastProgressedAt! ? -1 : 1)),
	// 	[currentUser?.progressedContents]
	// );

	const demoContents = useApiFilteredContents({
		categoryTypeFilters: 'COMPANY',
		userHasProgress: false,
		contentIsActive: true,
		contentIsContainedInProducts: true,
		sortAfterPriority: true,
		contentIsDemo: true,
	});
	// const availableCategories = useAvailableCategories('COLLECTION', demoContents);
	const accessibleProductContents = currentUser?.accessibleProductContents;

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teamwork5Image}
				title={`Hallo ${currentUser?.userName || currentUser?.firstName || ''}`}
				subTitle="Herzlich willkommen zu Ihrem Weiterbildungsportal. Gestalten Sie Ihre Zukunft mit Wissen und Kompetenz."
			/>
			{/* {progressedContents?.length ? (
				<SectionWithoutBackground flexDirection="column">
					<Contents
						filterAfterCategoryGroup="EDUCATION_FORMAT"
						title="Meine aktiven Kurse"
						subTitle="An diesen Kursen bist du dran"
						contents={progressedContents}
						showProgress
					/>
				</SectionWithoutBackground>
			) : null} */}
			{
				<SectionWithoutBackground flexDirection="column">
					<Contents
						title="Meine Kurse"
						subTitle="Diese Kurse wurden für Sie freigeschaltet"
						contents={accessibleProductContents?.length ? accessibleProductContents : demoContents}
						showProgress
					/>
				</SectionWithoutBackground>
			}
			<SectionWithoutBackground>
				<MarkdownTxt>
					Sie haben technische Fragen zum Bildungsportal? Sprechen Sie uns an! Wir helfen Ihnen
					gerne weiter: [kontakt@bildungsfreaks.de](mailto:kontakt@bildungsfreaks.de)
				</MarkdownTxt>
			</SectionWithoutBackground>
			{/* {currentUser.audienceType === 'TEACHER' || currentUser.audienceType === 'PARENTS' ? (
				<EducationFormatCategoriesSection />
			) : null}
			<SectionWithBackground flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title="Mach bildungsfreaks zu deinem Ding!"
					subTitle="Finde die passenden Kurse nach Themengiebieten"
					hasBottomMargin
				/>
				<CategoriesRow categories={availableCategories || undefined} />
			</SectionWithBackground>
			{currentUser.audienceType === 'TEACHER' || currentUser.audienceType === 'PARENTS' ? (
				<BildungsfreaksFavouritesSection />
			) : null} */}
		</ContentWrapper>
	);
};

export default LoggedInUserView;
