import React from 'react';

import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import teamworkImage from '../images/BusinessTeamwork4.png';
import SectionHeader from '../components/SectionHeader';
import FilterRow from '../components/FilterRow';
import Contents from '../components/Contents';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import { useCurrentUser } from '../utils/user';
import CertificatesSection from '../components/CertificatesSection';

const filterOptions: { label: string; value: 'COMPLETED' | 'ACTIVE' | 'DOWNLOADS' }[] = [
	{ label: 'Aktiv', value: 'ACTIVE' },
	{ label: 'Downloads', value: 'DOWNLOADS' },
	{ label: 'Absolviert', value: 'COMPLETED' },
];

const subTitleDict = {
	ACTIVE:
		'Fast geschafft – diese Kurse haben Sie begonnen, bisher aber nicht abgeschlossen. Weiter geht‘s!',
	COMPLETED: 'Diese Kurse haben Sie erfolgreich absolviert.',
	DOWNLOADS: 'Alles downloadbaren Materialien finden Sie hier.',
};

const DashboardView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();

	const [state, setState] = React.useState<undefined | 'COMPLETED' | 'ACTIVE' | 'DOWNLOADS'>(
		undefined
	);
	const handleChange = React.useCallback((value) => {
		setState(value);
	}, []);

	const progressedContents = React.useMemo(
		() =>
			currentUser?.progressedContents
				.filter((c) => c.completion !== 1)
				.sort((a, b) => (a.lastProgressedAt! > b.lastProgressedAt! ? -1 : 1)),
		[currentUser?.progressedContents]
	);
	const completedContents = React.useMemo(
		() =>
			currentUser?.progressedContents
				.filter((c) => c.completion === 1)
				.sort((a, b) => (a.lastProgressedAt! > b.lastProgressedAt! ? -1 : 1)),
		[currentUser?.progressedContents]
	);

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teamworkImage}
				title="Mein Dashboard"
				subTitle="In diesem Bereich behalten Sie alles im Blick."
			/>
			<SectionWithoutBackground flexDirection="column">
				<SectionHeader hasBottomMargin alignItems="flex-start" title="Meine Lernaktivitäten" />
				<FilterRow defaultValue="ACTIVE" options={filterOptions} onChange={handleChange} />
				<Contents
					m="2rem 0 0 0"
					subTitle={!state ? undefined : subTitleDict[state]}
					contents={
						state === 'ACTIVE'
							? progressedContents
							: state === 'COMPLETED'
							? completedContents
							: currentUser?.downloadedContents
					}
					showProgress={state === 'ACTIVE' || state === 'COMPLETED'}
				/>
			</SectionWithoutBackground>
			<CertificatesSection />
		</ContentWrapper>
	);
};

export default DashboardView;
