import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';

import { useSnackbar } from '../utils/snackBar';
import { triggerDownload } from '../utils/download';
import SectionHeader from './SectionHeader';
import Button from './Button';
import Txt from './Txt';
import createCertificateDownloadLink from '../mutations/createCertificateDownloadLink';
import { useCurrentUser } from '../utils/user';
import { getDate } from '../utils/time';
import SectionWithBackground from './SectionWithBackground';
import SimpleTable4 from './SimpleTable4';

const PdfIcon = styled(PictureAsPdf)`
	color: red;
`;

const CertificatesSection = () => {
	const snackBar = useSnackbar();
	const currentUser = useCurrentUser();
	const certificates = currentUser?.certificates;
	const sortedCertificates = React.useMemo(() => {
		const copy = certificates ? [...certificates] : null;
		return copy?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
	}, [certificates]);

	const handleDownloadPdf = React.useCallback(
		async (certId) => {
			const { success, error, presignedUrl } = await createCertificateDownloadLink(certId);
			if (success) {
				triggerDownload(presignedUrl);
			} else {
				snackBar({ error });
			}
		},
		[snackBar]
	);

	const renderActions = React.useCallback(
		({ id }) => (
			<Box display="flex" flexDirection="row" alignItems="center">
				<Button variant="secondButton" onClick={handleDownloadPdf} id={id}>
					Download
				</Button>
			</Box>
		),
		[handleDownloadPdf]
	);

	const tableData = React.useMemo(() => {
		return !sortedCertificates?.length
			? undefined
			: sortedCertificates.map((certificate) => {
					return {
						'id': certificate.id,
						'Name': (
							<Box display="flex" flexDirection="row" alignItems="center">
								<Box m="0 0.5rem 0 0">
									<PdfIcon />
								</Box>
								<Txt fontWeight="bold" numberOfLines={2}>
									{certificate.content.title}{' '}
									{certificate.version > 1 ? `v${certificate.version}` : ''}
								</Txt>
							</Box>
						),
						'Abgeschlossen am': getDate(certificate.createdAt),
					};
			  });
	}, [sortedCertificates]);

	return tableData?.length ? (
		<SectionWithBackground padding="2rem" flexDirection="column">
			<SectionHeader alignItems="flex-start" title="Zertifikate" hasBottomMargin />
			<SimpleTable4 renderData={tableData} headRowFontWeight="bold" renderActions={renderActions} />
		</SectionWithBackground>
	) : null;
};
export default CertificatesSection;
