import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../components/ContentWrapper';

import SectionWithoutBackground from '../components/SectionWithoutBackground';
import SectionHeader from '../components/SectionHeader';
import { useCurrentUser } from '../utils/user';
import { Box } from '@mui/material';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import IconButton from '../components/IconButton';
import UpsertCompanyVolumeLicenseDialog from '../components/UpsertCompanyVolumeLicenseDialog';
import { getDate } from '../utils/time';
import Card from '../components/Card';
import SimpleTable4 from '../components/SimpleTable4';

const Row = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
`;

const KEY_1 = 'Abteilung';

const CompanyVolumeLicensesListView = () => {
	const currentUser = useCurrentUser();
	const companyVolumeLicenses = currentUser?.company?.volumeLicenses;

	const tableData = React.useMemo(
		() =>
			companyVolumeLicenses?.map((volumeLicense) => {
				return {
					'id': volumeLicense.id,
					[KEY_1]: volumeLicense.companyDepartment?.title,
					'Kurse': volumeLicense.products?.map((product) => product.title).join(', '),
					'Anzahl Lizenzen': volumeLicense.remainingSeats + volumeLicense.takenSeats,
					'Aktivierte Lizenzen': volumeLicense.takenSeats,
					'Freie Lizenzen': volumeLicense.remainingSeats,
					'Erstellt am': getDate(volumeLicense.createdAt),
					'Beenden in Tagen': volumeLicense.finishInDays || '',
					'Status': volumeLicense.active ? 'Aktiv' : 'Inaktiv',
				};
			}) || [],
		[companyVolumeLicenses]
	);

	const handleShowUpserVolumeLicenseDialog = React.useCallback((id) => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<UpsertCompanyVolumeLicenseDialog dismissPortal={dismissPortal} id={id} />
			),
			isLocked: true,
		});
	}, []);

	const renderActions = React.useCallback(
		({ id }) => {
			return (
				<Row>
					<IconButton
						m="0 1rem 0 0"
						iconName="EditRounded"
						onClick={handleShowUpserVolumeLicenseDialog}
						id={id}
					/>
				</Row>
			);
		},
		[handleShowUpserVolumeLicenseDialog]
	);

	return (
		<ContentWrapper>
			<SectionWithoutBackground flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title="Lizenzen"
					subTitle="In diesem Bereich können Sie die Lizenzen Ihrer Organisation verwalten. "
				/>
			</SectionWithoutBackground>
			<SectionWithoutBackground justifyContent="flex-end">
				<Button variant="mainButton" onClick={handleShowUpserVolumeLicenseDialog}>
					Neue Lizenz hinzufügen
				</Button>
			</SectionWithoutBackground>
			{!companyVolumeLicenses?.length ? null : (
				<SectionWithoutBackground>
					<Card>
						<SimpleTable4
							renderData={tableData}
							numberOfLines={2}
							renderActions={renderActions}
							headRowFontWeight="bold"
							searchKeys={KEY_1}
							searchableData={tableData}
							sortKey={KEY_1}
						/>
					</Card>
				</SectionWithoutBackground>
			)}
		</ContentWrapper>
	);
};

export default CompanyVolumeLicensesListView;
