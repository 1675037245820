import { useQuery, gql } from '@apollo/client';
import { Category } from './category';
import { ProductDetailsFragment } from './fragments';

export type Product = {
	id: string;
	title: string;
	description?: string;
	priceCents?: number;
	priceLabel: string;
	audienceCategories: Category[];
};

export const useAllProducts = (): Product[] | undefined => {
	const { data } = useQuery(
		gql`
			query {
				products {
					...ProductDetailsFragment
				}
			}
			${ProductDetailsFragment}
		`
	);

	return data?.products;
};

export const useProduct = (id?: string): Product | undefined | null => {
	const { data } = useQuery(
		gql`
			query Product($id: ID!) {
				product(id: $id) {
					...ProductDetailsFragment
				}
			}
			${ProductDetailsFragment}
		`,
		{ variables: { id }, skip: !id }
	);

	return !id ? null : data?.product;
};
