import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { CompanyDepartmentInfoFragment } from './fragments';
import { VolumeLicense } from './volumeLicense';
import { Trainee } from './trainee';

export const COMPANY_DEPARTMENTS_QUERY = gql`
	query CompanyDepartments {
		companyDepartments {
			...CompanyDepartmentInfoFragment
		}
	}
	${CompanyDepartmentInfoFragment}
`;

export const COMPANY_DEPARTMENT = gql`
	query CompanyDepartment($id: ID!) {
		companyDepartment(id: $id) {
			...CompanyDepartmentInfoFragment
		}
	}
	${CompanyDepartmentInfoFragment}
`;

export type Employee = {
	id: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	emailConfirmed?: boolean;
};

export type CompanyDepartment = {
	id: string;
	title: string;
	active: boolean;
	reportExceededDeadlines: boolean;
	admins: {
		id: string;
		email: string;
		firstName: string;
		lastName: string;
	};
	volumeLicenses?: VolumeLicense[];
	trainees?: Trainee[];
	employees?: Employee[];
};

export const useCompanyDepartments = (): undefined | null | CompanyDepartment[] => {
	const { data } = useQuery(COMPANY_DEPARTMENTS_QUERY);
	return React.useMemo(() => data?.companyDepartments, [data?.companyDepartments]);
};

export const useCompanyDepartment = (id?: string): undefined | null | CompanyDepartment => {
	const { data } = useQuery(COMPANY_DEPARTMENT, { variables: { id }, skip: !id });
	return React.useMemo(() => (!id ? null : data?.companyDepartment), [data?.companyDepartment, id]);
};
