import React from 'react';
import styled from 'styled-components';
import { Grid, Box } from '@mui/material';
import { useNavigate } from '../utils/hooks';

import Button from '../components/Button';
import Dialog from '../components/Dialog';
import FilledTextField from '../components/FilledTextField';

import useTextFieldController from '../utils/useTextFieldController';
import { checkValidationFunctions, validateEmail, validatePassword } from '../utils/helpers';
import { fetchUser, setToken } from '../utils/user';
import login from '../mutations/login';
import { useSearchParams } from 'react-router-dom';
import GridContainer from '../components/GridContainer';
import LoginImage from '../images/login-image.png';
import Txt from '../components/Txt';
import SectionHeader from '../components/SectionHeader';

const Background = styled(Box)`
	display: flex;
	flex-direction: column;
	background-color: ${(p) => p.theme.customColors.decentBlue};
	max-width: 450px;
	min-width: 350px;
	padding: 2rem;
	margin: 3rem;
`;
const horizontalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };
const verticalSpacing = { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 };
const OuterWrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1500px;
	padding-top: 1rem;
	flex-wrap: wrap-reverse;
	flex: 1;
`;
const StyledImage = styled.img`
	max-width: 673px;
`;
const Separator = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 3rem;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
`;

const Hline = styled.div`
	display: flex;
	flex-grow: 1;
	min-width: 10px;
	height: 1px;
	background-color: #000000;
	margin: 0 0.5rem 0 0.5rem;
`;

const LoginView = (): JSX.Element => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const contentId = searchParams.get('contentId') || undefined;
	const {
		newStateValue: email,
		textInputProps: emailFieldProps,
		validate: validateEmailFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Email',
		stateKey: 'email',
		validationFunction: validateEmail,
		stateValueFallback: '',
		textFieldType: 'email',
	});

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Passwort',
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const [loading, setLoading] = React.useState(false);

	// const handleSSO = () => {
	// 	Dialog.render({
	// 		title: 'SSO-Login',
	// 		description: 'In diesem Demo System nicht verfügbar',
	// 	});
	// };

	const handleLogin = React.useCallback(async () => {
		const isValid = checkValidationFunctions([validateEmailFn, validatePasswordFn]);
		if (isValid) {
			setLoading(true);
			const { success, jwt, error } = await login(email!, password!);
			setLoading(false);
			if (success) {
				setToken(jwt!);
				await fetchUser();
				navigate('/?initial=true');
			} else {
				switch (error) {
					case 'USER_NOT_FOUND':
						Dialog.render({
							title: 'Fehler',
							description: 'Diese Emailadresse ist uns nicht bekannt.',
						});
						break;
					case 'USER_NOT_VERIFIED':
						Dialog.render({
							title: 'Fehler',
							description: 'Emailadresse wurde noch nicht bestätigt.',
						});
						break;
					case 'CREDENTIALS_DO_NOT_MATCH':
						Dialog.render({
							title: 'Fehler',
							description: 'Email und Passwort stimmen nicht überein.',
						});
						break;
					case 'NETWORK_ERROR':
						Dialog.render({
							title: 'Fehler',
							description: 'Netzwerkfehler',
						});
						break;
					default:
						Dialog.render({
							title: 'Fehler',
							description: 'Es ist ein unbekannter Fehler aufgetreten.',
						});
				}
			}
		}
	}, [email, navigate, password, validateEmailFn, validatePasswordFn]);

	const textFieldsProps = React.useMemo(
		() => [emailFieldProps, passwordFieldProps],
		[emailFieldProps, passwordFieldProps]
	);

	return (
		<OuterWrapper>
			<Grid item lg={8} md={4}>
				<StyledImage src={LoginImage} />
			</Grid>
			<Grid item lg={5} md={8}>
				<Background>
					<SectionHeader title="Login" subTitle="Herzlich willkommen zu Ihrem Bildungsportal. " />
					<GridContainer
						horizontalSpacing={horizontalSpacing}
						verticalSpacing={verticalSpacing}
						p="4rem 2rem"
						excludePadding
					>
						{/* <Button fullWidth onClick={handleSSO} variant="mainButton">
							Mit SSO anmelden
						</Button> */}

						{/* <Separator>
							<Hline />
							<Txt label="Oder mit E-Mail anmelden" />
							<Hline />{' '}
						</Separator> */}
						<>
							{textFieldsProps.map((textFieldProps) => (
								<Grid key={textFieldProps.label} item xs={12}>
									<FilledTextField fullWidth variant="filled" {...textFieldProps} />
								</Grid>
							))}
						</>
						<Grid item xs={12} lg={5}>
							<Button variant="link" navigateTo="/reset-password" disabled={loading}>
								Passwort vergessen?
							</Button>
						</Grid>
						<Grid item xs={12} lg={5}>
							<Button m="0 1rem 0 0 " variant="link" navigateTo="/signup" disabled={loading}>
								Ein Konto erstellen
							</Button>
						</Grid>

						<Grid item xs={12}>
							<Button
								fullWidth
								onClick={handleLogin}
								variant="mainButton"
								loading={loading}
								triggerOnEnter
							>
								Anmelden
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button endIconName="InfoOutlined" navigateTo="/admin/how-it-works" variant="text">
								{`Wie funktioniert's?`}
							</Button>
						</Grid>
					</GridContainer>
				</Background>
			</Grid>
		</OuterWrapper>
	);
};

export default LoginView;
