import React from 'react';
import { createTheme } from '@mui/material/styles';
import type { ThemeOptions as MuiThemeOptions } from '@mui/material/styles';
import { useWindowDimensions } from './hooks';
import { useLocation } from 'react-router-dom';

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		mainButton: true;
		secondButton: true;
		altButton: true;
		loadingMainButton: true;
		loadingButton: true;
		link: true;
		blueText: true;
		boldText: true;
		activeBottomAppBarButton: true;
		inactiveBottomAppBarButton: true;
		activeTopAppBarButton: true;
		inactiveTopAppBarButton: true;
		activeProfileViewMenuButton: true;
		inactiveProfileViewMenuButton: true;
		activeSelectButton: true;
		inactiveSelectButton: true;
	}
}

declare module '@mui/material/styles' {
	interface Theme {
		contentMaxWidthPx: number;
		authViewsMaxWidthPx: number;
		customColors: {
			accent: string;
			orange: string;
			lightOrange: string;
			secondaryGrey: string;
			lightBlue: string;
			white: string;
			lightGrey: string;
			decentGrey: string;
			darkGrey: string;
			typographyGrey: string;
			green: string;
			decentBlue: string;
			violet1: string;
			violet2: string;
			violet3: string;
		};
	}
	interface ThemeOptions {
		customColors?: {
			accent: string;
			orange: string;
			lightOrange: string;
			secondaryGrey: string;
			lightBlue: string;
			white: string;
			lightGrey: string;
			decentGrey: string;
			darkGrey: string;
			typographyGrey: string;
			green: string;
			decentBlue: string;
			violet1: string;
			violet2: string;
			violet3: string;
		};
	}
}

const orange = '#FFC358';
const lightOrange = '#FFD182';
const secondaryGrey = '#575655';
const lightBlue = '#009FE3';
const white = '#ffffff';
const lightGrey = '#E0E0E0';
const decentGrey = '#F4F4F4';
const darkGrey = '#575756';
const typographyGrey = '#575756';
const green = '#2FAC66';
const decentBlue = '#F5F9FD';
const violet1 = '#6F85EE';
const violet2 = '#93A3F2';
const violet3 = '#DBE1FC';

const baseButtonStyle = {
	minWidth: '0px',
	textAlign: 'left',
	borderRadius: '0px',
	color: secondaryGrey,
	backgroundColor: 'none',
	textTransform: 'none',
	padding: '1px',
	fontWeight: 'none',
};

const boldButtonStyle = {
	...baseButtonStyle,
	fontWeight: 'bold',
};

const activeAppBarButtonStyle = {
	'borderRadius': '0.5rem',
	'color': secondaryGrey,
	'textTransform': 'none',
	'padding': '1px 1rem',
	'fontWeight': 'none',
	'&:hover': {
		backgroundColor: 'transparent',
		color: lightBlue,
	},
};

const breakPoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1200,
		xl: 1536,
	},
};

const defaultTheme = {
	...breakPoints,
	customColors: {
		orange,
		lightOrange,
		secondaryGrey,
		lightBlue,
		white,
		lightGrey,
		decentGrey,
		darkGrey,
		typographyGrey,
		green,
		decentBlue,
		violet1,
		violet2,
		violet3,
		accent: orange,
	},
	contentMaxWidthPx: 1440,
	authViewsMaxWidthPx: 600,
	components: {
		MuiCssBaseline: {
			// get rid of scrollbars
			styleOverrides: {
				div: {
					'-ms-overflow-style': 'none', // hide scrollbar in IE
					'scrollbar-width': 'none', // hide scrollbar in Firefox
					'::-webkit-scrollbar': {
						display: 'none', // hide scrollbar in Chrome, Safari and Opera
					},
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'mainButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${lightBlue}`,
						'color': white,
						'backgroundColor': lightBlue,
						'&:hover': {
							border: `1px solid ${secondaryGrey}`,
							color: white,
							backgroundColor: secondaryGrey,
						},
					},
				},
				{
					props: { variant: 'secondButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${darkGrey}`,
						'color': darkGrey,
						'backgroundColor': 'transparent',
						'&:hover': {
							border: `1px solid ${lightBlue}`,
							color: lightBlue,
							backgroundColor: 'transparent',
						},
					},
				},
				{
					props: { variant: 'altButton' },
					style: {
						'textTransform': 'none',
						'border': `1px solid ${secondaryGrey}`,
						'color': secondaryGrey,
						'backgroundColor': white,
						'&:hover': {
							border: `1px solid ${secondaryGrey}`,
							color: white,
							backgroundColor: secondaryGrey,
						},
					},
				},
				{
					props: { variant: 'link' },
					style: {
						'borderRadius': '0px',
						'border': 'none',
						'borderBottom': `1px solid ${typographyGrey}`,
						'color': secondaryGrey,
						'backgroundColor': 'none',
						'textTransform': 'none',
						'padding': '1px',
						'fontWeight': 'none',
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
							borderBottom: `1px solid ${lightBlue}`,
						},
					},
				},
				{
					props: { variant: 'text' },
					style: {
						...baseButtonStyle,
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'boldText' },
					style: {
						...boldButtonStyle,
					},
				},
				{
					props: { variant: 'blueText' },
					style: {
						...baseButtonStyle,
						color: lightBlue,
					},
				},
				// SelectButtons
				{
					props: { variant: 'inactiveSelectButton' },
					style: {
						'textTransform': 'none',
						'backgroundColor': decentGrey,
						'color': typographyGrey,
						'&:hover': {
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'activeSelectButton' },
					style: {
						'textTransform': 'none',
						'backgroundColor': lightBlue,
						'color': typographyGrey,
						'&:hover': {
							color: white,
							backgroundColor: lightBlue,
						},
					},
				},
				// ProfileViewMenuButtons
				{
					props: { variant: 'inactiveProfileViewMenuButton' },
					style: {
						...boldButtonStyle,

						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'activeProfileViewMenuButton' },
					style: {
						...boldButtonStyle,
						'textDecoration': 'underline',
						'textDecorationColor': lightBlue,
						'textDecorationThickness': '3px',
						'&:hover': {
							backgroundColor: 'transparent',
							color: lightBlue,
							textDecoration: 'underline',
							textDecorationColor: lightBlue,
							textDecorationThickness: '3px',
						},
					},
				},
				// TopAppBarButtons
				{
					props: { variant: 'activeTopAppBarButton' },
					style: {
						...activeAppBarButtonStyle,
						'backgroundColor': white,
						'&:hover': {
							backgroundColor: white,
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'inactiveTopAppBarButton' },
					style: baseButtonStyle,
				},
				// BottomAppBarButtons
				{
					props: { variant: 'activeBottomAppBarButton' },
					style: {
						...activeAppBarButtonStyle,
						'backgroundColor': lightOrange,
						'&:hover': {
							backgroundColor: lightOrange,
							color: lightBlue,
						},
					},
				},
				{
					props: { variant: 'inactiveBottomAppBarButton' },
					style: baseButtonStyle,
				},
				// LoadingButtons
				{
					props: { variant: 'loadingButton' },
					style: {
						textTransform: 'none',
						borderRadius: '0.25rem',
						border: `1px solid ${lightGrey}`,
						color: secondaryGrey,
						backgroundColor: white,
					},
				},
				{
					props: { variant: 'loadingMainButton' },
					style: {
						textTransform: 'none',
						border: `1px solid ${lightGrey}`,
						color: secondaryGrey,
						backgroundColor: white,
					},
				},
			],
		},
	},
	palette: {
		primary: {
			main: orange,
		},
		secondary: {
			main: secondaryGrey,
		},
	},
	// caution: this also overwrites specific button styles
	typography: {
		allVariants: { color: typographyGrey, fontFamily: 'Open Sans, sans-serif' },
	},
};

const adminTheme = {
	...defaultTheme,
	customColors: {
		...defaultTheme.customColors,
		accent: violet1,
	},
	components: {
		...defaultTheme.components,
		MuiButton: {
			...defaultTheme.components.MuiButton,
			variants: defaultTheme.components.MuiButton.variants.map((buttonVariant) =>
				buttonVariant.props.variant === 'mainButton'
					? {
							...buttonVariant,
							style: {
								'textTransform': 'none',
								'border': `1px solid ${violet1}`,
								'color': white,
								'backgroundColor': violet1,
								'&:hover': {
									border: `1px solid ${secondaryGrey}`,
									color: white,
									backgroundColor: secondaryGrey,
								},
							},
					  }
					: buttonVariant.props.variant === 'activeBottomAppBarButton'
					? {
							...buttonVariant,
							style: {
								...activeAppBarButtonStyle,
								'backgroundColor': white,
								'&:hover': {
									backgroundColor: white,
									color: lightBlue,
								},
							},
					  }
					: buttonVariant.props.variant === 'inactiveBottomAppBarButton'
					? {
							...buttonVariant,
							style: {
								'minWidth': '0px',
								'textAlign': 'left',
								'borderRadius': '0px',
								'color': white,
								'backgroundColor': 'none',
								'textTransform': 'none',
								'padding': '1px',
								'fontWeight': 'none',
								'&:hover': {
									backgroundColor: 'transparent',
									color: orange,
								},
							},
					  }
					: buttonVariant
			),
		},
	},
};

const breakPointsValues = breakPoints.values;

export const useActiveBreakPoint = (): 'xs' | 'sm' | 'md' | 'lg' | 'xl' => {
	const { width } = useWindowDimensions();
	const ranges = Object.entries(breakPointsValues).map(([id, startCutoff], idx, arr) => {
		const nextCutoff = arr[idx + 1] ? arr[idx + 1]![1] : 100000;
		return { id, range: [startCutoff, nextCutoff] };
	}, []);
	return ranges.find((range) => width > range.range[0]! && width <= range.range[1]!)!.id as
		| 'xs'
		| 'sm'
		| 'md'
		| 'lg'
		| 'xl';
};

export const useCreateTheme = () => {
	const location = useLocation();
	const pathName = location.pathname;
	const isAdminStyleRoute = pathName.includes('/admin');

	const currentTheme = React.useMemo(
		() =>
			createTheme(
				isAdminStyleRoute ? (adminTheme as MuiThemeOptions) : (defaultTheme as MuiThemeOptions)
			),

		[isAdminStyleRoute]
	);
	return currentTheme;
};
