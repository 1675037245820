import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';

import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import Txt from '../components/Txt';
import FilterableContents2 from '../components/FilterableContents2';

import { useAppState } from '../utils/app';

import teamwork5Image from '../images/BusinessTeamwork5.png';
import MarkdownTxt from '../components/MarkdownTxt';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import { useCurrentUser } from '../utils/user';

const BorderWrapper = styled(Box)`
	align-self: stretch;
	border: 1px solid ${(p) => p.theme.customColors.lightGrey};
	padding: 1rem;
	border-radius: 0.5rem;
	width: 100%;
`;

const addFavouritesText = `**Du willst dir deine liebsten Kurse merken?** \n\n
[Registriere](/signup) dich oder [melde dich an](/login) um Kurse und Materialien auf deine Favoritenliste zu setzen.
`;

const label = 'Ihre Favoriten';

const FavouritesView = () => {
	const currentUser = useCurrentUser();
	const favouriteContents = currentUser?.favouriteContents;
	const appState = useAppState();

	return appState === 'LOGGED_OUT' ? (
		<ContentWrapper>
			<ImageHeader title={label} src={teamwork5Image} />
			<SectionWithoutBackground>
				<BorderWrapper>
					<MarkdownTxt>{addFavouritesText}</MarkdownTxt>
				</BorderWrapper>
			</SectionWithoutBackground>
		</ContentWrapper>
	) : (
		<ContentWrapper>
			{!favouriteContents ? null : favouriteContents.length === 0 ? (
				<>
					<ImageHeader title={label} src={teamwork5Image} />{' '}
					<SectionWithoutBackground>
						<BorderWrapper>
							<Txt>
								Hier erscheinen alle Inhalte, die Sie zu Ihren Favoriten hinzugefügt haben. Aktuell
								ist diese Liste leer.
							</Txt>
						</BorderWrapper>
					</SectionWithoutBackground>
				</>
			) : (
				<>
					<ImageHeader title={label} src={teamwork5Image} />
					<SectionWithoutBackground>
						<FilterableContents2 contents={favouriteContents} />
					</SectionWithoutBackground>
				</>
			)}
		</ContentWrapper>
	);
};

export default FavouritesView;
