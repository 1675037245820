import React from 'react';

export const checkValidationFunctions = (validationFunctions) => {
	return !validationFunctions.map((fn) => fn!()).some(Boolean);
};

const emailRegExp = new RegExp(
	/^[_A-Za-z0-9-]+([.|+][_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/
);

export const validateEmail = (value?: string): string | undefined => {
	if (!value) return 'Bitte ausfüllen';
	if (emailRegExp.test(value)) return;
	return 'Ungültige Email';
};

export const validatePassword = (value?: string): string | undefined => {
	if (!value) return 'Bitte ausfüllen';
	if (value.length >= 8) return;
	return 'Muss mindestens 8 Zeichen lang sein';
};

export const replaceTypeNames = (input: any) => {
	if (typeof input === 'object') {
		delete input['__typename'];
		for (const key in input) {
			const value = input[key];
			if (typeof value === 'object') {
				const newValue = replaceTypeNames(value);
				input[key] = newValue;
			}
		}
	}
	return input;
};
