import client from '../utils/client';
import { gql } from '@apollo/client';
import { CompanyDepartmentInfoFragment } from '../utils/fragments';

type Error =
	| 'NETWORK_ERROR'
	| 'UNAUTHORIZED'
	| 'COMPANY_DEPARTMENT_NOT_EXISTENT'
	| 'FIRST_NAME_LAST_NAME_AND_EMAIL_REQUIRED'
	| 'COMPANY_NOT_EXISTENT';

const upsertCompanyDepartment = async ({
	id,
	title,
	firstName,
	lastName,
	email,
	active,
}: {
	id?: string;
	title?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	active?: boolean;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpsertCompanyDepartment(
					$id: ID
					$title: String
					$firstName: String
					$lastName: String
					$email: String
					$active: Boolean
				) {
					upsertCompanyDepartment(
						id: $id
						title: $title
						firstName: $firstName
						lastName: $lastName
						email: $email
						active: $active
					) {
						...CompanyDepartmentInfoFragment
					}
				}
				${CompanyDepartmentInfoFragment}
			`,
			variables: { id, title, firstName, lastName, email, active },
			refetchQueries: ['CurrentUser'],
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default upsertCompanyDepartment;
