import React from 'react';
import SectionHeader from './SectionHeader';
import GridContainer from './GridContainer';
import GridItem from './GridItem';
import SectionWithoutBackground from './SectionWithoutBackground';
import ReactPlayerWrapper from './ReactPlayerWrapper';
import MarkdownTxt from './MarkdownTxt';

const horizontalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };
const verticalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

const aboutUsText = `
Willkommen bei bildungsfreaks! Hier finden Sie digitale Lerneinheiten und Materialien zu den wichtigen Zukunftsthemen, die wirklich zählen – Datenschutz, Urheberrecht und viele weitere. Und das Beste daran: Sie können jederzeit und überall lernen – auf Ihrem Smartphone, Tablet oder Computer.

Lernen Sie gerne in Ihrem eigenen Tempo? Mit Bildungsfreaks können Sie jederzeit eine Pause machen und später nahtlos weiterlernen. Ihr Lernfortschritt wird automatisch gespeichert, damit Sie direkt dort weitermachen können, wo Sie aufgehört haben. Und das ist noch nicht alles! Wenn Sie einen Kurs erfolgreich abgeschlossen haben, erhalten Sie ein Zertifikat, das Ihre Teilnahme bescheinigt.

Wir sind stolz darauf, dass unsere Plattform in Deutschland entwickelt und datenschutzkonform gehostet wird. Wir wachsen ständig und stellen regelmäßig neue Inhalte und Funktionen bereit, damit Sie noch besser lernen können.
`;

const LearnSmartSectionSection = () => {
	return (
		<SectionWithoutBackground>
			<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
				<GridItem xs={12}>
					<SectionHeader
						title="Smarter lernen. Smarter unterrichten."
						subTitle="Jederzeit und überall."
						alignItems="flex-start"
						hasBottomMargin
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<MarkdownTxt>{aboutUsText}</MarkdownTxt>
				</GridItem>
			</GridContainer>
		</SectionWithoutBackground>
	);
};
export default LearnSmartSectionSection;
