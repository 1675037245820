import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import Button from './Button';
import DialogWrapper from './DialogWrapper';
import { DialogButtonRow, DialogHeader } from './Dialog';
import deleteFromCompany from '../mutations/deleteFromCompany';

const DeleteFromCompanyDialog = ({
	dismissPortal,
	userId,
}: {
	dismissPortal: () => void;
	userId?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();

	const [loading, setLoading] = React.useState(false);

	const handleDeleteFromCompany = React.useCallback(async () => {
		setLoading(true);
		const { error } = await deleteFromCompany({
			userId: userId!,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: 'Nutzer erfolgreich entfernt' });
			dismissPortal();
		}
	}, [dismissPortal, snackBar, userId]);

	return (
		<DialogWrapper p="2rem">
			<>
				<DialogHeader
					title="Nutzer*in aus Organisation entfernen"
					description="Möchten Sie diese Nutzer*in wirklich aus dem Unternehmen entfernen? In diesem Fall wird die Nutzer*in auch aus der Abteilung und den entsprechenden Volumenlizenzen entfernt."
				/>
				<DialogButtonRow>
					<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
						Abbrechen
					</Button>
					<Button variant="mainButton" onClick={handleDeleteFromCompany} loading={loading}>
						Löschen
					</Button>
				</DialogButtonRow>
			</>
		</DialogWrapper>
	);
};

export default DeleteFromCompanyDialog;
