import React from 'react';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from './IconButton';
import { useDivDimensions, usePxPerRemFactor, useWindowDimensions } from '../utils/hooks';
import Button from './Button';
import Txt from './Txt';
import { CurrentUser } from '../utils/user';
import { appBarHeightVar } from '../utils/localState';
import FilterSectionRender from './FilterSectionRender';
import { ContentInfos } from '../utils/content';
import SortButton, { SortType } from './SortButton';
import { getSortedFilteredContents } from '../utils/content';

const USUAL_WIDTH_REM = 20;
const OFFEET_REM = 2;

const ELEMENT_PADDING = '0.75rem 1rem';

const MobileFilterSection = ({
	contents,
	onChange,
	defaultShowFilter,
	audienceType,
}: {
	contents?: ContentInfos[];
	onChange: (filteredContents?: ContentInfos[]) => void;
	defaultShowFilter?: boolean;
	audienceType?: CurrentUser['audienceType'];
}): JSX.Element => {
	const theme = useTheme();
	const handleToggleShowFilter = React.useCallback(() => {
		setShowFilter((before) => !before);
	}, []);
	const handleOpen = React.useCallback(() => {
		//
	}, []);

	const handleClose = React.useCallback(() => {
		setShowFilter(false);
	}, []);

	const [showFilter, setShowFilter] = React.useState<boolean>(defaultShowFilter || false);
	const [filteredContents, setFilteredContents] = React.useState<ContentInfos[] | undefined>(
		contents
	);
	const [sortType, setSortType] = React.useState<SortType | undefined>(undefined);

	const handleChangeFilteredContents = React.useCallback(
		(_numFilters: number, newFilteredContents) => {
			setFilteredContents(newFilteredContents);
			const sortedFilteredContents = getSortedFilteredContents({
				sortType,
				filteredContents: newFilteredContents,
			});
			onChange(sortedFilteredContents);
		},
		[onChange, sortType]
	);

	// sort type logic
	const handleSetSortType = React.useCallback(
		(newSortType) => {
			setSortType(newSortType);
			const sortedFilteredContents = getSortedFilteredContents({
				sortType: newSortType,
				filteredContents,
			});
			onChange(sortedFilteredContents);
		},
		[filteredContents, onChange]
	);

	// reset filters logic
	const [resetFiltersKey, setResetFiltersKey] = React.useState<number>(0);
	const handleResetFilters = React.useCallback(() => {
		setResetFiltersKey((before) => before + 1);
	}, []);

	// position fixed logic
	const { width: windowWidth } = useWindowDimensions();
	const pxPerRemFactor = usePxPerRemFactor();
	const finalWidth = Math.min(
		windowWidth - OFFEET_REM * pxPerRemFactor,
		USUAL_WIDTH_REM * pxPerRemFactor
	);
	const appBarHeight = appBarHeightVar();
	const deps = React.useMemo(() => [], []);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const { top } = useDivDimensions(containerRef, 'scroll&resize', deps, 1);
	const deps2 = React.useMemo(() => [], []);
	const heightRef = React.useRef<HTMLDivElement>(null);
	const { height } = useDivDimensions(heightRef, 'scroll&resize', deps2, 1);
	const fixed = top === undefined ? false : top <= appBarHeight;

	return (
		<Box ref={containerRef} display="flex" flexDirection="column" width="100%">
			{fixed ? <Box width="100%" border="10px solid orange" height={height} /> : null}
			<Box
				display="flex"
				position={fixed ? 'fixed' : undefined}
				flexDirection="row"
				justifyContent="space-between"
				p={fixed ? '1rem' : '1rem 0'}
				top={fixed ? appBarHeight : undefined}
				left={fixed ? 0 : undefined}
				right={fixed ? 0 : undefined}
				width="100%"
				ref={heightRef}
				zIndex={fixed ? 1000 : undefined}
				style={{ background: 'white' }}
			>
				<SortButton variant="mainButton" onChange={handleSetSortType} />
				<Button variant="mainButton" onClick={handleToggleShowFilter} startIconName="FilterList">
					Filtern
				</Button>
			</Box>
			<SwipeableDrawer open={showFilter} anchor="right" onOpen={handleOpen} onClose={handleClose}>
				<Box
					p={ELEMENT_PADDING}
					borderBottom={`3px solid ${theme.palette.common.black}`}
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
				>
					<Txt fontWeight="bold">Filter</Txt>
					<IconButton iconName="CloseOutlined" onClick={handleClose} />
				</Box>
				<Box
					width={`${finalWidth}px`}
					height="100vh"
					display="flex"
					flexDirection="column"
					overflow="scroll"
					position="relative"
				>
					<FilterSectionRender
						contents={contents}
						onChange={handleChangeFilteredContents}
						audienceType={resetFiltersKey === 0 ? audienceType : undefined}
						key={resetFiltersKey}
					/>
				</Box>
				<Box width="100%" display="flex" flexDirection="row" justifyContent="space-around" p="1rem">
					<Button variant="mainButton" onClick={handleResetFilters}>
						Filter zurücksetzen
					</Button>
				</Box>
			</SwipeableDrawer>
		</Box>
	);
};

export default MobileFilterSection;
