import React from 'react';

import ChevronRight from '@mui/icons-material/ChevronRight';
import EditRounded from '@mui/icons-material/EditRounded';
import SendRounded from '@mui/icons-material/SendRounded';
import CancelScheduleSend from '@mui/icons-material/CancelScheduleSend';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Search from '@mui/icons-material/Search';
import PhoneIphoneOutlined from '@mui/icons-material/PhoneIphoneOutlined';
import DesktopMacOutlined from '@mui/icons-material/DesktopMacOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Info from '@mui/icons-material/Info';
import ColorLensOutlined from '@mui/icons-material/ColorLensOutlined';
import ColorLens from '@mui/icons-material/ColorLens';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import Delete from '@mui/icons-material/Delete';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Share from '@mui/icons-material/Share';
import PersonAdd from '@mui/icons-material/PersonAdd';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/icons-material/Menu';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import PlayCircleFilledWhiteOutlined from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import History from '@mui/icons-material/History';
import Lock from '@mui/icons-material/Lock';
import LocalLibraryOutlined from '@mui/icons-material/LocalLibraryOutlined';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Check from '@mui/icons-material/Check';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import PlayArrow from '@mui/icons-material/PlayArrow';
import FilterList from '@mui/icons-material/FilterList';
import FilterNone from '@mui/icons-material/FilterNone';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import Replay from '@mui/icons-material/Replay';
import AccessAlarm from '@mui/icons-material/AccessAlarm';
import Schedule from '@mui/icons-material/Schedule';
import PriorityHigh from '@mui/icons-material/PriorityHigh';
import MoreHoriz from '@mui/icons-material/MoreHoriz';

import ImageBox from '../components/ImageBox';
import image from '../images/BF_Logo.png';
// import image from '../images/BF_Grafik_Kachel_Abo_Square.png';

export type IconName =
	| 'ChevronRight'
	| 'EditRounded'
	| 'SendRounded'
	| 'CancelScheduleSend'
	| 'SaveOutlined'
	| 'Add'
	| 'DeleteOutline'
	| 'Search'
	| 'PhoneIphoneOutlined'
	| 'DesktopMacOutlined'
	| 'CloseOutlined'
	| 'Info'
	| 'ColorLensOutlined'
	| 'ColorLens'
	| 'FavoriteBorder'
	| 'Favorite'
	| 'Delete'
	| 'ContentCopy'
	| 'Share'
	| 'PersonAdd'
	| 'AccountCircle'
	| 'Menu'
	| 'ChevronLeft'
	| 'ChevronRight'
	| 'CheckCircleOutline'
	| 'CheckCircle'
	| 'CircleOutlined'
	| 'PlayCircleFilledWhiteOutlined'
	| 'History'
	| 'Lock'
	| 'AccountCircle'
	| 'LocalLibraryOutlined'
	| 'SchoolOutlined'
	| 'AccountCircleOutlined'
	| 'InfoOutlined'
	| 'Bildungsfreaks'
	| 'Check'
	| 'PlayCircleOutline'
	| 'PlayArrow'
	| 'FilterList'
	| 'FilterNone'
	| 'SupervisorAccount'
	| 'CancelOutlined'
	| 'Replay'
	| 'AccessAlarm'
	| 'Schedule'
	| 'PriorityHigh'
	| 'MoreHoriz';

const iconDict: { [key in IconName]: (props?: any) => JSX.Element } = {
	ChevronRight: (props) => <ChevronRight {...props} />,
	EditRounded: (props) => <EditRounded {...props} />,
	SendRounded: (props) => <SendRounded {...props} />,
	CancelScheduleSend: (props) => <CancelScheduleSend {...props} />,
	SaveOutlined: (props) => <SaveOutlined {...props} />,
	Add: (props) => <Add {...props} />,
	DeleteOutline: (props) => <DeleteOutline {...props} />,
	Search: (props) => <Search {...props} />,
	PhoneIphoneOutlined: (props) => <PhoneIphoneOutlined {...props} />,
	DesktopMacOutlined: (props) => <DesktopMacOutlined {...props} />,
	CloseOutlined: (props) => <CloseOutlined {...props} />,
	Info: (props) => <Info {...props} />,
	ColorLensOutlined: (props) => <ColorLensOutlined {...props} />,
	ColorLens: (props) => <ColorLens {...props} />,
	FavoriteBorder: (props) => <FavoriteBorder {...props} />,
	Favorite: (props) => <Favorite {...props} />,
	Delete: (props) => <Delete {...props} />,
	ContentCopy: (props) => <ContentCopy {...props} />,
	Share: (props) => <Share {...props} />,
	PersonAdd: (props) => <PersonAdd {...props} />,
	AccountCircle: (props) => <AccountCircle {...props} />,
	Menu: (props) => <Menu {...props} />,
	ChevronLeft: (props) => <ChevronLeft {...props} />,
	CheckCircleOutline: (props) => <CheckCircleOutline {...props} />,
	CheckCircle: (props) => <CheckCircle {...props} />,
	CircleOutlined: (props) => <CircleOutlined {...props} />,
	PlayCircleFilledWhiteOutlined: (props) => <PlayCircleFilledWhiteOutlined {...props} />,
	History: (props) => <History {...props} />,
	Lock: (props) => <Lock {...props} />,
	LocalLibraryOutlined: (props) => <LocalLibraryOutlined {...props} />,
	SchoolOutlined: (props) => <SchoolOutlined {...props} />,
	AccountCircleOutlined: (props) => <AccountCircleOutlined {...props} />,
	InfoOutlined: (props) => <InfoOutlined {...props} />,
	Bildungsfreaks: (props) => <ImageBox src={image} width="20px" {...props} />,
	Check: (props) => <Check {...props} />,
	PlayCircleOutline: (props) => <PlayCircleOutline {...props} />,
	PlayArrow: (props) => <PlayArrow {...props} />,
	FilterList: (props) => <FilterList {...props} />,
	FilterNone: (props) => <FilterNone {...props} />,
	SupervisorAccount: (props) => <SupervisorAccount {...props} />,
	CancelOutlined: (props) => <CancelOutlined {...props} />,
	Replay: (props) => <Replay {...props} />,
	AccessAlarm: (props) => <AccessAlarm {...props} />,
	Schedule: (props) => <Schedule {...props} />,
	PriorityHigh: (props) => <PriorityHigh {...props} />,
	MoreHoriz: (props) => <MoreHoriz {...props} />,
};

export default iconDict;
