import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

import Txt from './Txt';
import { useIsMobile, useSpacingRem } from '../utils/hooks';
import MarkdownTxt from './MarkdownTxt';

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

// this component is usually wrapped in a SectionWithoutBackground, the SectionWithoutBackground controls the padding according to the screen size
// this component is also wrapped in ImageHeader, while ImageHeader controls the padding of the section header according to the screen size
const SectionHeader = ({
	title,
	subTitle,
	p,
	alignItems = 'center',
	hasBottomMargin,
	isMarkdown,
	renderContent,
}: {
	title?: string;
	subTitle?: string;
	p?: string;
	alignItems?: 'center' | 'flex-start';
	hasBottomMargin?: boolean;
	isMarkdown?: boolean;
	renderContent?: () => JSX.Element;
}): JSX.Element => {
	const isMobile = useIsMobile();

	const spacingRem = useSpacingRem();

	return (
		<Wrapper
			m={hasBottomMargin ? `0 0 ${spacingRem}rem 0` : undefined}
			p={p}
			alignItems={alignItems}
		>
			{title ? (
				<Txt align="center" fontWeight="bold" variant={isMobile ? 'h6' : 'h5'}>
					{title}
				</Txt>
			) : null}
			{subTitle ? (
				isMarkdown ? (
					<MarkdownTxt m="0.5rem 0 0">{subTitle}</MarkdownTxt>
				) : (
					<Txt m="0.5rem 0 0" variant={isMobile ? 'body1' : 'h6'}>
						{subTitle}
					</Txt>
				)
			) : null}
			{renderContent ? renderContent() : null}
		</Wrapper>
	);
};

export default SectionHeader;
