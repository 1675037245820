import React from 'react';
import { Box } from '@mui/material';
import { useDivDimensions, usePxPerRemFactor } from '../utils/hooks';

export const useGetNumberOfColums = ({
	ref,
	minimumSize,
	columnGapPx = 0,
	horizontalPaddingPx = 0,
	deps,
}: {
	ref: React.RefObject<HTMLDivElement>;
	minimumSize: number;
	columnGapPx?: number;
	horizontalPaddingPx?: number;
	deps?: unknown[];
}): { numberOfColumns: number; columnWidth: number } => {
	const { width } = useDivDimensions(ref, 'resize', deps);
	const availableWidth = Math.floor(width ? width - 2 * horizontalPaddingPx : 1);

	const numberOfColumns = availableWidth
		? Math.floor((availableWidth - 2 * horizontalPaddingPx) / minimumSize)
		: 1;
	const correctedAvailableWidth = (availableWidth || 0) - (numberOfColumns - 1) * columnGapPx;
	const correctedNumberOfColumns = Math.floor(correctedAvailableWidth / minimumSize) || 1;
	const columnWidth = correctedAvailableWidth / correctedNumberOfColumns;
	return { numberOfColumns: correctedNumberOfColumns, columnWidth };
};

const FlexWrap = ({
	children,
	rowGapRem = 1,
	columGapRem = 1,
	minWidthRem,
	pRightRem = 0,
	pLeftRem = 0,
}: {
	children?: JSX.Element[];
	rowGapRem?: number;
	columGapRem?: number;
	minWidthRem: number;
	pRightRem?: number;
	pLeftRem?: number;
}) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const pxPerRemFactor = usePxPerRemFactor();
	const { columnWidth } = useGetNumberOfColums({
		ref,
		minimumSize: pxPerRemFactor * minWidthRem,
		columnGapPx: pxPerRemFactor * columGapRem,
		horizontalPaddingPx: pxPerRemFactor * pRightRem + pxPerRemFactor * pLeftRem,
	});

	return !children || !children.length ? null : (
		<Box
			width="100%"
			height="100%"
			display="flex"
			flexDirection="row"
			flexWrap="wrap"
			rowGap={`${rowGapRem}rem`}
			columnGap={`${columGapRem}rem`}
			justifyContent="flex-start"
			alignContent="flex-start"
			ref={ref}
			paddingLeft={pLeftRem ? `${pLeftRem}rem` : undefined}
			paddingRight={pRightRem ? `${pRightRem}rem` : undefined}
		>
			{children?.map((child, index) => (
				<Box key={index} width={columnWidth} display="flex">
					{child}
				</Box>
			))}
		</Box>
	);
};

export default FlexWrap;
