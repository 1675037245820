import React from 'react';
import styled from 'styled-components';
import { Grid, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useAllMemberships } from '../utils/membership';
import { useCurrentUser } from '../utils/user';
import GridItem from '../components/GridItem';
import Button from '../components/Button';
import Txt from '../components/Txt';
import ContentWrapper from '../components/ContentWrapper';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import MembershipFeatures from '../components/MembershipFeatures';
import GetProContactDialog from '../components/GetProContactDialog';
import Dialog from '../components/Dialog';
import { useNavigate } from '../utils/hooks';

const OrangeCell = styled(Box)<{ $widthFactor: number; $backgroundColor?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: ${(p) => p.theme.customColors.orange};
	border-radius: 0.5rem;
	height: 3rem;
`;

const gridItemsPropsDict = {
	1: {
		xs: 12,
		sm: 12,
		md: 12,
		lg: 12,
		xl: 12,
	},
	2: {
		xs: 12,
		sm: 6,
		md: 6,
		lg: 6,
		xl: 6,
	},
	3: {
		xs: 12,
		sm: 6,
		md: 4,
		lg: 4,
		xl: 4,
	},
};

const StyledGridItem = styled(GridItem)`
	background-color: ${(p) => p.theme.customColors.decentBlue};
	border-radius: 0.5rem;
`;

const MembershipListView = (): JSX.Element | null => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const membershipIds = searchParams.get('membershipIds')?.split(',');
	const allMemberships = useAllMemberships();
	const currentUser = useCurrentUser();

	const handleShowGetProContactDialog = React.useCallback(() => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => <GetProContactDialog dismissPortal={dismissPortal} />,
		});
	}, []);

	const currentMembershipLebel = currentUser?.membership?.level;
	const maxMembershipLevel = allMemberships?.reduce(
		(acc, { level }) => (level > acc ? level : acc),
		0
	);

	const relevantMemberships = React.useMemo(
		() =>
			allMemberships
				?.filter(({ id }) => (membershipIds?.length ? membershipIds.includes(id) : true)) //
				.filter(({ level }) => {
					return !currentMembershipLebel
						? true
						: level === maxMembershipLevel
						? true
						: level > currentMembershipLebel;
				}),
		[allMemberships, currentMembershipLebel, maxMembershipLevel, membershipIds]
	);

	const relevantMembershipsWithButtonProps = React.useMemo(
		() =>
			relevantMemberships?.map((membership) => {
				return {
					...membership,
					button: {
						label:
							currentUser?.membership?.id === membership.id
								? undefined
								: membership.type === 'LEVEL_1'
								? 'Jetzt kostenlos registrieren'
								: 'Jetzt PRO werden',
						onClick:
							currentUser?.membership?.id === membership.id
								? undefined
								: membership.type === 'LEVEL_1'
								? () => navigate('/signup')
								: handleShowGetProContactDialog,
					},
				};
			}),
		[currentUser?.membership?.id, handleShowGetProContactDialog, navigate, relevantMemberships]
	);

	return (
		<ContentWrapper>
			<SectionWithoutBackground>
				<Box display="flex" flexDirection="row" justifyContent="center" width="100%">
					<Box maxWidth="70rem">
						<Grid container spacing={2}>
							{relevantMembershipsWithButtonProps?.map(
								({ id, title, description, priceLabel, appFunctionalities, button }) => {
									return (
										<StyledGridItem
											key={id}
											{...gridItemsPropsDict[relevantMembershipsWithButtonProps?.length]}
											flexDirection="column"
											justifyContent="flex-start"
											alignItems="flex-start"
										>
											<OrangeCell>
												<Txt variant="h6">{title}</Txt>
											</OrangeCell>
											<Box p="2rem" width="100%" height="8rem">
												<Txt variant="body1">{description}</Txt>
												<Txt m="1rem 0 0 0" variant="h6">
													{priceLabel}
												</Txt>
											</Box>
											<Box p="2rem" flex="1" display="flex" flexDirection="column">
												<MembershipFeatures appFunctionalities={appFunctionalities} />
												<Box flex="1" />
												{button.label && button.onClick ? (
													<Button
														m="2rem 0 0 0"
														onClick={button.onClick}
														fullWidth
														variant="mainButton"
													>
														{button.label}
													</Button>
												) : null}
											</Box>
										</StyledGridItem>
									);
								}
							)}
						</Grid>
					</Box>
				</Box>
			</SectionWithoutBackground>
		</ContentWrapper>
	);
};

export default MembershipListView;
