import React from 'react';
import { API_BASE_URL_SSO, LATEST_TERMS_VERSION } from '../consts';
import { gql, useQuery, useReactiveVar } from '@apollo/client';

import { ContentAccessibilityType, ContentInfos } from '../utils/content';
import { Product } from '../utils/product';
import { AppFunctionality, Membership } from '../utils/membership';
import { Order } from '../utils/order';
import { Address } from '../utils/address';
import { AccessGenerator } from '../utils/accessGenerator';
import { Certificate } from '../utils/certificate';
import { UserInfoFragment } from '../utils/fragments';
import { Company } from '../utils/company';
import client from '../utils/client';
import Dialog from '../components/Dialog';
import UpdateInitialPasswordDialog from '../components/UpdateInitialPasswordDialog';
import ConfirmTermsAndConditionsDialog from '../components/ConfirmTermsAndConditionsDialog';
import { useIsHideBannerRoute } from './hooks';
import { termsWarningTemporaryHiddenVar } from './localState';
import { CompanyDepartment } from './companyDepartment';

export type CurrentUser = {
	id: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	addresses: Address[];
	products: Product[];
	orders: Order[];
	organizationName?: string;
	userName?: string;
	isTestUser?: boolean;
	appFunctionalities?: AppFunctionality[];
	isAdminUser?: boolean;
	isAccessPhraseUser?: boolean;
	accessGenerators?: AccessGenerator[];
	accessGeneratorsInfo?: {
		remaining: number;
		taken: number;
	};
	termsAcceptedVersion: number;
	isInvitedUser: boolean;
	hasChangedInitialPassword: boolean;
	inviteEmailSent?: boolean;
	inviteEmailSentAt?: string;
	emailConfirmed?: boolean;
	progressedContents: ContentInfos[];
	favouriteContents: ContentInfos[];
	downloadedContents: ContentInfos[];
	certificates: Certificate[];
	accessibleContentsInfo: {
		id: string;
		accessibleUntil: string;
		accessibilities: ContentAccessibilityType[];
	}[];
	accessibleProductContents: ContentInfos[];
	accessPhraseContents: ContentInfos[];
	membership?: Membership;
	audienceType?: 'COMPANY' | 'PUPILS' | 'PARENTS' | 'TEACHER';
	subscriptions: string[];
	company?: Company;
	companyDepartment?: CompanyDepartment;
	requiredContentsInfo: {
		id: string;
		finishInDays: number;
		finishUntil: string;
	}[];
};

export const getToken = () => {
	return localStorage.getItem('jwt');
};

export const setToken = (jwt: string) => {
	return localStorage.setItem('jwt', jwt);
};

export const CURRENT_USER_QUERY = gql`
	query CurrentUser {
		currentUser {
			...UserInfoFragment
		}
	}
	${UserInfoFragment}
`;

export const handleSSOLogin = () => {
	window.location.replace(`${API_BASE_URL_SSO}/login`);
};

export const handleSSOLogout = () => {
	window.location.replace(`${API_BASE_URL_SSO}/logout`);
};

export const fetchUser = async (): Promise<void> => {
	await client.resetStore();
	await client.query({
		query: CURRENT_USER_QUERY,
		fetchPolicy: 'network-only',
	});
};

export const logout = async (navigate) => {
	localStorage.removeItem('jwt');
	await client.resetStore();
	navigate('/');
};

export const useCurrentUser = (): undefined | null | CurrentUser => {
	const { data } = useQuery(CURRENT_USER_QUERY);
	return React.useMemo(
		() =>
			data?.currentUser
				? {
						...data.currentUser,
						isAdminUser: data.currentUser.roles.some((role) => role === 'ADMIN' || role === 'ROOT'),
				  }
				: data?.currentUser,
		[data?.currentUser]
	);
};

export const usePotentiallyRequestPasswordReset = () => {
	const currentUser = useCurrentUser();
	const isInvitedUser = currentUser?.isInvitedUser;
	const hasChangedInitialPassword = currentUser?.hasChangedInitialPassword;
	const isHideBannerRoute = useIsHideBannerRoute();

	const show = isHideBannerRoute
		? false
		: currentUser && isInvitedUser && !hasChangedInitialPassword;

	React.useEffect(() => {
		if (show) {
			Dialog.render(
				{
					renderContent: UpdateInitialPasswordDialog,

					isLocked: true,
				},
				'update-initial-password'
			);
		}
		if (!currentUser) {
			Dialog.unmount('update-initial-password');
		}
	}, [currentUser, hasChangedInitialPassword, isInvitedUser, show]);
};

export const usePotentiallyRequestTermsAcceptance = () => {
	const currentUser = useCurrentUser();
	const isInvitedUser = currentUser?.isInvitedUser;
	const hasChangedInitialPassword = currentUser?.hasChangedInitialPassword;
	const isHideBannerRoute = useIsHideBannerRoute();
	const isTemporaryHidden = useReactiveVar(termsWarningTemporaryHiddenVar);

	const show = isHideBannerRoute
		? false
		: isTemporaryHidden
		? false
		: currentUser &&
		  (!currentUser?.termsAcceptedVersion ||
				currentUser?.termsAcceptedVersion < LATEST_TERMS_VERSION);

	React.useEffect(() => {
		if (show) {
			Dialog.render(
				{
					renderContent: (props) => (
						<ConfirmTermsAndConditionsDialog
							{...props}
							version={currentUser?.termsAcceptedVersion}
						/>
					),
					isLocked: true,
				},
				'terms-and-conditions'
			);
		}
		if (!currentUser) {
			Dialog.unmount('terms-and-conditions');
		}
	}, [currentUser, hasChangedInitialPassword, isInvitedUser, show]);
};

export type UserType =
	| 'ADMIN'
	| 'COMPANY_ADMIN'
	| 'COMPANY_DEPARTMENT_ADMIN'
	| 'STANDARD'
	| 'ACCESS_PHRASE_USER'
	| 'LOGGED_OUT';

export const useUserType = (): UserType => {
	const currentUser = useCurrentUser();
	const userType = !currentUser
		? 'LOGGED_OUT'
		: currentUser?.isAdminUser
		? 'ADMIN'
		: currentUser?.company?.userRole === 'COMPANY_ADMIN'
		? 'COMPANY_ADMIN'
		: currentUser?.company?.userRole === 'COMPANY_DEPARTMENT_ADMIN'
		? 'COMPANY_DEPARTMENT_ADMIN'
		: currentUser?.isAccessPhraseUser
		? 'ACCESS_PHRASE_USER'
		: 'STANDARD';
	return userType;
};
