import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { BoxProps } from '@mui/material/Box';
import { useSectionTopSpacingRem, useSpacingRem } from '../utils/hooks';

const Wrapper = styled(Box)`
	width: 100%;
	background-color: ${(p) => p.theme.customColors.decentBlue};
	display: flex;
`;

const SectionWithBackground = React.forwardRef<HTMLDivElement, BoxProps>(
	({ children, ...rest }, ref) => {
		const spacingRem = useSpacingRem();
		const sectionTopSpacingRem = useSectionTopSpacingRem();

		return (
			<Wrapper ref={ref} m={`${sectionTopSpacingRem}rem 0 0 0`} p={`${spacingRem}rem`} {...rest}>
				{children}
			</Wrapper>
		);
	}
);

export default React.memo(SectionWithBackground);
