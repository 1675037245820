import client from '../utils/client';
import { gql } from '@apollo/client';
import { CompanyInfoFragment } from '../utils/fragments';

type Error = 'COPANY_NOT_EXISTENT' | 'NETWORK_ERROR' | 'UNAUTHORIZED' | 'UNKNOWN';

const updateCompany = async ({
	id,
	title,
	active,
}: {
	id: string;
	title?: string;
	active?: boolean;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateCompany($id: ID!, $title: String, $active: Boolean) {
					updateCompany(id: $id, title: $title, active: $active) {
						...CompanyInfoFragment
					}
				}
				${CompanyInfoFragment}
			`,
			variables: { id, title, active },
		});
		if (errors) {
			return { success: false, error: (errors[0]?.message || 'UNKNOWN') as Error };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default updateCompany;
