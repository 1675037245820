import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, Toolbar } from '@mui/material';

import Button from './Button';
import { useIsMobile } from '../utils/hooks';
import type { ButtonProps, ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { IconName } from '../utils/iconNameDict';
import { useLocation } from 'react-router-dom';

const Row = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledToolBar = styled(Toolbar)<{ $isMobile?: boolean; $backgroundColor?: string }>`
	background-color: ${(p) => p.$backgroundColor || p.theme.customColors.orange};
	min-height: ${(p) => (p.$isMobile ? '2rem' : '3rem')};
	height: ${(p) => (p.$isMobile ? '2rem' : '3rem')};
`;

type NavButton = {
	label: string;
	navigateTo?: string;
	variant?: MuiButtonProps['variant'];
	iconName?: IconName;
	onClick?: (argument: any) => void;
	children?: NavButton[];
};

const GenericBottomAppBar = ({
	buttons,
}: {
	buttons: NavButton[];
	buttonVariant?: ButtonProps['variant'];
	buttonActiveVariant?: ButtonProps['variant'];
}) => {
	const theme = useTheme();
	const location = useLocation();
	const path = location.pathname;
	const lastPartOfPath = path.split('/').pop();
	const isMobile = useIsMobile();
	return (
		<>
			<StyledToolBar $isMobile={isMobile} $backgroundColor={theme.customColors.accent}>
				<Row width="100%" justifyContent="center">
					{buttons?.map(({ label, navigateTo }) => (
						<Button
							key={label}
							id={label}
							m="0 1rem 0 0"
							navigateTo={navigateTo}
							variant={
								navigateTo
									? (navigateTo === '/' && path === '/') ||
									  lastPartOfPath === navigateTo.split('/').pop()
										? 'activeBottomAppBarButton'
										: 'inactiveBottomAppBarButton'
									: 'inactiveBottomAppBarButton'
							}
						>
							{label}
						</Button>
					))}
				</Row>
			</StyledToolBar>
		</>
	);
};

export default React.memo(GenericBottomAppBar);
