import React from 'react';

import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import Contents from '../components/Contents';
import teacherImage from '../images/BF_Header_FuerLehrer.png';
import { useCurrentUser } from '../utils/user';

const LoggedInAccessPhraseUserView = (): JSX.Element | null => {
	const currentUser = useCurrentUser();
	const accessPhraseContents = currentUser?.accessPhraseContents;

	return !currentUser ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper>
			<ImageHeader
				src={teacherImage}
				title="Hallo"
				subTitle="Willkommen bei deinem bildungsfreaks!"
			/>
			<SectionWithoutBackground flexDirection="column">
				<Contents title="Meine Kurse" contents={accessPhraseContents} showProgress />
			</SectionWithoutBackground>
		</ContentWrapper>
	);
};

export default LoggedInAccessPhraseUserView;
