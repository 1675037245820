import { gql } from '@apollo/client';

export const VolumeLicenseInfoFragment = gql`
	fragment VolumeLicenseInfoFragment on VolumeLicense {
		id
		companyId
		isLimited
		limitedUnit
		limitedFactor
		numberOfLicenses
		takenSeats
		remainingSeats
		products {
			id
			title
		}
		company {
			id
			title
		}
		users {
			id
			firstName
			lastName
			email
			emailConfirmed
		}
		createdAt
		active
		userAudienceType
		finishInDays
		companyDepartment {
			id
			title
			admins {
				id
				email
				firstName
				lastName
			}
			volumeLicenses {
				id
				companyId
				isLimited
				limitedUnit
				limitedFactor
				numberOfLicenses
				takenSeats
				remainingSeats
				products {
					id
					title
				}
				company {
					id
					title
				}
				users {
					id
					firstName
					lastName
					email
					emailConfirmed
				}
				createdAt
				active
				userAudienceType
				finishInDays
			}
		}
	}
`;

export const TraineeInfoFragment = gql`
	fragment TraineeInfoFragment on Trainee {
		id
		firstName
		lastName
		email
		completed
		emailConfirmed
		products {
			productId
			title
			contents {
				contentId
				updatedAt
			}
			completedAt
			completed
			finishUntil
			volumeLicenseId
		}
	}
`;

export const CompanyDepartmentInfoFragment = gql`
	fragment CompanyDepartmentInfoFragment on CompanyDepartment {
		id
		title
		reportExceededDeadlines
		active
		admins {
			id
			email
			firstName
			lastName
		}
		volumeLicenses {
			...VolumeLicenseInfoFragment
		}
		trainees {
			...TraineeInfoFragment
		}
		employees {
			id
			firstName
			lastName
			email
			emailConfirmed
		}
	}
	${VolumeLicenseInfoFragment}
	${TraineeInfoFragment}
`;

export const CategoryDetailsFragment = gql`
	fragment CategoryDetailsFragment on Category {
		id
		title
		shortDescription
		subTitle
		description
		type
		groupName
		imageUrl
		contentEducationFormats {
			title
			type
		}
		contentEducationLevels {
			title
			type
		}
		authors
	}
`;

export const ProductDetailsFragment = gql`
	fragment ProductDetailsFragment on Product {
		id
		title
		description
		priceCents
		priceLabel
		audienceCategories {
			...CategoryDetailsFragment
		}
	}
	${CategoryDetailsFragment}
`;

export const OrderDetailsFragment = gql`
	fragment OrderDetailsFragment on Order {
		id
		userId
		createdAt
		totalPrice
		priceLabel
		taxes
		taxesLabel
		status
		products {
			...ProductDetailsFragment
		}
	}
	${ProductDetailsFragment}
`;

export const MembershipInfoFragment = gql`
	fragment MembershipInfoFragment on Membership {
		id
		type
		level
		title
		description
		priceCents
		priceLabel
		appFunctionalities {
			type
			description
			property
		}
	}
`;

export const ContentInfosFragment = gql`
	fragment ContentInfosFragment on Content {
		id
		title
		imageUrl
		author
		demo
		categories {
			id
			groupName
			type
			title
		}
		active
		serieOrder {
			type
			order
		}
		completion
		lastProgressedAt
		priority
		contentFormat
		hasCertificate
		isRegisterFree
		accessibilities {
			type
			ids
		}
		createdAt
		popularity
		structure {
			chapter
			lessons
		}
		latestVersion
		progressedVersion
	}
`;

export const ContentDetailsFragment = gql`
	fragment ContentDetailsFragment on Content {
		...ContentInfosFragment
		sku
		description
		shortDescription
		duration
		updatedAt
		source
		path
		cooperations {
			label
			link
		}
		licenseInfos {
			label
			link
		}
		learningGoals
		why
		who
		what
		techRequirements
		requirements
	}
	${ContentInfosFragment}
`;

export const CertificateInfoFragment = gql`
	fragment CertificateInfoFragment on Certificate {
		id
		createdAt
		version
		content {
			id
			title
		}
		user {
			id
			firstName
			lastName
		}
	}
`;

export const CompanyInfoFragment = gql`
	fragment CompanyInfoFragment on Company {
		id
		title
		active
		userDepartment
		userRole
		departments {
			...CompanyDepartmentInfoFragment
		}
		volumeLicenses {
			...VolumeLicenseInfoFragment
		}
	}
	${CompanyDepartmentInfoFragment}
	${VolumeLicenseInfoFragment}
`;

export const UserInfoFragment = gql`
	fragment UserInfoFragment on User {
		id
		email
		firstName
		lastName
		addresses {
			id
			country
			federalState
			city
			zipCode
			street
			houseNumber
			isStandardAddress
		}
		products {
			...ProductDetailsFragment
		}
		orders {
			...OrderDetailsFragment
		}
		appFunctionalities {
			type
			description
			property
		}
		organizationName
		userName
		isTestUser
		roles
		isAccessPhraseUser
		accessGenerators {
			id
			contentId
			groupName
			createdAt
			validUntil
			accessesAvailable
			accessesTaken
			code
			content {
				id
				title
				contentFormat
			}
			accessesCompleted
		}
		accessGeneratorsInfo {
			remaining
			taken
		}
		isInvitedUser
		hasChangedInitialPassword
		termsAcceptedVersion
		inviteEmailSent
		inviteEmailSentAt
		emailConfirmed
		progressedContents {
			...ContentInfosFragment
		}
		favouriteContents {
			...ContentInfosFragment
		}
		downloadedContents {
			...ContentInfosFragment
		}
		certificates {
			...CertificateInfoFragment
		}
		accessibleContentsInfo {
			id
			accessibleUntil
			accessibilities
		}
		accessibleProductContents {
			id
			...ContentInfosFragment
		}
		accessPhraseContents {
			...ContentInfosFragment
		}
		membership {
			...MembershipInfoFragment
		}
		audienceType
		subscriptions
		company {
			...CompanyInfoFragment
		}
		companyDepartment {
			...CompanyDepartmentInfoFragment
		}
		requiredContentsInfo {
			id
			finishInDays
			finishUntil
		}
	}
	${ProductDetailsFragment}
	${OrderDetailsFragment}
	${ContentInfosFragment}
	${CertificateInfoFragment}
	${MembershipInfoFragment}
	${CompanyInfoFragment}
`;

export default null;
