import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../components/ContentWrapper';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import SectionHeader from '../components/SectionHeader';
import { useCurrentUser } from '../utils/user';
import { Box } from '@mui/material';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import UpsertCompanyDepartmentDialog from '../components/UpsertCompanyDepartmentDialog';
import IconButton from '../components/IconButton';
import Card from '../components/Card';
import SimpleTable4 from '../components/SimpleTable4';

const Row = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
`;

const KEY_1 = 'Abteilung';
const KEY_2 = 'Verantwortlich';

const CompanyDepartmentsListView = () => {
	const currentUser = useCurrentUser();
	const companyDepartments = currentUser?.company?.departments;

	const maybeRenderData = React.useMemo(
		() =>
			!companyDepartments
				? companyDepartments
				: companyDepartments.map((cD) => {
						const admin = cD?.admins[0];
						const adminsString = admin
							? `${admin.firstName} ${admin.lastName} - ${admin.email}`
							: '';
						return {
							id: cD.id,
							[KEY_1]: cD.title,
							[KEY_2]: adminsString,
							Status: cD.active ? 'Aktiv' : 'Inaktiv',
						};
				  }),
		[companyDepartments]
	);

	const handleShowUpsertCompanyDepartmentDialog = React.useCallback((id) => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<UpsertCompanyDepartmentDialog dismissPortal={dismissPortal} id={id} />
			),
			isLocked: true,
		});
	}, []);

	const renderActions = React.useCallback(
		({ id }) => {
			return (
				<Row>
					<IconButton
						m="0 1rem 0 0"
						iconName="EditRounded"
						onClick={handleShowUpsertCompanyDepartmentDialog}
						id={id}
					/>
				</Row>
			);
		},
		[handleShowUpsertCompanyDepartmentDialog]
	);

	return (
		<ContentWrapper>
			<SectionWithoutBackground flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title="Abteilungen"
					subTitle="In diesem Bereich können Sie neue Abteilungen anlegen und verwalten. Sie können jeder Abteilung einen Verantwortlichen zuweisen. "
				/>
			</SectionWithoutBackground>
			<SectionWithoutBackground justifyContent="flex-end">
				<Button variant="mainButton" onClick={handleShowUpsertCompanyDepartmentDialog}>
					Neue Abteilung hinzufügen
				</Button>
			</SectionWithoutBackground>
			{!maybeRenderData ? null : (
				<SectionWithoutBackground>
					<Card>
						<SimpleTable4
							renderData={maybeRenderData}
							numberOfLines={2}
							renderActions={renderActions}
							headRowFontWeight="bold"
							searchKeys={`${KEY_1},${KEY_2}`}
							searchableData={maybeRenderData}
							sortKey={KEY_1}
						/>
					</Card>
				</SectionWithoutBackground>
			)}
		</ContentWrapper>
	);
};

export default CompanyDepartmentsListView;
