import React from 'react';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Txt from '../components/Txt';
import Button from '../components/Button';
import ContentWrapper from '../components/ContentWrapper';
import Dialog from '../components/Dialog';
import { useVolumeLicenses } from '../utils/volumeLicense';
import UpsertVolumeLicenseDialog from '../components/UpsertVolumeLicenseDialog';
import IconButton from '../components/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DialogWrapper from '../components/DialogWrapper';
import { useVolumeLicenseStatistics } from '../utils/volumeLicense';
import { DialogHeader } from '../components/Dialog';
import MarkdownTxt from '../components/MarkdownTxt';
import SimpleTable4 from '../components/SimpleTable4';

const VolumeLicenseStatisticsDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	const statistics = useVolumeLicenseStatistics(id);
	return (
		<DialogWrapper p="2rem">
			{!statistics ? (
				<CircularProgress />
			) : (
				<>
					<DialogHeader title="Volumenlizenz Infos" />
					<MarkdownTxt>{statistics}</MarkdownTxt>
				</>
			)}
		</DialogWrapper>
	);
};

const Row = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
`;

const VolumeLicenseListView = (): JSX.Element | null => {
	const volumeLicenses = useVolumeLicenses();

	const tableData = React.useMemo(
		() =>
			volumeLicenses?.map((vL) => ({
				id: vL.id,
				isLimited: vL.isLimited ? 'Ja' : 'Nein',
				limitedUnit: vL.limitedUnit,
				limitedFactor: vL.limitedFactor,
				numberOfLicenses: vL.numberOfLicenses,
				takenSeats: vL.takenSeats,
				remainingSeats: vL.remainingSeats,
				products: vL.products?.map(({ title }) => title).join(', '),
				company: vL.company?.title,
				createdAt: vL.createdAt,
				active: vL.active ? 'Ja' : 'Nein',
				userAudienceType: vL.userAudienceType,
				finishInDays: vL.finishInDays,
			})),
		[volumeLicenses]
	);

	const handleOpenUpsertVolumeLicenseDialog = React.useCallback((id) => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<UpsertVolumeLicenseDialog dismissPortal={dismissPortal} id={id} />
			),
			isLocked: true,
		});
	}, []);

	const handleOpenStatisticsDialog = React.useCallback((id) => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<VolumeLicenseStatisticsDialog dismissPortal={dismissPortal} id={id} />
			),
		});
	}, []);

	const renderActions = React.useCallback(
		({ id }) => {
			return (
				<Row>
					<IconButton
						m="0 1rem 0 0"
						iconName="EditRounded"
						onClick={handleOpenUpsertVolumeLicenseDialog}
						id={id}
					/>
					<IconButton m="0 1rem 0 0" navigateTo={`/volume-license/${id}`} iconName="PersonAdd" />
					<IconButton m="0 1rem 0 0" onClick={handleOpenStatisticsDialog} id={id} iconName="Info" />
				</Row>
			);
		},
		[handleOpenStatisticsDialog, handleOpenUpsertVolumeLicenseDialog]
	);

	return !tableData ? (
		<ContentWrapper>{null}</ContentWrapper>
	) : (
		<ContentWrapper p="2rem">
			<Txt m="0 0 2rem 0" variant="h6">
				Volumenlizenzen
			</Txt>
			<SimpleTable4 renderData={tableData} renderActions={renderActions} />
			<Button m="1rem 0 0 0" variant="mainButton" onClick={handleOpenUpsertVolumeLicenseDialog}>
				Hinzufügen
			</Button>
		</ContentWrapper>
	);
};

export default VolumeLicenseListView;
