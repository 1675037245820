import React from 'react';
import Box from '@mui/material/Box';
import { ContentInfos, useFilteredContents } from '../utils/content';
import RadioButtonSection from './RadioButtonSection';
import { CircularProgress } from '@mui/material';
import useFilterSections from '../utils/useFilterSections';
import { CurrentUser } from '../utils/user';
import { SortType } from './SortButton';
import { useIsMobile } from '../utils/hooks';
import Expandable from './Expandable';

type FilterType =
	| 'COLLECTION'
	| 'PRODUCT'
	| 'DURATION'
	| 'EDUCATION_LEVEL'
	| 'MEMBERSHIP'
	| 'AUDIENCE';

const FilterSectionRender = ({
	contents,
	onChange,
	audienceType,
	p,
	m,
}: {
	contents?: ContentInfos[];
	onChange: (numFiltersApplied: number, filteredContents?: ContentInfos[]) => void;
	audienceType?: CurrentUser['audienceType'];
	p?: string;
	m?: string;
}): JSX.Element => {
	// calculate the selectable filters based on the provided contents
	const filterSections = useFilterSections(contents);

	const isMobile = useIsMobile();

	// change selected filters
	const [filters, setFilters] = React.useState<
		{
			type: FilterType;
			value: null | string | number;
		}[]
	>(
		audienceType
			? [
				{
					type: 'AUDIENCE',
					value: audienceType,
				},
			]
			: []
	);
	const handleChangeFilter = React.useCallback(
		({ identifier, value }: { identifier?: string | null; value?: string | number | null }) => {
			if (!identifier || !value) {
				return;
			}
			const typeAlreadyInFilters = filters.some((f) => f.type === identifier);
			const typeAndIdAlreadyInFilters = filters.some(
				(f) => f.type === identifier && f.value === value
			);
			const updatedFilters = typeAndIdAlreadyInFilters
				? filters.filter((f) => f.type !== identifier && f.value !== value)
				: typeAlreadyInFilters
					? [
						...filters.filter((f) => f.type !== identifier),
						{ type: identifier as FilterType, value },
					]
					: [...filters, { type: identifier as FilterType, value }];
			setFilters(updatedFilters);
		},
		[filters]
	);

	// calculate filteredContents from selected filters
	const filteredContents = useFilteredContents(
		{
			categoryTypeFiltersEvery: filters
				.filter((f) =>
					['COLLECTION', 'EDUCATION_FORMAT', 'EDUCATION_LEVEL', 'AUDIENCE'].includes(f.type)
				)
				.map((f) => f.value) as string[],
			contentIsContainedInProductIds: filters
				.filter((f) => f.type === 'PRODUCT')
				.map((f) => f.value) as string[],
			contentIsContainedInMembershipIds: filters
				.filter((f) => f.type === 'MEMBERSHIP')
				.map((f) => f.value) as string[],
		},
		contents
	);

	// report filtered contents to parent component
	React.useEffect(() => {
		onChange(filters.length, filteredContents);
	}, [filteredContents, onChange, filters]);

	return !filterSections ? (
		<CircularProgress />
	) : (
		<Box display="flex" flexDirection="column" p={p} m={m}>
			{filterSections.map(({ label, type, options }) => {
				return isMobile ? (
					<Expandable key={label} label={label} hideElevation hasTitleLineBreak>
						<Box p="1rem">
							<RadioButtonSection
								// title={label}
								options={options}
								id={type}
								defaultValue={filters.find((f) => f.type === type)?.value}
								onChange={handleChangeFilter}
							/>
						</Box>
					</Expandable>
				) : (
					<Box key={label}>
						<RadioButtonSection
							title={label}
							options={options}
							id={type}
							defaultValue={filters.find((f) => f.type === type)?.value}
							onChange={handleChangeFilter}
						/>
					</Box>
				);
			})}
		</Box>
	);
};

export default React.memo(FilterSectionRender);
