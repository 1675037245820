import React from 'react';
import { debounce } from 'lodash';
import { useTheme } from 'styled-components';
import { useActiveBreakPoint } from './theme';
import {
	useLocation,
	useSearchParams,
	useNavigate as useNavigateReactRouterDom,
} from 'react-router-dom';
import { useCurrentUser, CURRENT_USER_QUERY, useUserType } from './user';
import { CONTENT_INFO_QUERY } from './content';
import client from '../utils/client';
import { useSnackbar } from './snackBar';
import Dialog from '../components/Dialog';
import createCertificate from '../mutations/createCertificate';
import {
	SPACING_DESKTOP_REM,
	SPACING_MOBILE_REM,
	SECTION_WRAPPER_VERTICAL_SPACING_MOBILE_REM,
	SECTION_WRAPPER_VERTICAL_SPACING_DESKTOP_REM,
} from '../consts';

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

export const useWindowDimensions = (): { width: number; height: number } => {
	const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

	const handleResize = React.useCallback(() => {
		setWindowDimensions(getWindowDimensions());
	}, []);

	React.useEffect(() => {
		setWindowDimensions(getWindowDimensions());
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]);

	return windowDimensions;
};

type DivDimensions = {
	bottom?: number;
	height?: number;
	left?: number;
	right?: number;
	top?: number;
	width?: number;
};

export const useDivDimensions = (
	ref: React.RefObject<HTMLDivElement>,
	event: 'scroll' | 'resize' | 'scroll&resize' = 'scroll&resize',
	deps?: unknown[],
	debounceMs?: number
): DivDimensions => {
	const [rect, setRect] = React.useState<DivDimensions>({
		bottom: undefined,
		height: undefined,
		left: undefined,
		right: undefined,
		top: undefined,
		width: undefined,
	});

	const handleMeasure = React.useCallback(() => {
		if (ref?.current) {
			setRect(ref.current.getBoundingClientRect());
		}
	}, [ref]);

	const debouncedHandleMeasure = React.useMemo(
		() => debounce(handleMeasure, debounceMs || 300),
		[debounceMs, handleMeasure]
	);

	React.useEffect(() => {
		if (event.includes('scroll')) {
			window.addEventListener('scroll', debounceMs ? debouncedHandleMeasure : handleMeasure);
		}
		if (event.includes('resize')) {
			window.addEventListener('resize', debounceMs ? debouncedHandleMeasure : handleMeasure);
		}
		handleMeasure();
		return () => {
			if (event.includes('scroll')) {
				window.removeEventListener('scroll', debounceMs ? debouncedHandleMeasure : handleMeasure);
			}
			if (event.includes('resize')) {
				window.removeEventListener('resize', debounceMs ? debouncedHandleMeasure : handleMeasure);
			}
		};
	}, [debouncedHandleMeasure, event, handleMeasure, ref, deps, debounceMs]);

	return rect;
};

export const useScrolled = (
	ref: React.RefObject<HTMLDivElement>
): { scrolledFromLeft: number; scrolledFromRight: number } => {
	const [scrollLeft, setScrollLeft] = React.useState(0);
	const [scrollWidth, setScrollWidth] = React.useState(0);
	const [width, setWidth] = React.useState(0);

	const scrolledFromRight = React.useMemo(
		() => scrollWidth - width - scrollLeft,
		[scrollLeft, scrollWidth, width]
	);
	const scrolledFromLeft = React.useMemo(
		() => scrollWidth - width - scrolledFromRight,
		[scrolledFromRight, scrollWidth, width]
	);

	const handleMeasure = React.useCallback(() => {
		if (ref?.current) {
			setScrollLeft(ref.current.scrollLeft);
			setScrollWidth(ref.current.scrollWidth);
			const { width: divWidth } = ref.current.getBoundingClientRect();
			setWidth(divWidth);
		}
	}, [ref]);

	const debouncedHandleMeasure = React.useMemo(() => debounce(handleMeasure, 300), [handleMeasure]);

	React.useEffect(() => {
		ref.current?.addEventListener('scroll', debouncedHandleMeasure);
		handleMeasure();
	});

	return { scrolledFromRight, scrolledFromLeft };
};

export const hexToRgba = (hex: string, opacity: number) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	if (!result || !result[0] || !result[1] || !result[2] || !result[3])
		return `rgba(255, 0, 0, 0.5)`;
	const [r, g, b] = result
		? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
		: [0, 0, 0];
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const usePxPerRemFactor = (): number => {
	const theme = useTheme();
	const pxPerRem = 1 / Number(theme.typography.pxToRem(1).replace('rem', ''));
	return pxPerRem;
};

export const usePotentiallyScrollToTop = () => {
	const [searchParams] = useSearchParams();
	const scrollToTop = searchParams.get('scrollToTop') || '';

	const handler = React.useCallback(() => {
		const newLocation = window.location.href;
		const updatedNewLocation = newLocation.includes('?scrollToTop=true&') // beginning with other params
			? newLocation.replace('scrollToTop=true&', '')
			: newLocation.includes('?scrollToTop=true') // beginning as single param
			? newLocation.replace('?scrollToTop=true', '')
			: newLocation.includes('scrollToTop=true&') // in between
			? newLocation.replace('scrollToTop=true&', '')
			: newLocation.includes('scrollToTop=true') // last param
			? newLocation.replace('scrollToTop=true', '')
			: newLocation;
		window.history.replaceState({}, '', updatedNewLocation);
	}, []);

	React.useEffect(() => {
		window.addEventListener('popstate', handler);
		return () => window.removeEventListener('popstate', handler);
	}, [handler]);

	// we generate a syntehtic dependency here, to trigger the React.useEffect in case it should scroll to top and the route did change
	const pathNameDependency = scrollToTop === 'true' ? window.location.pathname : '';

	// Scroll to top when there is a scrollToTop param in the url
	React.useLayoutEffect(() => {
		if (pathNameDependency) {
			window.scrollTo(0, 0);
		}
	}, [pathNameDependency]);
};

export const useNavigate = () => {
	const navigate = useNavigateReactRouterDom();
	return React.useCallback(
		(route) => {
			navigate(route.includes('?') ? `${route}&scrollToTop=true` : `${route}?scrollToTop=true`);
		},
		[navigate]
	);
};

//eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useInitializeStateWithDefault = <T extends any>({
	defaultValue,
	finishedInitializing,
}: {
	defaultValue: T;
	finishedInitializing?: boolean;
}): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, boolean] => {
	const [state, setState] = React.useState<T | undefined>(defaultValue);
	const [isInitialized, setIsInitialized] = React.useState(false);

	React.useEffect(() => {
		if (finishedInitializing) {
			setState(defaultValue);
			setIsInitialized(true);
		}
	}, [finishedInitializing, defaultValue]);

	const result = React.useMemo(() => [state, setState, isInitialized], [isInitialized, state]);
	return result as [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, boolean];
};

export const useIsMobile = (): boolean => {
	const activeBreakpoint = useActiveBreakPoint();
	const isMobile = activeBreakpoint === 'xs' || activeBreakpoint === 'sm';
	return isMobile;
};

export const useIsHideBannerRoute = (): boolean => {
	const location = useLocation();
	return (
		location.pathname === '/data-privacy' ||
		location.pathname === '/imprint' ||
		location.pathname === '/terms'
	);
};

export const useDialogWidthPx = (): string => {
	const MOBILE_WIDTH_REM = 25;
	const DESKTOP_WIDTH_REM = 50;
	const pxPerRem = usePxPerRemFactor();
	const mobileWidthPx = MOBILE_WIDTH_REM * pxPerRem;
	const desktopWidthPx = DESKTOP_WIDTH_REM * pxPerRem;
	const { width: windowWidthPx } = useWindowDimensions();
	const isMobile = useIsMobile();
	const dialogWidth = !isMobile
		? desktopWidthPx
		: Math.min(mobileWidthPx, windowWidthPx - 2 * pxPerRem);
	return `${dialogWidth}px`;
};

export const useDialogDimensions = (): { width: string; maxHeight: string } => {
	const MOBILE_WIDTH_REM = 25;
	const DESKTOP_WIDTH_REM = 50;
	const pxPerRem = usePxPerRemFactor();
	const mobileWidthPx = MOBILE_WIDTH_REM * pxPerRem;
	const desktopWidthPx = DESKTOP_WIDTH_REM * pxPerRem;
	const { width: windowWidthPx, height: windowHeight } = useWindowDimensions();
	const isMobile = useIsMobile();
	const dialogWidth = !isMobile
		? desktopWidthPx
		: Math.min(mobileWidthPx, windowWidthPx - 2 * pxPerRem);

	const MOBILE_HEIGHT_REM = 30;
	const DESKTOP_HEIGHT_REM = 60;
	const mobileHeightPx = MOBILE_HEIGHT_REM * pxPerRem;
	const desktopHeightPx = DESKTOP_HEIGHT_REM * pxPerRem;
	const dialogHeight = Math.min(
		!isMobile ? desktopHeightPx : mobileHeightPx,
		windowHeight - 2 * pxPerRem
	);

	return { width: `${dialogWidth}px`, maxHeight: `${dialogHeight}px` };
};

export const useSpacingRem = (): number => {
	const isMobile = useIsMobile();
	const spacingRem = isMobile ? SPACING_MOBILE_REM : SPACING_DESKTOP_REM;
	return spacingRem;
};

export const useSectionTopSpacingRem = (): number => {
	const isMobile = useIsMobile();
	const spacingRem = isMobile
		? SECTION_WRAPPER_VERTICAL_SPACING_MOBILE_REM
		: SECTION_WRAPPER_VERTICAL_SPACING_DESKTOP_REM;
	return spacingRem;
};

// !!! use FlexWrapper component instead, -> e.g. how it is used in CompanyDashboardView
export const useNumberOfColumns = ({
	ref,
	minimumSize,
	columnGapPx = 0,
	horizontalPaddingPx = 0,
	deps,
}: {
	ref: React.RefObject<HTMLDivElement>;
	minimumSize: number;
	columnGapPx?: number;
	horizontalPaddingPx?: number;
	deps?: unknown[];
}): { numberOfColumns: number; columnWidth: number } => {
	const { width } = useDivDimensions(ref, 'resize', deps);
	const availableWidth = Math.floor(width ? width - 2 * horizontalPaddingPx : 1);
	console.log({ width, availableWidth, minimumSize });

	const numberOfColumns = availableWidth
		? Math.floor((availableWidth - 2 * horizontalPaddingPx) / minimumSize)
		: 1;
	const correctedAvailableWidth = (availableWidth || 0) - (numberOfColumns - 1) * columnGapPx;
	const correctedNumberOfColumns = Math.floor(correctedAvailableWidth / minimumSize) || 1;
	const columnWidth = correctedAvailableWidth / correctedNumberOfColumns;
	return { numberOfColumns: correctedNumberOfColumns, columnWidth };
};

export const useGrouped = ({
	items,
	nGroups,
}: {
	items?: unknown[];
	nGroups: number;
}): Array<unknown[]> => {
	const initial = Array.from(Array(nGroups).keys()).map(() => []) as Array<unknown[]>;
	const grouped = React.useMemo(
		() =>
			items?.length
				? items.reduce<Array<unknown[]>>((acc, next) => {
						const minimumNumberOfItems = acc
							.map((a) => a.length)
							.sort((a, b) => (a > b ? 1 : -1))[0];
						const arrayToPushTo = acc.findIndex((a) => a.length === minimumNumberOfItems);
						acc[arrayToPushTo]?.push(next);
						return acc;
				  }, initial)
				: [[]],
		[initial, items]
	);
	return grouped;
};

const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const usePotentiallyHandleLeavePlayerView = (): void => {
	const currentUser = useCurrentUser();
	const snackBar = useSnackbar();

	const handleFinish = React.useCallback(
		async (cId) => {
			if (!currentUser) return;

			snackBar({ info: 'Kursfortschritt wird gespeichert...' });

			// wait for 3 seconds to make sure all the progress already landed in the database
			await delay(3000);

			// get progress of the content
			const { data } = await client.query({
				query: CONTENT_INFO_QUERY,
				fetchPolicy: 'network-only',
				variables: {
					id: cId,
				},
			});

			const comlpetion = data?.content?.completion;
			const hasCertificate = data?.content?.hasCertificate;
			const progressedVersion = data?.content?.progressedVersion;

			// update progressedContents
			if (comlpetion) {
				const { currentUser: currentUserBefore } = client.readQuery({
					query: CURRENT_USER_QUERY,
				});
				const updatedCurrentUser = {
					...currentUserBefore,
					progressedContents: [
						data.content,
						...currentUserBefore.progressedContents.filter((c) => c.id !== data.content.id),
					],
				};
				client.cache.writeQuery({
					query: CURRENT_USER_QUERY,
					data: { currentUser: updatedCurrentUser },
				});
			}

			// evaluate if we must create a certificate
			const generateCertificate =
				comlpetion === 1 &&
				hasCertificate &&
				!currentUser.certificates.some(
					({ content, version }) => content.id === cId && version === progressedVersion
				) &&
				!currentUser.isAccessPhraseUser;

			if (!generateCertificate) return;

			Dialog.render(
				{
					title: 'Kurs abgeschlossen!',
					description: 'Zertifikat wird erstellt...',
					isLocked: true,
				},
				'create-certificate'
			);
			const { success, error } = await createCertificate(cId!);
			Dialog.unmount('create-certificate');

			if (success) {
				Dialog.render({
					title: 'Zertifikat erstellt!',
					description:
						'Herzlichen Glückwunch, du hast diesen Kurs abgeschlossen. Dein Zertifikat findest du in deinem Dashboard.',
				});
			} else {
				snackBar({ error });
			}
		},
		[currentUser, snackBar]
	);

	const currentPath = useLocation()?.pathname;

	const pathHistory = React.useRef<string[]>([]);

	React.useEffect(() => {
		const lastPath = pathHistory.current[pathHistory.current.length - 1];
		if (lastPath === currentPath) return;
		pathHistory.current.push(currentPath);
		if (lastPath && lastPath.includes('/player/') && !currentPath.includes('/player/')) {
			const contentId = lastPath.split('/player/')[1];
			handleFinish(contentId);
		}
	}, [currentPath, handleFinish]);
};

export const useLabelAndValue = ({
	items,
	labelKey,
	valueKey,
}: {
	items?: { [key: string]: string | unknown }[] | null;
	labelKey: string;
	valueKey: string;
}): { label: string; value: string }[] | undefined => {
	return React.useMemo(
		() =>
			items?.map(
				(item) =>
					({
						label: typeof item[labelKey] === 'string' ? item[labelKey] : '?unknown',
						value: typeof item[valueKey] === 'string' ? item[valueKey] : '?unknown',
					} as { label: string; value: string })
			),
		[items, labelKey, valueKey]
	);
};

export const useTrackPageViews = () => {
	const location = useLocation();
	React.useEffect(() => {
		//@ts-ignore
		const paq = window._paq as unknown as any[];
		if (paq) {
			paq.push(['setCustomUrl', `${location.pathname}${location.search}${location.hash}`]);
			paq.push(['trackPageView']);
		}
	}, [location]);
};

export const usePotentiallyNavigateToAdminRoute = () => {
	const userType = useUserType();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const initial = searchParams.get('initial') || '';
	const isCompanyAdmin = userType === 'COMPANY_ADMIN' || userType === 'COMPANY_DEPARTMENT_ADMIN';

	React.useEffect(() => {
		if (isCompanyAdmin && initial === 'true') {
			navigate('/admin');
		}
	}, [userType, navigate, isCompanyAdmin, initial]);
};
