import client from '../utils/client';
import { gql } from '@apollo/client';
import { ContentInfosFragment } from '../utils/fragments';

const deleteProgress = async (
	id: string
): Promise<{ success: true; error: undefined } | { success: false; error: string }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation DeleteProgress($id: ID!) {
					deleteProgress(id: $id) {
						...ContentInfosFragment
					}
				}
				${ContentInfosFragment}
			`,
			variables: { id },
		});
		if (errors) {
			return { success: false, error: errors[0]?.message || 'UNKNOWN' };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default deleteProgress;
