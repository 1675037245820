import React from 'react';
import styled, { useTheme } from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';

const Wrapper = styled(Box)`
	display: flex;
	flex-direction: row;
	border-radius: 0.5rem;
	height: 1rem;
`;

const ProgressBar = ({
	progress,
	width = '100%',
	m,
	color,
	completeColor,
	...rest
}: {
	progress?: number;
	width?: string;
	m?: string;
	color?: string;
	completeColor?: string;
} & BoxProps): JSX.Element => {
	const theme = useTheme();
	const complete = progress === 1;
	const finalColor = color || theme.customColors.secondaryGrey;
	return (
		<Wrapper
			{...rest}
			border={`2px solid ${complete ? completeColor || finalColor : finalColor}`}
			width={width}
			m={m}
		>
			<Box
				width={progress ? progress * 100 + '%' : '0%'}
				height="100%"
				bgcolor={complete ? completeColor || finalColor : finalColor}
				borderRadius="0.5rem"
			></Box>
		</Wrapper>
	);
};

export default ProgressBar;
