import React from 'react';
import { Grid, Box } from '@mui/material';
import styled from 'styled-components';

import Txt from '../components/Txt';
import ImageBox from '../components/ImageBox';
import GridContainer from '../components/GridContainer';
import ContentWrapper from '../components/ContentWrapper';
import ImageHeader from '../components/ImageHeader';
import SectionHeader from '../components/SectionHeader';

import image1 from '../images/Demo_Admin.svg';
import image2 from '../images/Demo_Benutzer.svg';
import image3 from '../images/Demo_Benutzerverwaltung.svg';
import image4 from '../images/Demo_Header.png';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import MarkdownTxt from '../components/MarkdownTxt';

import loginImage from '../images/login-image.png';

const FlexGridColumn = styled(Grid)`
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
`;

const data = [
	{
		icon: <ImageBox src={image2} fontSize="large" />,
		title: 'Benutzer',
		description:
			'Als Benutzer können Mitarbeiter*innen die Ihnen zugewiesenen Kurse absolvieren, Fristen im Blick behalten und ihre Zertifikate einsehen.',
	},
	{
		icon: <ImageBox src={image3} fontSize="large" />,
		title: 'Benutzerverwaltung',
		description:
			'Verantwortliche können Benutzer verwalten, neue Benutzer anlegen und  Kurse zuweisen. In der Übersicht behalten sie Fristen und den Kursstatus im Blick.',
	},
	{
		icon: <ImageBox src={image1} fontSize="large" />,
		title: 'Administrator',
		description:
			'Administratoren können Verantwortliche (z. B. Abteilungen) verwalten, Gruppenlizenzen vergeben und individuelle Kursfristen festlegen. Sie behalten den Gesamtüberblick.',
	},
];

const horizontalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };
const verticalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };

const sectionSubtitle = `###### Individualisieren Sie die Lernplattform auf Ihre Bedürfnisse und behalten Sie den Überblick über notwendige Weiterbildungen Ihrer Mitarbeiterinnen. Mit der automatischen Erinnerungsfunktion verpassen sie keine Schulung mehr, sodass alle Mitarbeiterinnen und Mitarbeiter stets auf dem neuesten Stand sind. Die Nutzerverwaltung ermöglicht die volle Kontrolle über alle Teilnehmerinnen und deren Fortschritt. Individuelle Zugriffsrechte vergeben und steuern Sie mit dem benutzerfreundlichen Rollenmanagement und behalten so den Überblick. Nutzen Sie die komfortable Einladungsfunktion, um neue Teilnehmer*innen schnell und mühelos hinzuzufügen.

\n ###### Dank der unkomplizierten Bedienung ist die Lernplattform in wenigen Minuten einsatzbereit.`;

const HowItWorksView = () => {
	return (
		<ContentWrapper>
			<ImageHeader title="So funktioniert's" src={image4} />
			<SectionWithoutBackground flexDirection="column">
				<GridContainer
					excludePadding
					horizontalSpacing={horizontalSpacing}
					verticalSpacing={verticalSpacing}
				>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<SectionHeader
							alignItems="flex-start"
							title="Volles Potenzial für die Mitarbeiterentwicklung"
							hasBottomMargin
						/>
						<MarkdownTxt>{sectionSubtitle}</MarkdownTxt>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Box
							display="flex"
							width="100%"
							height="100%"
							justifyContent="center"
							alignItems="center"
						>
							<ImageBox src={loginImage} width="25rem" objectFit="contain" />
						</Box>
					</Grid>
				</GridContainer>
			</SectionWithoutBackground>
			<SectionWithoutBackground>
				<GridContainer
					excludePadding
					horizontalSpacing={horizontalSpacing}
					verticalSpacing={verticalSpacing}
				>
					<Grid item xs={12}>
						<SectionHeader title="Verwalten Sie Ihre Benutzerrollen" hasBottomMargin />
					</Grid>
					<>
						{data.map(({ icon, title, description }) => (
							<FlexGridColumn key={title} item xs={12} sm={12} md={4}>
								<Box display="flex" flexDirection="row" justifyContent="center" height="7rem">
									{icon}
								</Box>
								<Txt align="center" width="100%" m="1rem 0" variant="h6">
									{title}
								</Txt>
								<Txt align="center">{description}</Txt>
							</FlexGridColumn>
						))}
					</>
				</GridContainer>
			</SectionWithoutBackground>
			<></>
		</ContentWrapper>
	);
};

export default HowItWorksView;
