import React from 'react';

import TextField from './TextField';
import { useSnackbar } from '../utils/snackBar';
import Button from './Button';
import DialogWrapper from './DialogWrapper';
import Dialog, { DialogButtonRow, DialogHeader } from './Dialog';
import inviteToVolumeLicense from '../mutations/inviteToVolumeLicense';
import { TextFieldRef } from './TextField';
import Txt from './Txt';

const InviteToVolumeLicenseDialog = ({
	dismissPortal,
	id,
	productTitles,
}: {
	dismissPortal: () => void;
	id?: string;
	productTitles?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();

	const [csv, setCsv] = React.useState('');
	const handleChangeCsv = React.useCallback(
		(value) => {
			setCsv(value);
		},
		[setCsv]
	);

	const [firstName, setFirstName] = React.useState('');
	const handleChangeFirstName = React.useCallback(
		(value) => {
			setFirstName(value);
		},
		[setFirstName]
	);

	const [lastName, setLastName] = React.useState('');
	const handleChangeLastName = React.useCallback(
		(value) => {
			setLastName(value);
		},
		[setLastName]
	);

	const firstNameFieldRef = React.useRef<TextFieldRef>(null);
	const lastNameFieldRef = React.useRef<TextFieldRef>(null);
	const emailFieldRef = React.useRef<TextFieldRef>(null);
	const csvFieldRef = React.useRef<TextFieldRef>(null);

	const [email, setEmail] = React.useState('');
	const handleChangeEmail = React.useCallback(
		(value) => {
			setEmail(value);
		},
		[setEmail]
	);

	const [loading, setLoading] = React.useState(false);

	const handleValidate = React.useCallback(() => {
		let invalid = false;
		if ((!csv && !email && !firstName && !lastName) || (csv && (email || firstName || lastName))) {
			Dialog.render({
				title: 'Ungültige Einträge',
				description:
					'Bitte füllen Sie entweder die Felder für den Einzelimport oder das Feld für den Bulk Import aus.',
			});
			invalid = true;
		}
		return invalid;
	}, [csv, email, firstName, lastName]);

	const handleInviteToVolumeLicense = React.useCallback(async () => {
		const invalid = handleValidate();
		if (invalid) {
			return;
		}
		setLoading(true);
		const { error, errorMessage } = await inviteToVolumeLicense({
			id: id!,
			csvOrTsvString: csv || `${firstName},${lastName},${email}`,
		});
		setLoading(false);
		if (error) {
			if (error === 'INVALID_ENTRIES') {
				Dialog.render({
					title: 'Ungültige Einträge',
					description: errorMessage,
					isMarkdown: true,
				});
			} else if (error === 'EXCEEDS_NUMBER_OF_LICENSES') {
				Dialog.render({
					title: 'Unzureichende Volumenlizenz',
					description: errorMessage,
					isMarkdown: true,
				});
			} else {
				snackBar({ error });
			}
		} else {
			snackBar({ success: 'Nutzer erfolgreich hinzugefügt' });
			dismissPortal();
		}
	}, [csv, dismissPortal, email, firstName, handleValidate, id, lastName, snackBar]);

	return (
		<DialogWrapper p="2rem">
			<>
				<DialogHeader title="Nutzer zu Volumenlizenz einladen" />
				<Txt m="0 0 1rem">{productTitles}</Txt>
				<Txt m="2rem 0 1rem" variant="h6">
					Einzelanlage
				</Txt>
				<TextField
					label="Vorname"
					onChange={handleChangeFirstName}
					m="2rem 0 0 0"
					width="100%"
					ref={firstNameFieldRef}
				/>
				<TextField
					label="Nachname"
					onChange={handleChangeLastName}
					m="2rem 0 0 0"
					width="100%"
					ref={lastNameFieldRef}
				/>
				<TextField
					label="Email-Adresse"
					onChange={handleChangeEmail}
					m="2rem 0 0 0"
					width="100%"
					ref={emailFieldRef}
				/>
				<Txt m="2rem 0 1rem" variant="h6">
					Bulk Import
				</Txt>
				<Txt>
					Kopieren Sie die Informationen der Nutzer aus der CSV oder Excel Datei hier herein. Die
					Struktur muss wie folgt sein: Vorname, Name, Email-Adresse oder Vorname; Name;
					Email-Adresse.
				</Txt>
				<TextField
					label="CSV oder SSV mit Vorname, Nachname, E-Mail-Adresse"
					onChange={handleChangeCsv}
					m="2rem 0 0 0"
					width="100%"
					multiline
					maxRows={10}
					ref={csvFieldRef}
				/>
				<DialogButtonRow>
					<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
						Abbrechen
					</Button>
					<Button variant="mainButton" onClick={handleInviteToVolumeLicense} loading={loading}>
						Einladen
					</Button>
				</DialogButtonRow>
			</>
		</DialogWrapper>
	);
};

export default InviteToVolumeLicenseDialog;
