import React from 'react';
import Box from '@mui/material/Box';

import { ContentInfos } from '../utils/content';
import ContentTile, { WIDTH } from './ContentTile';
import { CircularProgress } from '@mui/material';
import ContentWrapper from './ContentWrapper';
import DesktopFilterSection from './DesktopFilterSection';
import { CurrentUser } from '../utils/user';
import { useIsMobile, useNumberOfColumns, usePxPerRemFactor } from '../utils/hooks';
import MobileFilterSection from './MobileFilterSection';

const COLUMN_GAP = 1;

const FilterableContents = ({
	contents,
	audienceType,
	defaultShowFilter,
}: {
	contents?: ContentInfos[];
	audienceType?: CurrentUser['audienceType'];
	defaultShowFilter?: boolean;
}): JSX.Element => {
	const isMobile = useIsMobile();

	const [sortedFilteredContents, setSortedFilteredContents] = React.useState<
		ContentInfos[] | undefined
	>(contents);

	const handleChangeFilteredContents = React.useCallback((newFilteredContents?: ContentInfos[]) => {
		setSortedFilteredContents(newFilteredContents);
	}, []);

	const [isCollapsed, setIsCollapsed] = React.useState<boolean>(
		defaultShowFilter !== undefined ? !defaultShowFilter : true
	);

	const deps = React.useMemo(() => [contents, isMobile], [contents, isMobile]);
	const ref = React.useRef<HTMLDivElement>(null);
	const pxPerRemFactor = usePxPerRemFactor();
	const widthPx = pxPerRemFactor * WIDTH;
	const { numberOfColumns } = useNumberOfColumns({
		ref,
		minimumSize: widthPx,
		columnGapPx: pxPerRemFactor * COLUMN_GAP,
		deps,
	});
	const numberOfTailingTiles = sortedFilteredContents?.length
		? numberOfColumns - (sortedFilteredContents?.length % numberOfColumns)
		: 0;

	let mainFlexDirection: 'row' | 'column' = 'row';
	if (isMobile) {
		mainFlexDirection = 'column';
	}
	if (isCollapsed) {
		// If no filter is selected, then we want to show the filter buttons on tob op the content
		mainFlexDirection = 'column';
	}
	return !contents ? (
		<CircularProgress />
	) : (
		<ContentWrapper>
			<Box display="flex" flexDirection={mainFlexDirection} width="100%">
				{isMobile ? (
					<MobileFilterSection
						contents={contents}
						onChange={handleChangeFilteredContents}
						// TODO: this is set to false for now, as there is a weird scrolling bug
						defaultShowFilter={false}
						audienceType={audienceType}
					/>
				) : (
					<DesktopFilterSection
						contents={contents}
						onChange={handleChangeFilteredContents}
						audienceType={audienceType}
						isCollapsed={isCollapsed}
						setIsCollapsed={setIsCollapsed}
					/>
				)}
				<Box
					display="flex"
					flexDirection="row"
					justifyContent={isMobile ? 'center' : 'flex-start'}
					flexWrap="wrap"
					alignContent="flex-start"
					columnGap={`${COLUMN_GAP}rem`}
					rowGap="1rem"
					width="100%"
					ref={ref}
				>
					{sortedFilteredContents?.map((content) => (
						<ContentTile key={content.id} {...content} />
					))}
					{Array(numberOfTailingTiles)
						.fill('_')
						?.map((_, index) => (
							<Box width={`${WIDTH}rem`} key={index} />
						))}
				</Box>
			</Box>
		</ContentWrapper>
	);
};

export default React.memo(FilterableContents);
