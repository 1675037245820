import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

import Txt from './Txt';
import Button from './Button';
import IconButton from './IconButton';
import { DIALOG_BACKGROUND_ZINDEX, DIALOG_ZINDEX } from '../consts';

import PortalProvider from './PortalProvider';
import { BoxProps } from '@mui/system';
import MarkdownTxt from './MarkdownTxt';

type DialogProps = {
	title?: string;
	description?: string;
	onClose?: () => void;
	renderContent?: (val: {
		dismissPortal: () => void;
		onSuccess?: () => void;
		id?: string;
	}) => JSX.Element;
	dismissPortal: () => void;
	buttons?: { id: string; label: string; onClick?: () => void; variant?: ButtonProps['variant'] }[];
	width?: string;
	backgroundColor?: string;
	wrapperStyle?: { [key: string]: string | number };
	isLocked?: boolean;
	onSuccess?: () => void;
	isMarkdown?: boolean;
};

const Background = styled(Box)`
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	background-color: grey;
	opacity: 50%;
	z-index: ${DIALOG_BACKGROUND_ZINDEX};
`;

const ContentWrapper = styled(Box)`
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 1rem;
	position: fixed;
	z-index: ${DIALOG_ZINDEX};
	overflow: scroll;
`;

export const DialogHeader = ({
	title,
	description,
	isMarkdown,
}: {
	title?: string;
	description?: string;
	isMarkdown?: boolean;
}) => {
	return (
		<>
			<Txt m="0 0 1rem 0" variant="h6">
				{title}
			</Txt>
			{isMarkdown && description ? (
				<MarkdownTxt>{description}</MarkdownTxt>
			) : (
				<Txt m="0 0 1rem 0">{description}</Txt>
			)}
		</>
	);
};

export const DialogButtonRow = (props: BoxProps) => {
	return (
		<Box
			width="100%"
			m="2rem 0 0"
			display="flex"
			flexDirection="row"
			justifyContent="flex-end"
			alignItems="center"
			flexWrap="wrap"
			rowGap="1rem"
			{...props}
		>
			{props.children}
		</Box>
	);
};

const Dialog = ({
	renderContent,
	title,
	description,
	onClose,
	dismissPortal,
	buttons,
	isLocked,
	onSuccess,
	isMarkdown,
}: DialogProps): JSX.Element => {
	const handleClose = React.useCallback(() => {
		onClose?.();
		dismissPortal();
	}, [dismissPortal, onClose]);

	const handleClick = React.useCallback(
		(buttonId: string) => {
			buttons?.find((button) => button.id === buttonId)?.onClick?.();
			dismissPortal();
		},
		[buttons, dismissPortal]
	);

	const handleStopPropagation = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	}, []);

	return (
		<Box>
			<ContentWrapper onClick={isLocked ? undefined : handleClose} id="dialog">
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					width="100%"
					minHeight="100%"
					flex="1"
				>
					<Box
						display="flex"
						bgcolor="background.paper"
						borderRadius="1rem"
						padding={renderContent ? undefined : '3rem'}
						boxShadow="24"
						onClick={handleStopPropagation}
						flexDirection="column"
						overflow="hidden"
						position="relative"
					>
						{renderContent ? (
							renderContent({ dismissPortal: handleClose, onSuccess: onSuccess })
						) : (
							<DialogHeader title={title} description={description} isMarkdown={isMarkdown} />
						)}

						{buttons?.length ? (
							<DialogButtonRow>
								{buttons.map(({ id: buttonId, label, onClick, variant }) => (
									<Button
										m="0 0 0 1rem"
										key={buttonId}
										id={buttonId}
										onClick={onClick ? handleClick : handleClose}
										variant={variant}
									>
										{label}
									</Button>
								))}
							</DialogButtonRow>
						) : null}
						{isLocked ? null : (
							<Box
								position="absolute"
								right="0"
								top="0"
								display="flex"
								alignItems="center"
								justifyContent="center"
								p="1.5rem"
							>
								<IconButton iconName="CloseOutlined" onClick={handleClose} />
							</Box>
						)}
					</Box>
				</Box>
			</ContentWrapper>
			<Background />
		</Box>
	);
};

const dialogStack: string[] = [];
Dialog.render = (props: Omit<DialogProps, 'dismissPortal'>, id?: string): void => {
	if (!id) {
		id = uuidv4() as string;
		dialogStack.push(id);
	} else {
		if (!dialogStack.includes(id)) {
			dialogStack.push(id);
		}
	}
	PortalProvider.render(id, Dialog, props);
};
Dialog.unmount = (id?: string) => {
	if (!id) id = dialogStack.pop();
	if (id) PortalProvider.unmount(id);
};

export default Dialog;
