import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from 'styled-components';

import ContentWrapper from '../components/ContentWrapper';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import SectionHeader from '../components/SectionHeader';
import { useCurrentUser } from '../utils/user';
import Txt from '../components/Txt';
import IconButton from '../components/IconButton';
import Button from '../components/Button';
import RemoveFromVolumeLicensesDialog from '../components/RemoveFromVolumeLicensesDialog';
import Dialog from '../components/Dialog';
import { getDate } from '../utils/time';
import DeleteFromCompanyDialog from '../components/DeleteFromCompanyDialog';
import CheckBox2 from '../components/CheckBox2';
import updateReportExceededDeadlines from '../mutations/updateReportExceededDeadlines';
import Card from '../components/Card';
import SimpleTable4 from '../components/SimpleTable4';
import { useTranslatedArray } from '../utils/arrayHooks';

const DepartmentTrainessListView = () => {
	const theme = useTheme();

	const currentUser = useCurrentUser();
	const department = currentUser?.companyDepartment;
	const trainees = department?.trainees;
	const employees = department?.employees;

	const employeesThatAreNotTrainees = React.useMemo(() => {
		if (!employees || !trainees) return [];
		return employees
			.filter((employee) => !trainees.some((trainee) => trainee.id === employee.id))
			.map((employee) => ({ ...employee, completed: undefined, products: undefined }));
	}, [employees, trainees]);

	const handleShowUpdateVolumeLicenseUserDialog = React.useCallback(
		async (userId) => {
			Dialog.render({
				renderContent: ({ dismissPortal }) => (
					<RemoveFromVolumeLicensesDialog
						dismissPortal={dismissPortal}
						userId={userId}
						companyDepartmentId={currentUser?.companyDepartment?.id}
					/>
				),
				isLocked: true,
			});
		},
		[currentUser?.companyDepartment?.id]
	);

	const handleDeleteUserFromCompany = React.useCallback(async (userId) => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<DeleteFromCompanyDialog dismissPortal={dismissPortal} userId={userId} />
			),
			isLocked: true,
		});
	}, []);

	const merged = React.useMemo(
		() => [...(trainees || []), ...(employeesThatAreNotTrainees || [])],
		[employeesThatAreNotTrainees, trainees]
	);

	const tableData = React.useMemo(() => {
		return merged?.map(
			({ id, emailConfirmed, completed, firstName, lastName, email, products }) => ({
				id,
				'Nutzer*in': (
					<Box display="flex" flexDirection="column">
						<Txt>
							{firstName} {lastName}
						</Txt>
						<Txt>{email}</Txt>
					</Box>
				),
				'Registriert': emailConfirmed ? (
					<IconButton iconColor={theme.customColors.green} iconName="CheckCircle" />
				) : (
					<IconButton iconName="CancelOutlined" />
				),
				'Kurs-Status': completed ? (
					<IconButton iconColor={theme.customColors.green} iconName="CheckCircle" />
				) : (
					<IconButton iconName="CancelOutlined" />
				),
				'Kurse': (
					<Box>
						{products?.map(({ productId, title }) => (
							<Txt key={productId}>{title}</Txt>
						))}
					</Box>
				),
				'Absolviert am': (
					<Box>
						{products?.map(({ productId, completedAt }) => (
							<Txt visibility={!completedAt ? 'hidden' : undefined} key={productId}>
								{getDate(completedAt) || '-'}
							</Txt>
						))}
					</Box>
				),
				'Fällig bis': (
					<Box>
						{products?.map(({ productId, finishUntil }) => (
							<Txt
								color={finishUntil && new Date().toISOString() > finishUntil ? 'red' : undefined}
								visibility={!finishUntil ? 'hidden' : undefined}
								key={productId}
							>
								{finishUntil ? getDate(finishUntil) : '-'}
							</Txt>
						))}
					</Box>
				),
			})
		);
	}, [merged, theme.customColors.green]);

	const renderActions = React.useCallback(
		({ id }) => {
			return (
				<Box display="flex" flexDirection="row" alignItems="center">
					<Button
						variant="secondButton"
						onClick={handleShowUpdateVolumeLicenseUserDialog}
						id={id}
						m="0 0.5rem 0 0"
						toolTip="Zugriff auf Lizenzen bearbeiten"
					>
						Bearbeiten
					</Button>
					<Button
						iconName="Delete"
						onClick={handleDeleteUserFromCompany}
						id={id}
						toolTip="Organisationszugehörigkeit ändern"
					/>
				</Box>
			);
		},
		[handleDeleteUserFromCompany, handleShowUpdateVolumeLicenseUserDialog]
	);

	const handleChangeReportExceededDeadlines = React.useCallback(async (value) => {
		await updateReportExceededDeadlines({ report: value });
	}, []);

	const searchableData = useTranslatedArray(merged, {
		firstName: 'Vorname',
		lastName: 'Nachname',
		email: 'Email',
	});

	return (
		<ContentWrapper>
			{trainees === undefined || !tableData ? null : (
				<>
					<SectionWithoutBackground flexDirection="column">
						<SectionHeader
							alignItems="flex-start"
							title="Nutzer*innen verwalten"
							subTitle="In diesem Bereich können Sie Ihre Lizenzen und Nutzer*innen verwalten. "
						/>
					</SectionWithoutBackground>
					<SectionWithoutBackground justifyContent="space-between">
						<CheckBox2
							label="Täglich Emails zu überfälligen Schulungen erhalten"
							defaultValue={department?.reportExceededDeadlines}
							onChange={handleChangeReportExceededDeadlines}
						/>
					</SectionWithoutBackground>
					{!trainees ? null : (
						<SectionWithoutBackground>
							<Card>
								<SimpleTable4
									renderData={tableData}
									renderActions={renderActions}
									headRowFontWeight="bold"
									searchableData={searchableData || undefined}
									sortKey="Nachname"
								/>
							</Card>
						</SectionWithoutBackground>
					)}
				</>
			)}
		</ContentWrapper>
	);
};

export default DepartmentTrainessListView;
