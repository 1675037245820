export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'DEVELOPMENT';
export const DEVELOPMENT = ENVIRONMENT === 'DEVELOPMENT';

export const API_BASE_URL = DEVELOPMENT
	? 'http://localhost:5001'
	: ENVIRONMENT === 'PRODUCTION'
	? 'https://apilive.bildungsfreaks.de'
	: ENVIRONMENT === 'STAGING'
	? 'https://apistage.bildungsfreaks.de'
	: ENVIRONMENT === 'DEMO'
	? 'https://weiterbildung.bildungsfreaks.de'
	: 'wrong';

console.log({
	ENVIRONMENT,
	DEVELOPMENT,
	API_BASE_URL,
});

export const API_BASE_URL_GQL = `${API_BASE_URL}/graphql`;
export const API_BASE_URL_SSO = `${API_BASE_URL}/sso`;
export const UPLOAD_CATEGORY_IMAGE_ENDPOINT = `${API_BASE_URL}/upload-category-image`;
export const COURSE_MATERIAL_CLOUDFRONT_URL = `${API_BASE_URL}/courses`;

// ----------------- STYLE -----------------

// deafult z-indexes of material-ui components:
// mobile stepper: 1000
// fab: 1050
// speed dial: 1050
// app bar: 1100
// drawer: 1200
// modal: 1300
// snackbar: 1400
// tooltip: 1500

export const POPPER_ZINDEX = 1103;
export const DIALOG_ZINDEX = 1102;
export const DIALOG_BACKGROUND_ZINDEX = 1101;
export const FLOATING_ACTION_BUTTON_ZINDEX = 1099;
export const CARD_BOX_SHADOW = 3;
export const CARD_BORDER_RADIUS_REM = 0.5;
export const SPACING_MOBILE_REM = 1;
export const SPACING_DESKTOP_REM = 2;
export const SECTION_WRAPPER_VERTICAL_SPACING_MOBILE_REM = 2;
export const SECTION_WRAPPER_VERTICAL_SPACING_DESKTOP_REM = 4;

// ----------------- TERMS -----------------

//Make sure the text for this version is present in TermsOfServiceView.tsx
export const LATEST_TERMS_VERSION = 2; // This must be a integer (1,2,3...)

// ----------------- OTHER -----------------

export const CONTACT_EMAIL = 'kontakt@bildungsfreaks.de';
export const PAYPAL_CLIENT_ID =
	'AeGLpUTE3Txp16jXREo3PldIM-EgzMV7p_ibQTH1Z7T-gpZ0b7xo9MPjp9YPsCUQH8DvRNaRir8ytWbO';
export const PAYPAL_CURRENCY = 'USD';
