import React from 'react';
import { useLocation } from 'react-router-dom';
import CookieBannerDialog from '../components/CookieBannerDialog';
import Dialog from '../components/Dialog';
import { useIsHideBannerRoute } from './hooks';

export const CONSENT_COOKIE_VERSION = 1;

type SetCookieInputType = {
	cookieName: 'cookiePolicyResponse';
	cookieValue: CookieConsent;
};
type SetCookieFunction = ({ cookieName, cookieValue }: SetCookieInputType) => void;

type CookieConsent = {
	version: number;
	allowedExternalServices: boolean;
};
type CookieName = 'cookiePolicyResponse';
type CookiesObject = { [key in CookieName]: string };

const CookieContext = React.createContext({});

export const useCookies = (): [CookiesObject, SetCookieFunction] =>
	React.useContext(CookieContext) as [CookiesObject, SetCookieFunction];

export const CookieProvider = ({ children }): JSX.Element => {
	const [update, setUpdate] = React.useState(0);
	const allCookies = React.useMemo(
		() =>
			document.cookie.split(';').reduce((acc, cookie) => {
				const [key, value] = cookie.split('=').map((str) => str.trim());
				if (key === undefined || value === undefined) return acc;
				if (key === '') {
					return acc;
				} else {
					return {
						...acc,
						[key]: value,
					};
				}
			}, {}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[update]
	);

	const setCookie = React.useCallback(({ cookieName, cookieValue }: SetCookieInputType) => {
		document.cookie = `${cookieName}=${JSON.stringify(
			cookieValue
		)}; path=/; secure=true; samesite=none; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
		setUpdate((before) => before + 1);
	}, []);
	const result = React.useMemo(
		() => [allCookies, setCookie] as [CookiesObject, SetCookieFunction],
		[allCookies, setCookie]
	);

	return <CookieContext.Provider value={result}>{children}</CookieContext.Provider>;
};

export const useCookieConsent = (): undefined | (CookieConsent & { valid: boolean }) => {
	const [cookies] = useCookies();
	const cookiePolicyResponse: undefined | CookieConsent = cookies['cookiePolicyResponse']
		? (JSON.parse(cookies['cookiePolicyResponse']) as CookieConsent)
		: undefined;
	return cookiePolicyResponse
		? { ...cookiePolicyResponse, valid: cookiePolicyResponse.version >= CONSENT_COOKIE_VERSION }
		: undefined;
};

export const useExternalCookiesAllowed = (): boolean => {
	const cookiePolicyResponse = useCookieConsent();
	return cookiePolicyResponse &&
		cookiePolicyResponse.valid &&
		cookiePolicyResponse.allowedExternalServices
		? true
		: false;
};

export const usePotentiallyCookieBanner = () => {
	const cookiePolicyResponse = useCookieConsent();
	const location = useLocation();
	const isHideBannerRoute = useIsHideBannerRoute();

	React.useEffect(() => {
		const show = isHideBannerRoute ? false : !cookiePolicyResponse || !cookiePolicyResponse.valid;
		if (show) {
			Dialog.render(
				{
					renderContent: CookieBannerDialog,
					isLocked: true,
				},
				'cookie-banner-dialog'
			);
		}
	}, [cookiePolicyResponse, isHideBannerRoute, location.pathname]);
};
