/* eslint-disable promise/prefer-await-to-then */
import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import { API_BASE_URL, PAYPAL_CLIENT_ID, PAYPAL_CURRENCY } from '../consts';
import { getToken } from '../utils/user';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';
import savePaypalSubscription from '../mutations/savePaypalSubscription';
import { CircularProgress } from '@mui/material';

const orderPaypalOptions = {
	clientId: PAYPAL_CLIENT_ID,
	currency: PAYPAL_CURRENCY,
	intent: 'capture',
};
const subscriptionPaypalOptions = {
	clientId: PAYPAL_CLIENT_ID,
	currency: PAYPAL_CURRENCY,
	intent: 'subscription',
	vault: true,
	components: 'buttons',
};

const PaypalPayment = ({
	onSuccess,
	onError,
	productIds,
	membershipId,
}: {
	onSuccess: (paymentType: 'order' | 'subscription') => void;
	onError?: (error) => void;
	productIds?: string[];
	membershipId?: string;
}) => {
	const [loading, setLoading] = React.useState(false);

	const [_, dispatch] = usePayPalScriptReducer();

	React.useEffect(() => {
		if (membershipId) {
			dispatch({
				type: 'resetOptions',
				value: subscriptionPaypalOptions,
			});
		} else {
			dispatch({
				type: 'resetOptions',
				value: orderPaypalOptions,
			});
		}
	}, [dispatch, membershipId]);

	const handleCreateOrder = React.useCallback(async () => {
		return axios({
			method: 'POST',
			url: `${API_BASE_URL}/paypal/create-paypal-order`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				productIds: productIds,
				jwtToken: getToken(),
			},
		}).then((response) => {
			return response.data.id;
		});
	}, [productIds]);

	const handleCreateSubscription = React.useCallback(async (_, actions) => {
		return actions.subscription
			.create({
				plan_id: 'P-7D561803M7418651BMTRSCYA', // PAYPAL_TODO: dynamize
			})
			.then((orderId) => {
				return orderId;
			});
	}, []);

	const handleApprove = React.useCallback(
		async (data, actions) => {
			if (actions.order) {
				return actions.order.capture().then((details) => {
					console.log('PaypalPayment order handleApprove: ', details);
					onSuccess('order');
					return null;
				});
			} else if (actions.subscription) {
				const { subscriptionID /* orderID, facilitatorAccessToken */ } = data;
				setLoading(true);
				const { success, error } = await savePaypalSubscription({
					membershipId: membershipId!,
					paypalSubscriptionID: subscriptionID,
				});
				setLoading(false);
				if (!success) {
					onError?.(error);
					return;
				}
				onSuccess('subscription');
			} else {
				console.log('something different happened');
			}
		},
		[membershipId, onError, onSuccess]
	);

	return loading ? (
		<CircularProgress />
	) : membershipId ? (
		<PayPalButtons onApprove={handleApprove} createSubscription={handleCreateSubscription} />
	) : productIds?.length ? (
		<PayPalButtons onApprove={handleApprove} createOrder={handleCreateOrder} />
	) : null;
};

export default PaypalPayment;
