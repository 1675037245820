import React from 'react';
import ContentWrapper from '../components/ContentWrapper';
import LearnSmartSection from '../components/LearnSmartSection';
import ImageHeader from '../components/ImageHeader';
import PerksSection from '../components/PerksSection';

import image from '../images/BusinessTeamwork5.png';

import ProBannerSmallSection from '../components/ProBannerSmallSection';

const AboutUsView = () => {
	return (
		<ContentWrapper center minHeight="10rem">
			<ImageHeader src={image} title="Über bildungsfreaks" />
			<LearnSmartSection />
			<PerksSection />
			{/* <ProBannerSmallSection /> */}
		</ContentWrapper>
	);
};

export default AboutUsView;
