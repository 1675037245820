import client from '../utils/client';
import { gql } from '@apollo/client';

type Error = 'UNAUTHENTICATED' | 'NETWORK_ERROR';

const cancelPaypalSubscription = async ({
	paymentId,
}: {
	paymentId: string;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation CancelPaypalSubscription($paymentId: ID!) {
					cancelPaypalSubscription(paymentId: $paymentId) {
						success
					}
				}
			`,
			variables: { paymentId },
		});
		if (errors) {
			return { success: false, error: errors[0]?.message as Error };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default cancelPaypalSubscription;
