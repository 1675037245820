import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from 'styled-components';

import SectionWithoutBackground from '../components/SectionWithoutBackground';
import ContentWrapper from '../components/ContentWrapper';
import { useCurrentUser } from '../utils/user';
import ProgressBar from '../components/ProgressBar';
import GridItem from '../components/GridItem';
import GridContainer from '../components/GridContainer';
import Bar from '../components/Bar';
import FlexWrap from '../components/FlexWrap';
import Txt from '../components/Txt';
import ImageHeader from '../components/ImageHeader';
import headerImage from '../images/Demo_Header.png';
import { CARD_BOX_SHADOW, CARD_BORDER_RADIUS_REM } from '../consts';
import Button from '../components/Button';
import { IconName } from '../utils/iconNameDict';
import Card from '../components/Card';
import SimpleTable4 from '../components/SimpleTable4';

const BAR_CONTAINER_HEIGHT_REM = 20;
const ROW_GAP_REM = 1;

const horizontalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };
const verticalSpacing = { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 };
const gridItem = {
	xs: 12,
	sm: 12,
	md: 6,
};

const getPercent = (n?: number, nAll?: number) => {
	if (!n || !nAll) {
		return 0;
	}
	return Number(((n / nAll) * 100).toFixed());
};

const CompanyDashboardView = () => {
	const currentUser = useCurrentUser();
	const theme = useTheme();

	const departments = currentUser?.company?.departments;

	const departmentStatistics = React.useMemo(
		() =>
			departments
				?.map((d) => ({
					departmentTitle: d.title,
					departmentId: d.id,
					trainings: d.trainees?.filter(Boolean).flatMap((t) => t.products),
				}))
				.map((department) => {
					const nAll = department.trainings?.length || 0;
					const nCompleted =
						department.trainings?.filter((product) => product.completed).length || 0;
					const nOpen =
						typeof nAll === 'number' && typeof nCompleted === 'number' ? nAll - nCompleted : 0;

					return {
						...department,

						nAll,
						nCompleted,
						nOpen,
						nDelayed:
							department.trainings?.filter(
								(product) =>
									product.finishUntil &&
									new Date(product.finishUntil) < new Date() &&
									!product.completed
							).length || 0,
					};
				}),
		[departments]
	);

	const overAllStatistics = React.useMemo(
		() =>
			departmentStatistics?.reduce<{
				nAll: number;
				nCompleted: number;
				nOpen: number;
				nDelayed: number;
			}>(
				(acc, nextDepartment) => {
					acc.nAll = acc.nAll + nextDepartment.nAll;
					acc.nCompleted = acc.nCompleted + nextDepartment.nCompleted;
					acc.nOpen = acc.nOpen + nextDepartment.nOpen;
					acc.nDelayed = acc.nDelayed + nextDepartment.nDelayed;
					return acc;
				},
				{ nAll: 0, nCompleted: 0, nOpen: 0, nDelayed: 0 }
			),
		[departmentStatistics]
	);

	const barsData = React.useMemo(
		() => [
			{
				label: 'Absolviert',
				value: getPercent(overAllStatistics?.nCompleted, overAllStatistics?.nAll),
			},
			{
				label: 'Offen',
				value: getPercent(overAllStatistics?.nOpen, overAllStatistics?.nAll),
			},
			{
				label: 'Verspätet',
				value: getPercent(overAllStatistics?.nDelayed, overAllStatistics?.nAll),
			},
		],
		[overAllStatistics]
	);

	const cardsData = React.useMemo(
		() => [
			{
				label: 'Absolviert',
				value: overAllStatistics?.nCompleted || 0,
				iconName: 'Check',
				iconBackgroundColor: theme.customColors.violet1,
			},
			{
				label: 'Verspätet',
				value: overAllStatistics?.nDelayed || 0,
				iconName: 'PriorityHigh',
				iconBackgroundColor: theme.customColors.violet2,
			},
			{
				label: 'Offen',
				value: overAllStatistics?.nOpen || 0,
				iconName: 'MoreHoriz',
				iconBackgroundColor: theme.customColors.violet3,
			},
		],
		[
			overAllStatistics,
			theme.customColors.violet1,
			theme.customColors.violet2,
			theme.customColors.violet3,
		]
	);

	const tableData = React.useMemo(() => {
		return (departmentStatistics || []).map((department) => ({
			'id': department.departmentId,
			'Abteilung': department.departmentTitle,
			'Kurse gesamt': department.nAll,
			'Absolviert': department.nCompleted,
			'Absolviert (%)': (
				<ProgressBar
					progress={department.nCompleted / department.nAll}
					color={theme.customColors.accent}
				/>
			),
			'Offen': department.nOpen,
			'Davon verspätet': department.nDelayed,
		}));
	}, [departmentStatistics, theme.customColors.accent]);

	const colors = React.useMemo(
		() => [theme.customColors.violet1, theme.customColors.violet2, theme.customColors.violet3],
		[theme.customColors.violet1, theme.customColors.violet2, theme.customColors.violet3]
	);

	return (
		<ContentWrapper>
			{departments === undefined ? null : (
				<>
					<ImageHeader
						title="Dashboard"
						subTitle="Hier behalten Sie ihre vergebenen Lizenzen im Blick."
						src={headerImage}
					/>

					<SectionWithoutBackground flexDirection="column">
						<GridContainer
							horizontalSpacing={horizontalSpacing}
							verticalSpacing={verticalSpacing}
							p="1rem 0"
						>
							<GridItem {...gridItem}>
								<FlexWrap minWidthRem={15} rowGapRem={ROW_GAP_REM}>
									{
										cardsData.map((card, idx) => (
											<Card key={idx} width="100%" minHeight={`${BAR_CONTAINER_HEIGHT_REM / 2}rem`}>
												<Box
													display="flex"
													flexDirection="row"
													justifyContent="space-between"
													alignItems="center"
												>
													<Txt variant="h6" fontWeight="bold">
														{card.label}
													</Txt>
													<Button
														iconName={card.iconName as IconName}
														iconBackgroundColor={card.iconBackgroundColor}
														iconColor={theme.palette.common.white}
														isNotClickable
													/>
												</Box>
												<Txt variant="h5" fontWeight="bold">
													{card.value}
												</Txt>
												<Txt variant="caption">Anzahl Kurse über alle Abteilungen</Txt>
											</Card>
										)) as JSX.Element[]
									}
								</FlexWrap>
							</GridItem>
							<GridItem
								{...gridItem}
								height={`${BAR_CONTAINER_HEIGHT_REM + ROW_GAP_REM}rem`}
								boxShadow={CARD_BOX_SHADOW}
								p="1rem"
								borderRadius={`${CARD_BORDER_RADIUS_REM}rem`}
							>
								<Bar data={barsData} unit="%" colors={colors} />
							</GridItem>
						</GridContainer>
					</SectionWithoutBackground>

					<SectionWithoutBackground flexDirection="column">
						{!tableData || !tableData.length ? null : (
							<Card>
								<SimpleTable4
									renderData={tableData}
									headRowFontWeight="bold"
									searchableData={tableData}
									searchKeys="Abteilung"
									sortKey="Abteilung"
								/>
							</Card>
						)}
					</SectionWithoutBackground>
				</>
			)}
		</ContentWrapper>
	);
};

export default CompanyDashboardView;
