import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import Button from './Button';
import DialogWrapper from './DialogWrapper';
import { DialogButtonRow, DialogHeader } from './Dialog';
import Txt from './Txt';
import { useCurrentUser } from '../utils/user';
import { CircularProgress } from '@mui/material';
import reinviteToVolumeLicense from '../mutations/reinviteToVolumeLicense';

const ReinviteToVolumeLicenseDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();
	const currentUser = useCurrentUser();
	const volumeLicense = currentUser?.companyDepartment?.volumeLicenses?.find((v) => v.id === id);
	const numberOfUsers = volumeLicense?.users?.length;
	const numberOfUnconfirmedUsers = volumeLicense?.users?.filter((u) => !u.emailConfirmed).length;

	const [loading, setLoading] = React.useState(false);

	const handleReinviteToVolumeLicenses = React.useCallback(async () => {
		setLoading(true);
		const { error } = await reinviteToVolumeLicense({
			id: id!,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: 'Nutzer erfolgreich erneut eingeladen' });
			dismissPortal();
		}
	}, [dismissPortal, id, snackBar]);

	return (
		<DialogWrapper p="2rem">
			{!currentUser ? (
				<CircularProgress />
			) : (
				<>
					<DialogHeader title="Nicht registrierte Nutzer erneut einladen" />
					{numberOfUsers === 0 ? (
						<Txt>Keine Registrierung ausstehend.</Txt>
					) : numberOfUnconfirmedUsers === 0 ? (
						<Txt>
							Alle eingeladenen Nutzer haben die Einladung angenommen, daher können auch keine
							Nutzer erneut eingeladen werden
						</Txt>
					) : (
						<Txt>
							{numberOfUnconfirmedUsers} eingeladene{numberOfUnconfirmedUsers === 1 ? 'r' : ''}{' '}
							Nutzer {numberOfUnconfirmedUsers === 1 ? 'hat' : 'haben'} die Einladung noch nicht
							angenommen. Wollen Sie {numberOfUnconfirmedUsers === 1 ? 'diesen' : 'diese'} Nutzer
							erneut einladen?
						</Txt>
					)}

					<DialogButtonRow>
						<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
							Abbrechen
						</Button>
						{numberOfUnconfirmedUsers === 0 ? null : (
							<Button
								variant="mainButton"
								onClick={handleReinviteToVolumeLicenses}
								loading={loading}
								disabled={numberOfUnconfirmedUsers === 0}
							>
								Erneut Einladen
							</Button>
						)}
					</DialogButtonRow>
				</>
			)}
		</DialogWrapper>
	);
};

export default ReinviteToVolumeLicenseDialog;
