/* eslint  no-useless-escape: 0 */

import React, { useEffect } from 'react';
import styled from 'styled-components';
import Txt from '../components/Txt';
import { Box, TextField } from '@mui/material';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import client from '../utils/client';
import { gql } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	padding: 15px;
`;

export const ReportAccessibilityProblemView = () => {
	const [searchParams] = useSearchParams();
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [link, setLink] = React.useState('');
	const [description, setDescription] = React.useState('');

	const previousPage = searchParams.get('page');

	useEffect(() => {
		if (previousPage) {
			setLink(previousPage);
		}
	}, [previousPage]);

	const handleSubmission = React.useCallback(() => {
		async () => {
			console.log(name, email, link, description);
			if (!link || !description) {
				Dialog.render({
					title: 'Fehler',
					description: 'Bitte fülle das Beschreibungsfeld und den Link aus.',
				});
				return;
			}
			const { errors } = await client.mutate({
				mutation: gql`
					mutation ReportAccessibilityProblem(
						$name: String
						$email: String
						$link: String!
						$description: String!
					) {
						reportAccessibilityProblem(
							name: $name
							email: $email
							link: $link
							description: $description
						) {
							success
						}
					}
				`,
				variables: { name, email, link, description },
			});
			if (!errors) {
				setName('');
				setEmail('');
				setLink('');
				setDescription('');
				Dialog.render({
					title: 'Vielen Dank',
					description: 'Deine Meldung wurde erfolgreich übermittelt.',
				});
			} else {
				Dialog.render({
					title: 'Fehler',
					description:
						'Deine Meldung konnte leider nicht übermittelt werden. Bitte versuche es später erneut.',
				});
			}
		};
	}, [description, email, link, name]);

	return (
		<Wrapper>
			<Txt variant="h5" fontWeight="bold" mt="2rem">
				Barriere melden
			</Txt>

			<Txt mt="2rem">
				Dir ist auf unserer Internetseite etwas aufgefallen, das nicht barrierefrei zugänglich oder
				vollständig nutzbar ist? Dann informiere uns bitte über das unten stehende Formular.
			</Txt>
			<Txt mt="2rem" mb="2rem">
				Beschreibe bitte im Beschreibungsfeld kurz, welches Problem oder welche Mängel Sie in Bezug
				auf die Barrierefreiheit entdeckt haben. Zusätzlich zur Beschreibung wird die Seite, auf der
				Du den Fehler gefunden haben, beziehungsweise die Du zuletzt geöffnet hattest, von dem
				Formular automatisch mitgesendet. Bitte lassen Sie diesen Link zusätzlich stehen bzw.
				ergänze ihn.
			</Txt>

			<TextField
				fullWidth
				variant="filled"
				label="Dein Name"
				value={name}
				onChange={(event) => {
					setName(event.target.value);
				}}
			/>
			<Box mt="1rem">
				<TextField
					fullWidth
					variant="filled"
					label="Deine E-Mail Adresse"
					value={email}
					onChange={(event) => {
						setEmail(event.target.value);
					}}
				/>
			</Box>
			<Box mt="1rem">
				<TextField
					fullWidth
					variant="filled"
					label="Link zur fehlerhaften Seite *"
					multiline={true}
					rows={3}
					value={link}
					onChange={(event) => {
						setLink(event.target.value);
					}}
				/>
			</Box>
			<Box mt="1rem">
				<TextField
					fullWidth
					variant="filled"
					label="Bitte beschreibe die Barriere oder das Problem *"
					multiline={true}
					rows={5}
					value={description}
					onChange={(event) => {
						setDescription(event.target.value);
					}}
				/>
			</Box>
			<Txt mt="2rem">
				Mit dem Absenden bestätigen Sie, dass Sie über 16 Jahre alt sind und in die Verarbeitung
				Ihrer personenbezogenen Daten einwilligen. Weitere Informationen dazu finden Sie in unserer{' '}
				<a href="/privacy">Datenschutzerklärung</a>.
			</Txt>
			<Box mt="1rem">
				<Button variant="mainButton" onClick={handleSubmission}>
					Absenden
				</Button>
			</Box>
		</Wrapper>
	);
};
