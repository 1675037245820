/*eslint-disable react-perf/jsx-no-jsx-as-prop */
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { ENVIRONMENT } from './consts';

import MembershipListView from './screens/MembershipListView';
import LoginView from './screens/LoginViewDemo';
import AccessGeneratorRegisterView from './screens/AccessGeneratorRegisterView';
import RegisterView from './screens/RegisterView';
import GetProView from './screens/GetProView';
import OrderDetailView from './screens/OrderDetailView';
import ContentListView from './screens/ContentListView';
import CategoryView from './screens/CategoryView';
import NotFoundView from './screens/NotFoundView';
import AboutUsView from './screens/AboutUsView';
import FavouritesView from './screens/FavouritesView';
import RequestPasswordResetView from './screens/RequestPasswordResetView';
import DashboardView from './screens/DashboardView';
import ContentPlayerView from './screens/ContentPlayerView';
import AdminView from './screens/AdminView';
import PhraseLoginView from './screens/PhraseLoginView';
import LicensesView from './screens/LicensesView';
import InviteLoginView from './screens/InviteLoginView';
import CompanyListView from './screens/CompanyListView';
import VolumeLicenseListView from './screens/VolumeLicenseListView';
import FaqView from './screens/FaqView';
import LoggedOutPupilView from './screens/LoggedOutPupilView';
import LoggedOutTeacherView from './screens/LoggedOutTeacherView';
import ContactView from './screens/ContactView';
import ImprintView from './screens/ImprintView';
import TermsOfServiceView from './screens/TermsOfServiceView';
import DataPrivacyView from './screens/DataPrivacyView';
import ShareView from './screens/ShareView';
import SitemapView from './screens/SitemapView';
import LoggedOutView from './screens/LoggedOutView';
import LoggedInView from './screens/LoggedInView';
import ContentDetailView from './screens/ContentDetailView';
import ProfileView from './screens/ProfileView';
import VolumeLicenseDetailView from './screens/VolumeLicenseDetailView';
import CheckoutView from './screens/CheckoutView';
import AdminContentListView from './screens/AdminContentListView';
import PaymentTermsView from './screens/PaymentTerms';
import CompanyDepartmentsListView from './screens/CompanyDepartmentsListView';
import CompanyVolumeLicensesListView from './screens/CompanyVolumeLicensesListView';
import DepartmentVolumeLicensesListView from './screens/DepartmentVolumeLicensesListView';
import DepartmentTraineesListView from './screens/DepartmentTraineesListView';
import HowItWorksView from './screens/HowItWorksView';

import AdminCategoryDetailView from './screens/CategoryDetailView';
import AdminCategoryListView from './screens/CategoryListView';

import { AccessibilityView } from './screens/AccessibilityView';
import { ReportAccessibilityProblemView } from './screens/ReportAccessibilityProblemView';
import CompanyDashboardView from './screens/CompanyDashboardView';

type Accessibility = 'LOGGED_OUT' | 'LOGGED_IN';

type Route = {
	path: string;
	accessibility: Accessibility[];
	element: JSX.Element;
	isAccessPhraseUserRoute?: boolean;
	referenceInSitemap?: boolean;
};

export const oldAppReroutes: Route[] = [
	{
		path: '/collections/interaktive-lerneinheiten/sophie-scholl',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/10" replace={true} />,
	},
	{
		path: '/collections/sophie-scholl',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/10" replace={true} />,
	},
	{
		path: '/collections/diversity',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/17" replace={true} />,
	},
	{
		path: '/collections/svipe',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/15" replace={true} />,
	},
	{
		path: '/collections/berufsorientierung',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/16" replace={true} />,
	},
	{
		path: '/collections/politische-medienkompetenz',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/13" replace={true} />,
	},
	{
		path: '/collections/wissensbissen',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/11" replace={true} />,
	},
	{
		path: '/products/datenschutz-sensibilisierung-schulen-bundeslaenderspezifisch',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/19" replace={true} />,
	},
	{
		path: '/products/visiontour-1-multimediale-berufsorientierung',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/category/12" replace={true} />,
	},
	{
		path: '/products/medienentwicklungsplan-vorlage-schulentwicklung ',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/" replace={true} />,
	},
	{
		path: '/products/the-life-of-sophie-scholl',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/content/103" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-das-leben-der-sophie-scholl',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/content/1" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-fakt-vs-fiktion-wie-filme-geschichte-transportieren',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/content/2" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-filmanalyse-die-beziehung-zwischen-bild-und-ton',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/content/3" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-die-figuren-um-sophie-scholl-analyse-der-verschiedenen-beziehungen',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/content/4" replace={true} />,
	},
	{
		path: '/products/sophie-scholl-handreichung-fur-lehrkrafte',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/content/5" replace={true} />,
	},
	{
		path: '/privacy',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <Navigate to="/data-privacy" replace={true} />,
	},
];

export const standardRoutes: Route[] = [
	{
		path: '/pricing',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <MembershipListView />,
		referenceInSitemap: true,
	},
	{
		path: '/',
		accessibility: ['LOGGED_OUT'],
		element: <LoginView />,
		referenceInSitemap: true,
	},
	// {
	// 	path: '/teacher',
	// 	accessibility: ['LOGGED_OUT'],
	// 	element: <LoggedOutTeacherView />,
	// 	referenceInSitemap: true,
	// },
	// {
	// 	path: '/pupils',
	// 	accessibility: ['LOGGED_OUT'],
	// 	element: <LoggedOutPupilView />,
	// 	referenceInSitemap: true,
	// },
	// {
	// 	path: '/parents',
	// 	accessibility: ['LOGGED_OUT'],
	// 	element: <LoggedOutTeacherView />,
	// 	referenceInSitemap: true,
	// },
	{
		path: '/',
		accessibility: ['LOGGED_IN'],
		element: <LoggedInView />,
		isAccessPhraseUserRoute: true,
	},
	{
		path: '/signup',
		accessibility: ['LOGGED_OUT'],
		element: <RegisterView />,
		referenceInSitemap: true,
	},
	// {
	// 	path: '/getpro/:productId/:userIsRegistered',
	// 	accessibility: ['LOGGED_OUT', 'LOGGED_IN'],
	// 	element: <GetProView />,
	// },
	// {
	// 	path: '/order/:id/:success',
	// 	accessibility: ['LOGGED_IN'],
	// 	element: <OrderDetailView />,
	// },
	{
		path: '/login',
		accessibility: ['LOGGED_OUT'],
		element: <LoginView />,
		referenceInSitemap: true,
	},
	// {
	// 	path: '/phrase-login',
	// 	accessibility: ['LOGGED_OUT'],
	// 	element: <PhraseLoginView />,
	// },
	// {
	// 	path: '/access-generator-register/:accessGeneratorCode',
	// 	accessibility: ['LOGGED_OUT', 'LOGGED_IN'],
	// 	element: <AccessGeneratorRegisterView />,
	// },
	// {
	// 	path: '/access-generator-register',
	// 	accessibility: ['LOGGED_OUT', 'LOGGED_IN'],
	// 	element: <AccessGeneratorRegisterView />,
	// },
	// {
	// 	path: '/contents',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <ContentListView />,
	// 	referenceInSitemap: true,
	// },
	// {
	// 	path: '/contents/:userType',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <ContentListView />,
	// },
	{
		path: '/content/:id',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <ContentDetailView />,
		isAccessPhraseUserRoute: true,
	},
	{
		path: '/category/:id',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <CategoryView />,
	},
	// {
	// 	path: '/training',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <CategoryView categoryId="6" />,
	// 	referenceInSitemap: true,
	// },
	// {
	// 	path: '/downloads',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <CategoryView categoryId="8" />,
	// 	referenceInSitemap: true,
	// },
	// {
	// 	path: '/checklists',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <CategoryView categoryId="9" />,
	// 	referenceInSitemap: true,
	// },
	// {
	// 	path: '/teaching-program',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <CategoryView categoryId="7" />,
	// 	referenceInSitemap: true,
	// },
	// {
	// 	path: '/interactive-courses',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <CategoryView categoryId="5" />,
	// 	referenceInSitemap: true,
	// },
	{
		path: '/about-us',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <AboutUsView />,
		referenceInSitemap: true,
	},
	{
		path: '/favourites',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <FavouritesView />,
	},
	{
		path: '/reset-password',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <RequestPasswordResetView />,
	},
	{
		path: '/player/:id',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <ContentPlayerView />,
		isAccessPhraseUserRoute: true,
	},
	{
		path: '/dashboard',
		accessibility: ['LOGGED_IN'],
		element: <DashboardView />,
	},
	{
		path: '/licenses',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <LicensesView />,
	},
	{
		path: '/invite-login',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <InviteLoginView />,
	},
	{
		path: '/faq',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <FaqView />,
		referenceInSitemap: true,
	},
	{
		path: '/contact',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <ContactView />,
		referenceInSitemap: true,
	},
	{
		path: '/imprint',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <ImprintView />,
	},
	{
		path: '/terms/:version?',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <TermsOfServiceView />,
	},
	// {
	// 	path: '/payment-terms',
	// 	accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
	// 	element: <PaymentTermsView />,
	// },
	{
		path: '/payment-terms',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <PaymentTermsView />,
	},
	{
		path: '/accessibility',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <AccessibilityView />,
	},
	{
		path: '/accessibility-problem',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <ReportAccessibilityProblemView />,
	},
	{
		path: '/data-privacy',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <DataPrivacyView />,
	},
	// {
	// 	path: '/share',
	// 	accessibility: ['LOGGED_IN'],
	// 	element: <ShareView />,
	// },
	{
		path: '/profile/account',
		accessibility: ['LOGGED_IN'],
		element: <ProfileView />,
	},
	// {
	// 	path: '/profile/membership',
	// 	accessibility: ['LOGGED_IN'],
	// 	element: <ProfileView />,
	// },
	{
		path: '/profile/settings',
		accessibility: ['LOGGED_IN'],
		element: <ProfileView />,
	},
	...(ENVIRONMENT === 'DEMO' || ENVIRONMENT === 'DEVELOPMENT'
		? [
				{
					path: '/admin/how-it-works',
					accessibility: ['LOGGED_IN', 'LOGGED_OUT'] as Accessibility[],
					element: <HowItWorksView />,
				},
		  ]
		: []),
	...(ENVIRONMENT === 'PRODUCTION'
		? []
		: [
				{
					path: '/checkout',
					accessibility: ['LOGGED_IN'] as Accessibility[],
					element: <CheckoutView />,
				},
		  ]),
	{
		path: '*',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <NotFoundView />,
		isAccessPhraseUserRoute: true,
	},
	...oldAppReroutes,
];

export const adminRoutes: Route[] = [
	{
		path: '/',
		accessibility: ['LOGGED_OUT'],
		element: <LoginView />,
	},
	{
		path: '/',
		accessibility: ['LOGGED_IN'],
		element: <AdminView />,
	},
	{
		path: '/login',
		accessibility: ['LOGGED_OUT'],
		element: <LoginView />,
	},
	{
		path: '/contents',
		accessibility: ['LOGGED_IN'],
		element: <AdminContentListView />,
	},
	{
		path: '/companies',
		accessibility: ['LOGGED_IN'],
		element: <CompanyListView />,
	},
	{
		path: '/volume-licenses',
		accessibility: ['LOGGED_IN'],
		element: <VolumeLicenseListView />,
	},
	{
		path: '/volume-license/:id',
		accessibility: ['LOGGED_IN'],
		element: <VolumeLicenseDetailView />,
	},
	// {
	// 	path: '/category/:id',
	// 	accessibility: ['LOGGED_IN'],
	// 	element: <AdminCategoryDetailView />,
	// },
	// {
	// 	path: '/categories',
	// 	accessibility: ['LOGGED_IN'],
	// 	element: <AdminCategoryListView />,
	// },
	// {
	// 	path: '/gen-sitemap',
	// 	accessibility: ['LOGGED_IN'],
	// 	element: <SitemapView />,
	// },
	{
		path: '/',
		accessibility: ['LOGGED_IN', 'LOGGED_OUT'],
		element: <NotFoundView />,
	},
];

const companyAdminRoutes: Route[] = [
	{
		path: '/admin',
		accessibility: ['LOGGED_IN'],
		element: <CompanyDepartmentsListView />,
	},
	{
		path: '/admin/company-licenses',
		accessibility: ['LOGGED_IN'],
		element: <CompanyVolumeLicensesListView />,
	},
	{
		path: '/admin/dashboard',
		accessibility: ['LOGGED_IN'],
		element: <CompanyDashboardView />,
	},
];

const companyDepartmentAdminRoutes: Route[] = [
	{
		path: '/admin',
		accessibility: ['LOGGED_IN'],
		element: <DepartmentVolumeLicensesListView />,
	},
	{
		path: '/admin/company-department-volume-license-users',
		accessibility: ['LOGGED_IN'],
		element: <DepartmentTraineesListView />,
	},
];

type UserType =
	| 'LOGGED_OUT'
	| 'ADMIN'
	| 'COMPANY_ADMIN'
	| 'COMPANY_DEPARTMENT_ADMIN'
	| 'STANDARD'
	| 'ACCESS_PHRASE_USER';

const getRoutes = (type?: UserType) => {
	return type === 'LOGGED_OUT' || type === 'STANDARD' || type === 'ACCESS_PHRASE_USER'
		? standardRoutes
		: type === 'ADMIN'
		? adminRoutes
		: type === 'COMPANY_ADMIN'
		? [...standardRoutes, ...companyAdminRoutes]
		: [...standardRoutes, ...companyDepartmentAdminRoutes];
};

export default getRoutes;
