import React from 'react';
import { Box } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { BoxProps } from '@mui/material/Box';
import {
	useWindowDimensions,
	usePxPerRemFactor,
	useSpacingRem,
	useSectionTopSpacingRem,
} from '../utils/hooks';

const Wrapper = styled(Box)`
	width: 100%;
	display: flex;
`;

export const useHorizontalPaddingRem = (): number => {
	const spacingRem = useSpacingRem();
	const theme = useTheme();
	const pxPerRemFactor = usePxPerRemFactor();
	const { width: windowWidth } = useWindowDimensions();
	const minimalWidth = theme.contentMaxWidthPx + 2 * spacingRem * pxPerRemFactor;
	return windowWidth < minimalWidth ? spacingRem : 0;
};

const SectionWithoutBackground = React.forwardRef<HTMLDivElement, BoxProps>(
	({ children, ...rest }, ref) => {
		const horizontalPaddingRem = useHorizontalPaddingRem();
		const sectionTopSpacingRem = useSectionTopSpacingRem();
		return (
			<Wrapper
				ref={ref}
				m={`${sectionTopSpacingRem}rem 0 0 0`}
				p={`0 ${horizontalPaddingRem}rem`}
				{...rest}
			>
				{children}
			</Wrapper>
		);
	}
);

export default React.memo(SectionWithoutBackground);
