import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import CircularProgress from '@mui/material/CircularProgress';
import Button from './Button';
import createVolumeLicense from '../mutations/createVolumeLicense';
import updateVolumeLicense from '../mutations/updateVolumeLicense';
import DialogWrapper from './DialogWrapper';
import { useVolumeLicense } from '../utils/volumeLicense';
import { useAllProducts } from '../utils/product';
import Dialog from './Dialog';
import { IntegerSelectFieldRef } from './IntegerSelectField';
import BooleanSelectField, { BooleanSelectFieldRef } from './BooleanSelectField';
import IntegerTextField from './IntegerTextField';
import { DialogButtonRow, DialogHeader } from './Dialog';
import { useInitializeStateWithDefault } from '../utils/hooks';

import SearchSelectField2, { SearchSelectFieldRef2 } from './SearchSelectField2';
import { useCurrentUser } from '../utils/user';
import { useCategoriesOfGroup } from '../utils/category';

const UpsertCompanyVolumeLicenseDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();
	const currentUser = useCurrentUser();
	const companyDepartments = currentUser?.company?.departments;
	const volumeLicense = useVolumeLicense(id);
	const allProducts = useAllProducts();

	const companyCategoryId = useCategoriesOfGroup('AUDIENCE')?.find(
		(cat) => cat.type === 'COMPANY'
	)?.id;

	const defaultProductIds = React.useMemo(
		() => volumeLicense?.products.map((p) => p.id),
		[volumeLicense?.products]
	);

	const [loading, setLoading] = React.useState(false);

	const [productIds, setProductIds] = React.useState<string[]>([]);
	const handleChangeProducts = React.useCallback((items: { label: string; value?: string }[]) => {
		const ids = items.map(({ value }) => value) as string[];
		setProductIds(ids);
	}, []);

	const relevantProducts = React.useMemo(
		() =>
			allProducts?.filter(({ audienceCategories }) =>
				audienceCategories.some((ac) => ac.id === companyCategoryId)
			),
		[allProducts, companyCategoryId]
	);

	const [companyDepartmentId, setCompanyDepartmentId] = React.useState<string | undefined>(
		undefined
	);
	const handleChangeCompanyDepartment = React.useCallback(
		(items: { label: string; value?: string }[]) => {
			const newCompanyDepartmentId = items[0]?.value;
			setCompanyDepartmentId(newCompanyDepartmentId);
		},
		[]
	);

	const [numberOfLicenses, setNumberOfLicenses, initializedNumberOfLicenses] =
		useInitializeStateWithDefault({
			defaultValue: !id ? 1 : volumeLicense?.numberOfLicenses,
			finishedInitializing: !id ? true : volumeLicense !== undefined,
		});

	const handleChangeNumberOfLicenses = React.useCallback(
		(value) => {
			setNumberOfLicenses(value);
		},
		[setNumberOfLicenses]
	);

	const [finishInDays, setFinishInDays, inittedFinishInDays] = useInitializeStateWithDefault({
		defaultValue: id ? volumeLicense?.finishInDays : 1,
		finishedInitializing: id ? volumeLicense !== undefined : true,
	});

	const handleChangeFinishInDays = React.useCallback(
		(value) => {
			setFinishInDays(value);
		},
		[setFinishInDays]
	);

	const [active, setActive] = useInitializeStateWithDefault({
		defaultValue: !id ? true : volumeLicense?.active,
		finishedInitializing: !id ? true : volumeLicense !== undefined,
	});

	const handleChangeIsActive = React.useCallback(
		(value) => {
			setActive(value);
		},
		[setActive]
	);

	const [required, setRequired] = useInitializeStateWithDefault({
		defaultValue: id ? Boolean(volumeLicense?.finishInDays) : false,
		finishedInitializing: id ? volumeLicense !== undefined : true,
	});
	const companyDeparmentsRef = React.useRef<SearchSelectFieldRef2>(null);
	const productRef = React.useRef<SearchSelectFieldRef2>(null);
	const nLicensesRef = React.useRef<IntegerSelectFieldRef>(null);
	const activeRef = React.useRef<BooleanSelectFieldRef>(null);
	const finishInDaysRef = React.useRef<IntegerSelectFieldRef>(null);
	const requiredRef = React.useRef<BooleanSelectFieldRef>(null);

	const handleChangeRequired = React.useCallback(
		(value) => {
			setRequired(value);
		},
		[setRequired]
	);

	const handleValidate = React.useCallback(() => {
		const invalid = [companyDeparmentsRef, productRef, nLicensesRef, activeRef, finishInDaysRef]
			.map((r) => r.current?.validate())
			.some(Boolean);
		return invalid;
	}, []);

	const handleCreateVolumeLicense = React.useCallback(async () => {
		const invalid = handleValidate!();
		if (invalid) return;
		setLoading(true);
		const { success, error } = await createVolumeLicense({
			companyId: currentUser!.company!.id!,
			companyDepartmentId: companyDepartmentId!,
			audienceCategoryId: companyCategoryId!,
			isLimited: false,
			numberOfLicenses: numberOfLicenses!,
			productIds: productIds!,
			finishInDays: required ? finishInDays : undefined,
		});
		setLoading(false);
		if (success) {
			snackBar({ success: 'Volumenlizenz erfolgreich erstellt' });
			dismissPortal();
		} else {
			snackBar({ error });
		}
	}, [
		handleValidate,
		currentUser,
		companyDepartmentId,
		companyCategoryId,
		numberOfLicenses,
		productIds,
		required,
		finishInDays,
		snackBar,
		dismissPortal,
	]);

	const handleUpdateVolumeLicense = React.useCallback(async () => {
		setLoading(true);
		const { error } = await updateVolumeLicense({
			id: id!,
			numberOfLicenses: numberOfLicenses,
			active: active,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: 'Volumenlizenz erfolgreich aktualisiert' });
			dismissPortal();
		}
	}, [active, dismissPortal, id, numberOfLicenses, snackBar]);

	const maybeConfirmUpdateVolumeLicense = React.useCallback(() => {
		if (id && volumeLicense?.active && !active) {
			Dialog.render({
				title: 'Volumenlizenz deaktivieren',
				description:
					'Die Deaktivierung der Volumenlizenz führt dazu, dass die verknüpften Nutzer die verknüpften Produkte nicht mehr nutzen können. Bist du sicher, dass du die Volumenlizenz deaktivieren möchtest?',
				buttons: [
					{
						id: 'confirm',
						label: 'Deaktivieren',
						onClick: handleUpdateVolumeLicense,
					},
					{
						id: 'cancel',
						label: 'Abbrechen',
						variant: 'mainButton',
					},
				],
			});
		} else {
			handleUpdateVolumeLicense();
		}
	}, [active, handleUpdateVolumeLicense, id, volumeLicense?.active]);

	const initializing =
		volumeLicense === undefined ||
		!initializedNumberOfLicenses ||
		!relevantProducts ||
		!companyDepartments ||
		!inittedFinishInDays;

	return (
		<DialogWrapper p="2rem">
			{initializing ? (
				<CircularProgress />
			) : (
				<>
					<DialogHeader title={id ? 'Lizenz editieren' : 'Neue Lizenz hinzufügen'} />
					{!id ? (
						<SearchSelectField2
							label="Abteilung"
							options={companyDepartments || undefined}
							onChange={handleChangeCompanyDepartment}
							m="2rem 0 0 0"
							width="100%"
							ref={companyDeparmentsRef}
							defaultValues={volumeLicense?.companyDepartment?.id}
							labelKey="title"
							valueKey="id"
						/>
					) : null}
					{!id && companyCategoryId && relevantProducts?.length ? (
						<SearchSelectField2
							label="Produkte"
							options={relevantProducts}
							onChange={handleChangeProducts}
							m="1rem 0 0 0"
							width="100%"
							ref={productRef}
							multiple
							defaultValues={defaultProductIds}
							labelKey="title"
							valueKey="id"
						/>
					) : null}
					{!id ? (
						<BooleanSelectField
							onChange={handleChangeRequired}
							defaultValue={required}
							width="100%"
							m="1rem 0 0 0"
							label="Verpflichtend"
							ref={requiredRef}
						/>
					) : null}
					{!id && required ? (
						<IntegerTextField
							onChange={handleChangeFinishInDays}
							defaultValue={finishInDays}
							width="100%"
							m="1rem 0 0 0"
							label="Beenden in Tagen"
							ref={finishInDaysRef}
							min={1}
						/>
					) : null}
					<IntegerTextField
						onChange={handleChangeNumberOfLicenses}
						defaultValue={numberOfLicenses}
						width="100%"
						m="1rem 0 0 0"
						label="Anzahl Lizenzen"
						ref={nLicensesRef}
						min={1}
					/>
					{id ? (
						<BooleanSelectField
							onChange={handleChangeIsActive}
							defaultValue={active}
							width="100%"
							m="1rem 0 0 0"
							label="Aktiv"
							ref={activeRef}
						/>
					) : null}
					<DialogButtonRow>
						<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
							Abbrechen
						</Button>

						<Button
							variant="mainButton"
							onClick={!id ? handleCreateVolumeLicense : maybeConfirmUpdateVolumeLicense}
							loading={loading}
						>
							{id ? 'Speichern' : 'Hinzufügen'}
						</Button>
					</DialogButtonRow>
				</>
			)}
		</DialogWrapper>
	);
};

export default UpsertCompanyVolumeLicenseDialog;
