import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';

import { CARD_BOX_SHADOW, CARD_BORDER_RADIUS_REM } from '../consts';

const Card = styled(Box).attrs({ boxShadow: CARD_BOX_SHADOW })`
	width: 100%;
	padding: 1rem;
	border-radius: ${CARD_BORDER_RADIUS_REM}rem;
`;

export default Card;
