import React from 'react';

import { useSnackbar } from '../utils/snackBar';
import Button from './Button';

import DialogWrapper from './DialogWrapper';
import { DialogButtonRow, DialogHeader } from './Dialog';
import TextField, { TextFieldRef } from './TextField';
import upsertCompanyDepartment from '../mutations/upsertCompanyDepartment';
import { useInitializeStateWithDefault } from '../utils/hooks';
import { useCompanyDepartment } from '../utils/companyDepartment';
import { CircularProgress } from '@mui/material';
import BooleanSelectField, { BooleanSelectFieldRef } from './BooleanSelectField';

const UpsertCompanyDepartmentDialog = ({
	dismissPortal,
	id,
}: {
	dismissPortal: () => void;
	id?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();
	const companyDepartment = useCompanyDepartment(id);

	const [loading, setLoading] = React.useState(false);

	const [title, setTitle, inittedTitle] = useInitializeStateWithDefault({
		defaultValue: id ? companyDepartment?.title : undefined,
		finishedInitializing: id ? companyDepartment !== undefined : true,
	});
	const handleChangeTitle = React.useCallback(
		(value) => {
			setTitle(value);
		},
		[setTitle]
	);

	const [firstName, setFirstName, inittedFirstName] = useInitializeStateWithDefault({
		defaultValue: id ? companyDepartment?.admins[0]?.firstName : undefined,
		finishedInitializing: id ? companyDepartment !== undefined : true,
	});
	const handleChangeFirstName = React.useCallback(
		(value) => {
			setFirstName(value);
		},
		[setFirstName]
	);

	const [lastName, setLastName, inittedLastName] = useInitializeStateWithDefault({
		defaultValue: id ? companyDepartment?.admins[0]?.lastName : undefined,
		finishedInitializing: id ? companyDepartment !== undefined : true,
	});
	const handleChangeLastName = React.useCallback(
		(value) => {
			setLastName(value);
		},
		[setLastName]
	);

	const titleFieldRef = React.useRef<TextFieldRef>(null);
	const firstNameFieldRef = React.useRef<TextFieldRef>(null);
	const lastNameFieldRef = React.useRef<TextFieldRef>(null);
	const emailFieldRef = React.useRef<TextFieldRef>(null);
	const activeFieldRef = React.useRef<BooleanSelectFieldRef>(null);

	const [email, setEmail, inittedEmail] = useInitializeStateWithDefault({
		defaultValue: id ? companyDepartment?.admins[0]?.email : undefined,
		finishedInitializing: id ? companyDepartment !== undefined : true,
	});
	const handleChangeEmail = React.useCallback(
		(value) => {
			if (id) {
				firstNameFieldRef.current?.reset();
				lastNameFieldRef.current?.reset();
			}
			setEmail(value);
		},
		[id, setEmail]
	);

	const [active, setActive] = useInitializeStateWithDefault({
		defaultValue: id ? companyDepartment?.active : true,
		finishedInitializing: id ? companyDepartment !== undefined : true,
	});

	const handleChangeIsActive = React.useCallback(
		(value) => {
			setActive(value);
		},
		[setActive]
	);

	const handleValidate = React.useCallback(() => {
		let invalid;
		if (companyDepartment?.active === true && active === false) {
			invalid = titleFieldRef.current?.validate();
		} else {
			invalid = [firstNameFieldRef, lastNameFieldRef, emailFieldRef]
				.map((r) => r.current?.validate())
				.some(Boolean);
		}
		return invalid;
	}, [active, companyDepartment?.active]);

	const handleUpserCompanyDepartment = React.useCallback(async () => {
		const invalid = handleValidate!();
		if (invalid) {
			return;
		}
		setLoading(true);
		const { error } = await upsertCompanyDepartment({
			id,
			firstName,
			lastName,
			title,
			email,
			active,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: `Abteilung erfolgreich ${id ? 'aktualisiert' : 'erstellt'}.` });
			dismissPortal();
		}
	}, [active, dismissPortal, email, firstName, handleValidate, id, lastName, snackBar, title]);

	const initialized = inittedFirstName && inittedTitle && inittedEmail && inittedLastName;
	const changed =
		title !== companyDepartment?.title ||
		email !== companyDepartment?.admins[0]?.email ||
		firstName !== companyDepartment?.admins[0]?.firstName ||
		lastName !== companyDepartment?.admins[0]?.lastName ||
		active !== companyDepartment?.active;

	return (
		<DialogWrapper p="2rem">
			{!initialized ? (
				<CircularProgress />
			) : (
				<>
					<DialogHeader title={id ? 'Abteilung editieren' : 'Abteilung erstellen'} />
					<TextField
						label="Name der Abteilung"
						onChange={handleChangeTitle}
						m="2rem 0 0 0"
						width="100%"
						ref={titleFieldRef}
						defaultValue={title}
						disabled={loading}
					/>
					<>
						<TextField
							label="Email-Adresse des Verantwortlichen"
							onChange={handleChangeEmail}
							m="2rem 0 0 0"
							width="100%"
							ref={emailFieldRef}
							defaultValue={email}
							disabled={loading}
						/>
						<TextField
							label="Vorname des Verantwortlichen"
							onChange={handleChangeFirstName}
							m="2rem 0 0 0"
							width="100%"
							ref={firstNameFieldRef}
							defaultValue={firstName}
							disabled={loading}
						/>
						<TextField
							label="Nachname des Verantwortlichen"
							onChange={handleChangeLastName}
							m="2rem 0 0 0"
							width="100%"
							ref={lastNameFieldRef}
							defaultValue={lastName}
							disabled={loading}
						/>
						{id ? (
							<BooleanSelectField
								onChange={handleChangeIsActive}
								defaultValue={active}
								width="100%"
								m="2rem 0 0 0"
								label="Aktiv"
								ref={activeFieldRef}
								disabled={loading}
							/>
						) : null}
					</>
					<DialogButtonRow>
						<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
							Abbrechen
						</Button>
						<Button
							variant="mainButton"
							onClick={handleUpserCompanyDepartment}
							disabled={!changed}
							loading={loading}
						>
							{id ? 'Speichern' : 'Hinzufügen'}
						</Button>
					</DialogButtonRow>
				</>
			)}
		</DialogWrapper>
	);
};

export default UpsertCompanyDepartmentDialog;
