import client from '../utils/client';
import { gql } from '@apollo/client';
import { VolumeLicenseInfoFragment } from '../utils/fragments';

type Error =
	| 'INVALID_ENTRIES'
	| 'NETWORK_ERROR'
	| 'UNAUTHORIZED'
	| 'VOLUME_LICENSE_NOT_FOUND'
	| 'NO_ACCESS_TO_RESOURCE'
	| 'USER_NOT_FOUND';

const removeFromVolumeLicenses = async ({
	volumeLicenseIds,
	userId,
}: {
	volumeLicenseIds: string[];
	userId: string;
}): Promise<
	| { success: true; error: undefined; errorMessage: undefined }
	| { success: false; error: Error; errorMessage?: string }
> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation RemoveFromVolumeLicenses($volumeLicenseIds: [ID!]!, $userId: ID!) {
					removeFromVolumeLicenses(volumeLicenseIds: $volumeLicenseIds, userId: $userId) {
						...VolumeLicenseInfoFragment
					}
				}
				${VolumeLicenseInfoFragment}
			`,
			variables: { volumeLicenseIds, userId },
			errorPolicy: 'all',
			refetchQueries: ['CurrentUser'],
		});
		if (errors) {
			const errorCode = errors[0]?.extensions?.code as Error;
			const errorMessage = errors[0]?.message;
			return {
				success: false,
				error: errorCode,
				errorMessage,
			};
		}
		return {
			success: true,
			error: undefined,
			errorMessage: undefined,
		};
	} catch (e) {
		if (e && e.networkError)
			return { success: false, error: 'NETWORK_ERROR', errorMessage: undefined };
		throw e;
	}
};

export default removeFromVolumeLicenses;
