import React from 'react';
import { AppBar as MuiAppBar, Box } from '@mui/material';
import { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';

import { useDivDimensions, useIsMobile, useNavigate } from '../utils/hooks';
import { appBarHeightVar } from '../utils/localState';
import { useCurrentUser, logout, useUserType } from '../utils/user';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { IconName } from '../utils/iconNameDict';
import GenericTopAppBar from './GenericTopAppBar';
import GenericBottomAppBar from './GenericBottomAppBar';
import { useCategoriesOfGroup } from '../utils/category';

type NavButton = {
	label: string;
	navigateTo?: string;
	variant?: MuiButtonProps['variant'];
	iconName?: IconName;
	onClick?: (argument: any) => void;
	children?: NavButton[];
};

type Routes =
	| '/admin'
	| '/profile'
	| '/favourites'
	| '/signup'
	| '/login'
	| '/faq'
	| '/accessibility'
	| '/accessibility-problem?page='
	| '/contents'
	| '/pricing'
	| '/about-us'
	| '/profile/account';

type AppBarConfig = {
	top: {
		nav: NavButton[];
		act: NavButton[];
	};
	bottom: NavButton[];
};

type UserType =
	| 'LOGGED_OUT'
	| 'STANDARD'
	| 'ACCESS_PHRASE_USER'
	| 'COMPANY_ADMIN'
	| 'COMPANY_DEPARTMENT_ADMIN'
	| 'ADMIN';

type AppBarConfigList = {
	[key in UserType]: {
		top: {
			desktop: {
				nav: NavButton[];
				act: NavButton[];
			};
			mobile: {
				nav: NavButton[];
				act: NavButton[];
			};
		};
		bottom?: NavButton[];
	};
};

const buttons: {
	[route in Routes]: {
		all?: NavButton;
		mobile?: NavButton;
		desktop?: NavButton;
	};
} = {
	'/admin': {
		all: {
			label: 'Admin',
			iconName: 'SupervisorAccount' as IconName,
			navigateTo: '/admin',
			variant: 'text' as MuiButtonProps['variant'],
		},
	},

	'/profile': {
		all: {
			label: 'Mein Profil',
			iconName: 'AccountCircle' as IconName,
			navigateTo: '/profile/account',
			variant: 'text' as MuiButtonProps['variant'],
		},
	},
	'/favourites': {
		all: { label: 'Favoriten', navigateTo: '/favourites', iconName: 'FavoriteBorder' as IconName },
	},
	'/signup': {
		all: {
			label: 'Registrieren',
			navigateTo: '/signup',
			variant: 'outlined' as MuiButtonProps['variant'],
		},
	},
	'/login': {
		all: {
			label: 'Anmelden',
			navigateTo: '/login',
			variant: 'outlined' as MuiButtonProps['variant'],
		},
	},
	'/faq': {
		mobile: {
			label: 'Hilfe und FAQ',
			navigateTo: '/faq',
			variant: 'text' as MuiButtonProps['variant'],
			iconName: 'InfoOutlined' as IconName,
		},
	},
	'/accessibility': {
		mobile: {
			label: 'Barrierefreiheitserklärung',
			navigateTo: '/accessibility',
			variant: 'text' as MuiButtonProps['variant'],
			iconName: 'InfoOutlined' as IconName,
		},
	},
	'/accessibility-problem?page=': {
		mobile: {
			label: 'Barriere melden',
			navigateTo: '/accessibility-problem?page=' /* + path */,
			variant: 'text' as MuiButtonProps['variant'],
			iconName: 'InfoOutlined' as IconName,
		},
	},
	'/contents': {
		mobile: {
			label: 'Alle Inhalte',
			navigateTo: '/contents',
			variant: 'text' as MuiButtonProps['variant'],
			iconName: 'LocalLibraryOutlined' as IconName,
		},
		desktop: {
			label: 'Alle Inhalte',
			navigateTo: '/contents',
			variant: 'text' as MuiButtonProps['variant'],
		},
	},
	'/pricing': {
		mobile: {
			label: 'bf PRO',
			navigateTo: '/pricing',
			variant: 'text' as MuiButtonProps['variant'],
			iconName: 'Bildungsfreaks' as IconName,
		},
		desktop: {
			label: 'bf PRO',
			navigateTo: '/pricing',
			variant: 'text' as MuiButtonProps['variant'],
		},
	},
	'/about-us': {
		desktop: {
			label: 'Über uns',
			navigateTo: '/about-us',
			variant: 'text' as MuiButtonProps['variant'],
		},
	},
	'/profile/account': {
		all: {
			label: 'Mein Profil',
			navigateTo: '/profile/account',
			variant: 'text' as MuiButtonProps['variant'],
			iconName: 'AccountCircleOutlined' as IconName,
			children: [
				{
					label: 'Mein Konto',
					navigateTo: '/profile/account',
					variant: 'text' as MuiButtonProps['variant'],
				},
				{
					label: 'Mitgliedschaft',
					navigateTo: '/profile/membership',
					variant: 'text' as MuiButtonProps['variant'],
				},
				{
					label: 'Einstellungen',
					navigateTo: '/profile/settings',
					variant: 'text' as MuiButtonProps['variant'],
				},
			],
		},
	},
};

const useAppBarConfig = (): AppBarConfig => {
	const theme = useTheme();
	const navigate = useNavigate();
	const currentUser = useCurrentUser();
	const userType = useUserType();
	const audienceCategories = useCategoriesOfGroup('AUDIENCE');
	const filteredAudienceCategories = React.useMemo(
		() => audienceCategories?.filter((category) => category.type !== 'COMPANY'),
		[audienceCategories]
	);
	const location = useLocation();
	const pathname = location.pathname;

	const userCanShare = currentUser?.appFunctionalities?.some((af) =>
		af.type.includes('SHARE_CONTENTS')
	);

	const isMobile = useIsMobile();

	const handleLogout = React.useCallback(() => {
		logout(navigate);
	}, [navigate]);

	const logoutButton = React.useMemo(
		() => ({
			label: 'Abmelden',
			variant: 'outlined' as MuiButtonProps['variant'],
			onClick: handleLogout,
		}),
		[handleLogout]
	);

	const getButton = React.useCallback(
		(route) => {
			return route === '/logout'
				? logoutButton
				: isMobile
				? buttons[route].mobile || buttons[route].all
				: buttons[route].desktop || buttons[route].all;
		},
		[isMobile, logoutButton]
	);

	const showPricing =
		!currentUser ||
		(currentUser &&
			(currentUser.audienceType === 'TEACHER' || currentUser.audienceType === 'PARENTS'));

	const standardBottomAppBarButtons = React.useMemo(
		() => [
			{ label: 'Übersicht', navigateTo: '/' },
			{ label: 'Mein Dashboard', navigateTo: '/dashboard' },
			//...(userCanShare ? [{ label: 'Teilen', navigateTo: '/share' }] : []),
		],
		[userCanShare]
	);

	const isAdminRoute = pathname.includes('/admin');

	const config: AppBarConfigList = {
		LOGGED_OUT: {
			top: {
				desktop: {
					nav: [],
					act: [],
				},
				mobile: {
					nav: [],
					act: [],
				},
			},
			bottom: undefined,
		},
		STANDARD: {
			top: {
				desktop: {
					nav: [getButton('/about-us'), getButton('/favourites')],
					act: [getButton('/profile'), getButton('/logout')],
				},
				mobile: {
					nav: [
						getButton('/profile'),
						getButton('/faq'),
						getButton('/accessibility'),
						getButton('/accessibility-problem?page='),
					],
					act: [getButton('/logout')],
				},
			},
			bottom: standardBottomAppBarButtons,
		},
		ACCESS_PHRASE_USER: {
			top: {
				desktop: {
					nav: [],
					act: [getButton('/logout')],
				},
				mobile: {
					nav: [],
					act: [getButton('/logout')],
				},
			},
			bottom: standardBottomAppBarButtons,
		},
		COMPANY_DEPARTMENT_ADMIN: {
			top: {
				desktop: {
					nav: [],
					act: [getButton('/admin'), getButton('/profile'), getButton('/logout')],
				},
				mobile: {
					nav: [
						getButton('/profile'),
						getButton('/faq'),
						getButton('/accessibility'),
						getButton('/accessibility-problem?page='),
						getButton('/admin'),
					],
					act: [getButton('/logout')],
				},
			},
			bottom: isAdminRoute
				? [
						{ label: 'Lizenzen', navigateTo: '/admin' },
						{
							label: 'Nutzerverwaltung',
							navigateTo: '/admin/company-department-volume-license-users',
						},
				  ]
				: standardBottomAppBarButtons,
		},
		COMPANY_ADMIN: {
			top: {
				desktop: {
					nav: [],
					act: [getButton('/admin'), getButton('/profile'), getButton('/logout')],
				},
				mobile: {
					nav: [
						getButton('/profile'),
						getButton('/faq'),
						getButton('/accessibility'),
						getButton('/accessibility-problem?page='),
						getButton('/admin'),
					],
					act: [getButton('/logout')],
				},
			},
			bottom: isAdminRoute
				? [
						{ label: 'Abteilungen', navigateTo: '/admin' },
						{ label: 'Lizenzen', navigateTo: '/admin/company-licenses' },
						{ label: 'Dashboard', navigateTo: '/admin/dashboard' },
				  ]
				: standardBottomAppBarButtons,
		},
		ADMIN: {
			top: {
				desktop: {
					nav: [
						{ label: 'Home', navigateTo: '/' },
						{ label: 'Unternehmen', navigateTo: '/companies' },
						{ label: 'Volumenlizenzen', navigateTo: '/volume-licenses' },
						{ label: 'Kategorien', navigateTo: '/categories' },
						{ label: 'Sitemap', navigateTo: '/gen-sitemap' },
					],
					act: [getButton('/logout')],
				},
				mobile: {
					nav: [
						{ label: 'Home', navigateTo: '/' },
						{ label: 'Unternehmen', navigateTo: '/companies' },
						{ label: 'Volumenlizenzen', navigateTo: '/volume-licenses' },
						{ label: 'Kategorien', navigateTo: '/categories' },
						{ label: 'Sitemap', navigateTo: '/gen-sitemap' },
					],
					act: [getButton('/logout')],
				},
			},
			bottom: [],
		},
	};

	const result = {
		top: {
			nav: config[userType].top[isMobile ? 'mobile' : 'desktop'].nav as NavButton[],
			act: config[userType].top[isMobile ? 'mobile' : 'desktop'].act as NavButton[],
		},
		bottom: config[userType].bottom as NavButton[],
	};
	return result;
};

const GenericAppBar2 = () => {
	const location = useLocation();
	const isPlayerRoute = location.pathname.includes('/player/');
	const appBarConfig = useAppBarConfig();
	const isMobile = useIsMobile();
	const userType = useUserType();

	const ref = React.useRef<HTMLDivElement>(null);
	const deps = React.useMemo(
		() => [location.pathname, isMobile, userType],
		[isMobile, location.pathname, userType]
	);
	const { height } = useDivDimensions(ref, 'resize', deps);
	appBarHeightVar(height);

	return isPlayerRoute ? null : (
		<Box>
			<MuiAppBar position="fixed" ref={ref}>
				<GenericTopAppBar config={appBarConfig.top} />
				{appBarConfig.bottom ? <GenericBottomAppBar buttons={appBarConfig.bottom} /> : null}
			</MuiAppBar>
			<Box width="100%" height={height} />
		</Box>
	);
};

export default React.memo(GenericAppBar2);
