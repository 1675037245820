import React from 'react';
import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import ImageBox from './ImageBox';
import GridContainer from './GridContainer';
import Txt from './Txt';
import MarkdownTxt from './MarkdownTxt';
import SectionHeader from './SectionHeader';
import { useSpacingRem } from '../utils/hooks';

const Column = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const gridItemProps = {
	item: true,
	xs: 12,
	sm: 12,
	md: 6,
};

const horizontalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const verticalSpacing = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

const GreyGrid = styled(Grid)`
	background-color: ${(p) => p.theme.customColors.decentBlue};
`;
type ObjectFit = 'fill' | 'cover' | 'contain' | 'none' | 'scale-down';
const ImageHeader = ({
	src,
	title,
	subTitle,
	isMarkdown,
	height = 20,
	objectFit = 'contain',
	p,
	renderContent,
}: {
	src: string;
	title?: string;
	subTitle?: string;
	isMarkdown?: boolean;
	height?: number;
	objectFit?: ObjectFit;
	p?: string;
	renderContent?: () => JSX.Element;
}) => {
	const spacingRem = useSpacingRem();

	return (
		<GridContainer horizontalSpacing={horizontalSpacing} verticalSpacing={verticalSpacing}>
			<GreyGrid {...gridItemProps}>
				<Column p={p}>
					<SectionHeader
						alignItems="flex-start"
						title={title}
						subTitle={subTitle}
						p={`${spacingRem}rem`}
						hasBottomMargin
						isMarkdown={isMarkdown}
						renderContent={renderContent}
					/>
				</Column>
			</GreyGrid>
			<GreyGrid {...gridItemProps}>
				<Column alignItems="center">
					<ImageBox objectFit={objectFit} src={src} maxHeight={`${height}rem`} />
				</Column>
			</GreyGrid>
		</GridContainer>
	);
};

export default React.memo(ImageHeader);
