import React from 'react';
import { Box, Grid } from '@mui/material';
import { useSnackbar } from '../utils/snackBar';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from '../utils/hooks';

import Button from '../components/Button';
import Dialog from '../components/Dialog';
import FilledTextField from '../components/FilledTextField';
import AuthWrapper from '../components/AuthWrapper';

import useTextFieldController from '../utils/useTextFieldController';
import { validateEmail, validatePassword } from '../utils/helpers';
import { fetchUser, setToken, useCurrentUser } from '../utils/user';

import requestPasswordReset from '../mutations/requestPasswordReset';
import confirmPasswordReset from '../mutations/confirmPasswordReset';

const RequestPasswordResetView = (): JSX.Element => {
	const [searchParams] = useSearchParams();
	const defaultConfirmationCode = searchParams.get('confirmationCode') || undefined;
	const defaultEmail = searchParams.get('email') || undefined;
	const currentUser = useCurrentUser();
	const navigate = useNavigate();
	const snackBar = useSnackbar();

	const {
		newStateValue: email,
		textInputProps: emailFieldProps,
		validate: validateEmailFn,
	} = useTextFieldController({
		defaultValue: currentUser?.email || defaultEmail || '',
		inputLabel: 'Email',
		stateKey: 'email',
		validationFunction: validateEmail,
		stateValueFallback: '',
		textFieldType: 'email',
	});

	const {
		newStateValue: password,
		textInputProps: passwordFieldProps,
		validate: validatePasswordFn,
	} = useTextFieldController({
		defaultValue: '',
		inputLabel: 'Neues Passwort',
		stateKey: 'password',
		validationFunction: validatePassword,
		stateValueFallback: '',
		textFieldType: 'password',
	});

	const {
		newStateValue: code,
		textInputProps: codeFieldProps,
		validate: validateCodeFn,
	} = useTextFieldController({
		defaultValue: defaultConfirmationCode || '',
		inputLabel: 'Code aus E-Mail',
		stateKey: 'code',
		validationFunction: (value) =>
			!value ? 'Bitte ausfüllen' : value.length !== 6 ? 'Der Code muss 6 Zeichen haben' : undefined,
		stateValueFallback: '',
		trim: true,
		limit: 6,
	});

	const [processStep, setProcessStep] = React.useState<
		'START' | 'ENTER_CONFIRMATION_CODE' | 'CONFIRMATION'
	>(defaultConfirmationCode ? 'ENTER_CONFIRMATION_CODE' : 'START');
	const [loading, setLoading] = React.useState(false);

	const handleRequestPasswordReset = React.useCallback(
		async (hideDialog?: string) => {
			if (!currentUser) {
				const validationError = validateEmailFn!();
				if (validationError) {
					return;
				}
			}
			setLoading(true);
			const { success, error } = await requestPasswordReset({ email: email! });
			setLoading(false);
			if (success) {
				if (!hideDialog) {
					Dialog.render({
						title: 'Bestätigungs-Email versendet!',
						description:
							'Wir haben dir soeben eine Email mit einem Bestätigungscode geschickt. Bitte trage diesen innerhalb der nächsten Stunde im nächsten Schritt ein.',
						onClose: () => setProcessStep('ENTER_CONFIRMATION_CODE'),
					});
				}
			} else {
				snackBar({ error });
			}
		},
		[currentUser, email, snackBar, validateEmailFn]
	);

	const handleConfirmPasswordReset = React.useCallback(async () => {
		const validationError = [validatePasswordFn!(), validateCodeFn!()].some(Boolean);
		if (!validationError) {
			setLoading(true);
			const { success, error, jwt } = await confirmPasswordReset({
				email: email!,
				password: password!,
				confirmationCode: code!,
			});
			if (success) {
				setLoading(false);
				if (!currentUser) {
					setToken(jwt!);
					await fetchUser();
					snackBar({ success: 'Das Passwort wurde geändert und du wurdest eingeloggt!' });
					navigate('/?initial=true');
				} else {
					snackBar({ success: 'Das Passwort wurde geändert!' });
					navigate('/');
				}
			} else {
				setLoading(false);
				if (error === 'TOO_MANY_RESET_ATTEMPTS') {
					Dialog.render({
						title: 'Passwortänderung nicht möglich',
						description:
							'Es wurde zu häufig versucht dein Passwort zu ändern. Aus Sicherheitsgründen ist dies nicht weiter möglich. Bitte wende dich an unseren Support.',
					});
				} else if (error === 'CONFIRMATION_CODE_EXPIRED') {
					Dialog.render({
						title: 'Bestätigungscode abgelaufen',
						description:
							'Der Bestätigungscode ist nur 1 Stunde gültig und nun leider abgelaufen. Bitte starte den Prozess zum Zurücksetzen deines Passworts erneut.',
						onClose: () => {
							setProcessStep('START');
						},
					});
				} else {
					snackBar({ error });
				}
			}
		}
	}, [code, currentUser, email, navigate, password, snackBar, validateCodeFn, validatePasswordFn]);

	return (
		<AuthWrapper
			title={currentUser ? 'Passwort ändern' : 'Passwort vergessen'}
			subTitle={
				processStep === 'START'
					? currentUser
						? 'Um dein Passwort zu ändern, fordere einen Bestätigungscode an.'
						: 'Hier kannst du dein Passwort zurücksetzen. Trage deine deine E-Mail Adresse ein und fordere einen Bestätigungscode an.'
					: processStep === 'ENTER_CONFIRMATION_CODE'
					? ' Trage nun dein neues Passwort ein. Achte darauf, dass es mind. 8 Zeichen lang ist und Zahlen und Sonderzeichen enthält. Zur Verifizierung deiner Person, trage bitte im zweiten Schritt den Code aus deiner E-Mail ein und klicke auf Passwort bestätigen.'
					: ''
			}
		>
			<>
				{currentUser ? null : (
					<Grid item xs={12}>
						<FilledTextField
							variant="filled"
							fullWidth
							{...emailFieldProps}
							disabled={processStep !== 'START'}
						/>
					</Grid>
				)}

				{processStep === 'START' ? (
					<>
						<Grid item xs={12}>
							<Button
								fullWidth
								onClick={handleRequestPasswordReset}
								variant="mainButton"
								loading={loading}
							>
								{currentUser ? 'Bestätigungscode anfordern' : 'Anfrage absenden'}
							</Button>
						</Grid>
						{currentUser ? null : (
							<Grid item xs={12}>
								<Button variant="link" navigateTo="/login" disabled={loading}>
									Einloggen
								</Button>
							</Grid>
						)}
					</>
				) : null}
				{processStep === 'ENTER_CONFIRMATION_CODE' ? (
					<>
						<Grid item xs={12}>
							<FilledTextField variant="filled" fullWidth {...passwordFieldProps} />
						</Grid>
						<Grid item xs={12}>
							<FilledTextField variant="filled" fullWidth {...codeFieldProps} />
						</Grid>
						<Grid item xs={12}>
							<Box display="flex" flexDirection="row" alignItems="center">
								<Button
									m="0 1rem 0 0"
									variant="mainButton"
									onClick={handleConfirmPasswordReset}
									loading={loading}
								>
									Passwort bestätigen
								</Button>
								<Button
									variant="secondButton"
									onClick={handleRequestPasswordReset}
									loading={loading}
									id="hideDialog"
								>
									Erneut senden
								</Button>
							</Box>
						</Grid>
					</>
				) : null}
			</>
		</AuthWrapper>
	);
};

export default RequestPasswordResetView;
