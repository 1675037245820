import { format } from 'date-fns';

export const getDate = (input?: number | string): string => {
	try {
		return !input
			? ''
			: typeof input === 'string'
			? format(new Date(input), 'dd.MM.yyyy')
			: format(input, 'dd.MM.yyyy');
	} catch (e) {
		console.log(`>>> getDate error for ${input}`, e);
		return 'error';
	}
};

export const getDateWithTime = (input?: number | string): string => {
	try {
		return !input
			? ''
			: typeof input === 'string'
			? format(new Date(input), 'dd.MM.yyyy HH:mm')
			: format(input, 'dd.MM.yyyy HH:mm');
	} catch (e) {
		console.log(`>>> getDateWithTime error for ${input}`, e);
		return 'error';
	}
};
