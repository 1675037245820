import client from '../utils/client';
import { gql } from '@apollo/client';

type Error = 'UNAUTHENTICATED' | 'NETWORK_ERROR';

const validatePaypalSubscription = async ({
	paymentId,
}: {
	paymentId: string;
}): Promise<
	| { success: true; error: undefined; status: string }
	| { success: false; error: Error; status: undefined }
> => {
	try {
		const { errors, data } = await client.mutate({
			mutation: gql`
				mutation validatePaypalSubscription($paymentId: ID!) {
					validatePaypalSubscription(paymentId: $paymentId) {
						status
					}
				}
			`,
			variables: { paymentId },
		});
		if (errors) {
			return {
				success: false,
				status: undefined,
				error: (errors[0]?.message || 'UNKNOWN') as Error,
			};
		}
		return { success: true, status: data.validatePaypalSubscription.status, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, status: undefined, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default validatePaypalSubscription;
