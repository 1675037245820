import React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';
import Fab from '@mui/material/Fab';
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';

import { useNavigate } from '../utils/hooks';
import iconNameDict, { IconName } from '../utils/iconNameDict';
import { FLOATING_ACTION_BUTTON_ZINDEX } from '../consts';
import Txt from './Txt';

const StyledFloatingActionButton = styled(Fab)<{ $backgroundColor?: string; $iconColor?: string }>`
	background-color: ${(p) => p.$backgroundColor || ''};
	color: ${(p) => p.$iconColor || ''};
	margin: 0;
	padding: 0.5rem;
`;

const FloatingActionButton = ({
	id,
	m,
	p,
	loading,
	color,
	onClick,
	iconName,
	navigateTo,
	iconColor,
	backgroundColor,
	text,
	extended = false,
	...rest
}: {
	id?: string;
	m?: string;
	p?: string;
	loading?: boolean;
	color?: string;
	onClick?: (val?: any) => any;
	iconName?: IconName;
	navigateTo?: string;
	iconColor?: string;
	extended?: boolean;
	text?: string;
	backgroundColor?: string;
} & Omit<MuiIconButtonProps, 'color'>): JSX.Element => {
	const navigate = useNavigate();
	const handleClick = React.useCallback(() => {
		if (navigateTo) {
			navigate(navigateTo);
		} else {
			onClick?.(id);
		}
	}, [id, navigate, navigateTo, onClick]);

	const Icon = iconName && iconNameDict[iconName] ? iconNameDict[iconName] : null;

	return (
		<Box m={m} p={p} position="fixed" bottom="0" right="0" zIndex={FLOATING_ACTION_BUTTON_ZINDEX}>
			<StyledFloatingActionButton
				{...rest}
				$backgroundColor={backgroundColor}
				$iconColor={iconColor}
				onClick={handleClick}
				variant={extended ? 'extended' : 'circular'}
			>
				{loading ? (
					// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
					<CircularProgress size={24} sx={{ color }} />
				) : Icon ? (
					<Icon />
				) : null}
				{text && <Txt>{text}</Txt>}
			</StyledFloatingActionButton>
		</Box>
	);
};

export default React.memo(FloatingActionButton);
