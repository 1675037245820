import client from '../utils/client';
import { gql } from '@apollo/client';

type Error = 'UNAUTHENTICATED' | 'NETWORK_ERROR' | 'UNKNOWN';

const savePaypalSubscription = async ({
	membershipId,
	paypalSubscriptionID,
}: {
	membershipId: string;
	paypalSubscriptionID: string;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation SavePaypalSubscription($membershipId: ID!, $paypalSubscriptionID: String!) {
					savePaypalSubscription(
						membershipId: $membershipId
						paypalSubscriptionID: $paypalSubscriptionID
					) {
						success
					}
				}
			`,
			variables: { membershipId, paypalSubscriptionID },
			refetchQueries: ['CURRENT_USER_QUERY'],
		});
		if (errors) {
			return { success: false, error: (errors[0]?.message || 'UNKNOWN') as Error };
		}
		return { success: true, error: undefined };
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default savePaypalSubscription;
