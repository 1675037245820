import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from 'styled-components';
import { TypographyProps } from '@mui/material/Typography';

const keys = ['value'];

const MyResponsiveBar = ({
	data,
	unit,
	textVariant = 'body1',
	colors,
}: {
	data: { label: string; value: number }[];
	unit?: string;
	textVariant?: TypographyProps['variant'];
	colors?: string[];
}) => {
	const theme = useTheme();
	const typographyTheme = theme.typography[textVariant];

	const nivoTheme = React.useMemo(
		() => ({
			fontSize: typographyTheme.fontSize,
			fontFamily: typographyTheme.fontFamily,
			color: typographyTheme.color,
			fontWeight: typographyTheme.fontWeight,
			letterSpacing: typographyTheme.letterSpacing,
			lineHeight: typographyTheme.lineHeight,
		}),
		[
			typographyTheme.color,
			typographyTheme.fontFamily,
			typographyTheme.fontSize,
			typographyTheme.fontWeight,
			typographyTheme.letterSpacing,
			typographyTheme.lineHeight,
		]
	);
	return (
		<ResponsiveBar
			borderRadius={10}
			data={data}
			colors={colors || { scheme: 'nivo' }}
			keys={keys}
			indexBy="label"
			margin={{ bottom: 30 }}
			padding={0.3}
			axisTop={null}
			axisRight={null}
			axisLeft={null}
			axisBottom={{
				tickSize: 0,
				tickPadding: 10,
			}}
			enableGridY={false}
			isInteractive={false}
			colorBy="indexValue"
			label={unit ? (d) => `${d.value} ${unit}` : undefined}
			theme={nivoTheme}
		/>
	);
};

export default MyResponsiveBar;
