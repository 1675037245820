import client from '../utils/client';
import { gql } from '@apollo/client';
import { CertificateInfoFragment } from '../utils/fragments';
import { CURRENT_USER_QUERY, CurrentUser } from '../utils/user';

type Error =
	| 'NETWORK_ERROR'
	| 'NO_CERTIFICATE_FOUND'
	| 'CONTENT_NOT_COMPLETED'
	| 'CERTIFICATE_ALREADY_EXISTS'
	| 'CONTENT_NOT_FOUND'
	| 'CONTENT_IS_DEMO';

const createCertificate = async (
	contentId: string
): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation CreateCertificate($contentId: ID!) {
					createCertificate(contentId: $contentId) {
						...CertificateInfoFragment
					}
				}
				${CertificateInfoFragment}
			`,
			variables: { contentId },
			update: (cache, { data }) => {
				const { currentUser } = cache.readQuery({
					query: CURRENT_USER_QUERY,
				}) as {
					currentUser: {
						certificates: CurrentUser['certificates'];
					};
				};
				cache.writeQuery({
					query: CURRENT_USER_QUERY,
					data: {
						currentUser: {
							...currentUser,
							certificates: [...(currentUser?.certificates || []), data?.createCertificate],
						},
					},
				});
			},
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default createCertificate;
