import React from 'react';
import TextField from './TextField';
import fuzzySearch from '../utils/fuzzySearch';
import { Box } from '@mui/system';

const SearchField2 = ({
	searchableData,
	searchKeys,
	onSearch,
	width = '20rem',
	reportKey,
	m,
	placeHolder,
}: {
	searchableData?: { [key: string]: string | unknown }[];
	searchKeys: Array<string>;
	onSearch: (reportKeys: string[]) => void;
	width?: string;
	reportKey: string;
	m?: string;
	placeHolder?: string;
}) => {
	const [query, setQuery] = React.useState<string>('');

	const handleChangeQuery = React.useCallback((input) => {
		setQuery(input);
	}, []);

	const handleSearch = React.useCallback(() => {
		const searchResults = !query
			? searchableData!.map((item) => item[reportKey] as string)
			: (fuzzySearch(searchableData!, query!, searchKeys, {
					ignoreLocation: true,
			  }).map(({ item }) => item[reportKey]) as string[]);
		onSearch(searchResults === undefined ? [] : searchResults);
	}, [onSearch, query, searchKeys, searchableData, reportKey]);

	React.useEffect(() => {
		if (searchableData) {
			handleSearch();
		}
	}, [handleSearch, query, searchableData]);

	return (
		<Box width={width} m={m}>
			<TextField startIconName="Search" onChange={handleChangeQuery} placeHolder={placeHolder} />
		</Box>
	);
};

export default React.memo(SearchField2);
