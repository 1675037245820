/* eslint  no-useless-escape: 0 */

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	padding: 15px;
`;

export const AccessibilityView = () => {

	return (
		<Wrapper>
			<ReactMarkdown>{versionText}</ReactMarkdown>
		</Wrapper>
	);
};

const versionText = `
# Erklärung zur digitalen Barrierefreiheit

Die visionYOU GmbH bemüht sich, ihren Webauftritt [www.bildungsfreaks.de](http://www.bildungsfreaks.de/) in Einklang mit den nationalen Rechtsvorschriften zur Umsetzung der Richtlinie (EU) 2016/2102 des Europäischen Parlaments und des Rates barrierefrei zugänglich zu machen. Die technischen Anforderungen zur Barrierefreiheit ergeben sich aus der Barrierefreie-Informationstechnik-Verordnung (BITV) 2.0.

Diese Erklärung zur digitalen Barrierefreiheit gilt für das Angebot des Webauftritts bildungsfreaks.de der visionYOU GmbH.

## Prüfungsrahmen:

BITV-Selbstbewertung / Barrierefreiheitstest nach BITV gemäß BITV 2.0 / EN 301 549

## Projektdaten:

URL der geprüften Seite\*n: [www.bildunfgsfreaks.de](http://www.bildunfgsfreaks.de/) inkl. repräsentativer Kursseiten  
Prüfzeitraum: 28.08.2023 - 29.08.2023  
Datum Prüfbericht: 29.08.2023  
Prüfer: visionYOU GmbH  
Art des Tests: BITV-Selbstbewertung

## Ergebnis für die Seite:

Dieser Webauftritt ist teilweise barrierefrei. Es werden teilweise die Anforderungen der BITV 2.0 erfüllt.

Aufgrund der Überprüfung ist die Website wegen folgender Anforderungen nur teilweise barrierefrei, wir arbeiten an der baldmöglichen Beseitigung:

- 9.1.1.1a. Alternativtexte für Bedienelemente: Es sind nicht alle Alternativtexte für Bildelemente vorhanden.
- 9.1.1.1b. Alternativtexte für Grafiken und Objekte: Es sind nicht alle Alternativtexte für Grafiken und Objekte vorhanden.
- 9.1.1.1c. Leere alt-Attribute für Layoutgrafiken: Es sind nicht alle Leere alt-Attribute für Layoutgrafiken vorhanden.
- 9.1.2.3. Audiodeskription oder Volltext-Alternative für Videos: Es sind bisher keine Audiodeskriptionen oder Volltext-Alternativen für Videos vorhanden.
- 9.1.3.1a. HTML-Strukturelemente für Überschriften: Die Prüfung und Überarbeitung der HTML-Strukturelemente ist noch nicht abgeschlossen.
- 9.1.3.1b. HTML-Strukturelemente für Listen: Die Prüfung und Überarbeitung der HTML-Strukturelemente für Listen ist noch nicht abgeschlossen.
- 9.1.3.1d. Inhalt gegliedert: Inhaltsgliederungen befinden sich aktuell in der Überarbeitung.
- 9.1.3.3. Ohne Bezug auf sensorische Merkmale nutzbar: Verweise auf Inhalte der Seite nutzen aktuell ausschließlich sensorische Merkmale wie Farbe, Form oder Position.
- 9.1.4.11. Kontraste von Grafiken und grafischen Bedienelementen ausreichend: Die Prüfung und Überarbeitung der Kontraste ist noch nicht abgeschlossen.
- 9.2.4.1. Bereiche überspringbar: Wird im Rahmen der HTML-Strukturprüfung verbessert.
- 12.1.1. Dokumentation von Kompatibilität und Barrierefreiheit: Die Dokumentation wird im 4. Quartal 2023 erneuert. Bei Mängeln, die nicht oder später beseitigt werden, weiterhin darauf hingewiesen.

Wir arbeiten derzeit an der Beseitigung der bekannten Mängel. Sobald dieser Prozess abgeschlossen ist, werden wir die Informationen auf dieser Seite veröffentlichen.

# Barrieren melden: Kontaktieren Sie uns bei Anmerkungen oder Fragen zur digitalen Barrierefreiheit!

Sind Ihnen Mängel beim barrierefreien Zugang zu Inhalten von [www.bildungsfreaks.de](http://www.bildungsfreaks.de/) aufgefallen? Dann melden Sie sich gerne bei uns.

visionYOU GmbH  
Stahnsdorfer Str. 107  
14482 Potsdam  
Telefon: 0331 58256033  
E-Mail: [info@visionyou.de](mailto:info@visionyou.de)
`;

