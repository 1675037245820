import React from 'react';
import { chunk, orderBy } from 'lodash';

export const useStringArray = (itemOrItems?: string[] | string): string[] | undefined => {
	const arrayed = React.useMemo(
		() =>
			!itemOrItems
				? undefined
				: Array.isArray(itemOrItems)
				? itemOrItems.length === 0
					? undefined
					: itemOrItems
				: typeof itemOrItems === 'string'
				? itemOrItems.includes(',')
					? itemOrItems.split(',')
					: [itemOrItems]
				: undefined,
		[itemOrItems]
	);
	return arrayed;
};

type ArrayOfObjects = { id: string; [key: string]: unknown }[];
type ArrayOfStringValueObjects = { id: string; [key: string]: string }[];

export const useArrayOfStringValueObjects = (
	array: ArrayOfObjects | undefined
): ArrayOfStringValueObjects | undefined | null => {
	const results = React.useMemo(
		() =>
			!array
				? undefined
				: (array?.map((item) =>
						Object.entries(item)
							.filter(([_, value]) => typeof value === 'string')
							.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
				  ) as ArrayOfStringValueObjects),
		[array]
	);
	return results;
};

export const useTranslatedArray = (
	array?: ArrayOfObjects,
	translateDict?: { [key: string]: string }
): ArrayOfObjects | undefined => {
	const keysToKeep = React.useMemo(() => Object.keys(translateDict || {}), [translateDict]);
	const translatedArray = React.useMemo(
		() =>
			!array || !translateDict
				? undefined
				: (array.map((item) =>
						Object.entries(item)
							.filter(([key]) => key === 'id' || keysToKeep.includes(key))
							.reduce(
								(acc, [key, value]) => ({
									...acc,
									[key === 'id' ? 'id' : (translateDict[key] as string)]: value,
								}),
								{}
							)
				  ) as { id: string; [key: string]: unknown }[]),
		[array, keysToKeep, translateDict]
	);
	return translatedArray;
};

export const useArrayKeys = (array?: ArrayOfObjects, includeId?: boolean) => {
	const result = React.useMemo(
		() =>
			!array || typeof array[0] !== 'object'
				? undefined
				: Object.keys(array[0]).filter((key) => (key === 'id' ? (includeId ? true : false) : true)),

		[array, includeId]
	);
	return result;
};

export const useSortedArray = <T>(
	array?: T[],
	sortKeys?: string,
	sortDirection?: 'ASCENDING' | 'DESCENDING'
): T[] => {
	const sortKeysArray = useStringArray(sortKeys);

	const sortedArray = React.useMemo(
		() =>
			!array
				? []
				: !sortKeys || !sortKeysArray
				? array
				: orderBy(
						array,
						sortKeysArray,
						sortKeysArray.map(() => (sortDirection === 'ASCENDING' ? 'asc' : 'desc'))
				  ),
		[array, sortKeys, sortKeysArray, sortDirection]
	);
	return sortedArray;
};

export const useChunkedArray = <T>(array?: T[], chunkSize?: number) => {
	return React.useMemo(() => chunk(array, chunkSize), [array, chunkSize]);
};

export default null;
