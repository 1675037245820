import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box } from '@mui/material';

import GridItem from './GridItem';
import ContentWrapper from './ContentWrapper';
import GridContainer from './GridContainer';
import SectionHeader from './SectionHeader';

const Background = styled(Box)`
	display: flex;
	flex-direction: column;
	background-color: ${(p) => p.theme.customColors.decentBlue};
`;

const horizontalSpacing = { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 };
const verticalSpacing = { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 };

const AuthWrapper = ({
	children,
	title,
	subTitle,
}: {
	children: JSX.Element | JSX.Element[];
	title: string;
	subTitle?: string;
}): JSX.Element => {
	const theme = useTheme();
	return (
		<ContentWrapper maxWidth={`${theme.authViewsMaxWidthPx}px`}>
			<Background m="2rem">
				<GridContainer
					horizontalSpacing={horizontalSpacing}
					verticalSpacing={verticalSpacing}
					p="4rem 2rem"
					excludePadding
				>
					<GridItem xs={12}>
						<SectionHeader alignItems="flex-start" title={title} subTitle={subTitle} />
					</GridItem>
					<>{children}</>
				</GridContainer>
			</Background>
		</ContentWrapper>
	);
};

export default AuthWrapper;
