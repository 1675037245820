import client from '../utils/client';
import { gql } from '@apollo/client';

type Error = 'NETWORK_ERROR' | 'UNAUTHORIZED';

const updateReportExceededDeadlines = async ({
	report,
}: {
	report: string;
}): Promise<{ success: true; error: undefined } | { success: false; error: Error }> => {
	try {
		const { errors } = await client.mutate({
			mutation: gql`
				mutation UpdateReportExceededDeadlines($report: Boolean!) {
					updateReportExceededDeadlines(report: $report) {
						success
					}
				}
			`,
			variables: { report },
			refetchQueries: ['CurrentUser'],
		});
		if (errors) {
			const errorCode = errors[0]?.extensions?.code as Error;
			return {
				success: false,
				error: errorCode,
			};
		}
		return {
			success: true,
			error: undefined,
		};
	} catch (e) {
		if (e && e.networkError) return { success: false, error: 'NETWORK_ERROR' };
		throw e;
	}
};

export default updateReportExceededDeadlines;
