import client from '../utils/client';
import { gql } from '@apollo/client';
import { CURRENT_USER_QUERY, CurrentUser } from '../utils/user';
import { ContentInfosFragment } from '../utils/fragments';

type Error = 'NETWORK_ERROR' | 'NO_ACCESS_TO_CONTENT' | 'UNAUTHENTICATED' | 'INVALID_CONTENT';

const createProductContentDownloadLink = async (
	contentId: string
): Promise<
	| { success: true; error: undefined; presignedUrl: string }
	| { success: false; error: Error; presignedUrl: undefined }
> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: gql`
				mutation CreateProductContentDownloadLink($contentId: String!) {
					createProductContentDownloadLink(contentId: $contentId) {
						success
						presignedUrl
						content {
							...ContentInfosFragment
						}
					}
				}
				${ContentInfosFragment}
			`,
			variables: { contentId },
			update: (cache, { data: incomingData }) => {
				const { currentUser } = cache.readQuery({
					query: CURRENT_USER_QUERY,
				}) as {
					currentUser: {
						downloadedContents: CurrentUser['downloadedContents'];
					};
				};
				const content = incomingData?.createProductContentDownloadLink.content;
				const updatedCurrentUser = {
					...currentUser,
					downloadedContents: currentUser.downloadedContents.some((dc) => dc.id === content.id)
						? currentUser.downloadedContents
						: [content, ...currentUser.downloadedContents],
				};
				cache.writeQuery({
					query: CURRENT_USER_QUERY,
					data: { currentUser: updatedCurrentUser },
				});
			},
		});
		if (errors) {
			return {
				success: false,
				error: errors[0]?.extensions?.code as Error,
				presignedUrl: undefined,
			};
		}
		return {
			success: true,
			error: undefined,
			presignedUrl: data.createProductContentDownloadLink.presignedUrl,
		};
	} catch (e) {
		if (e && e.networkError)
			return { success: false, error: 'NETWORK_ERROR', presignedUrl: undefined };
		throw e;
	}
};

export default createProductContentDownloadLink;
