import React from 'react';
import styled from 'styled-components';
import { CircularProgress, Grid, TypographyProps, TablePagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Txt from './Txt';
import { Box } from '@mui/system';
import SearchField2 from './SearchField2';
import { useArrayOfStringValueObjects, useArrayKeys, useStringArray } from '../utils/arrayHooks';
import SelectButton from '../components/SelectButton';
import GridItem from './GridItem';
import { useIsMobile } from '../utils/hooks';
import { orderBy, chunk } from 'lodash';

export type SortType = 'ASCENDING' | 'DESCENDING';

const options: { label: string; value: SortType }[] = [
	{ label: 'A-Z', value: 'ASCENDING' },
	{ label: 'Z-A', value: 'DESCENDING' },
];

export type InputObject = {
	id: string;
	[key: string]: string | number | boolean | null | undefined | JSX.Element;
};

const reportKey = 'id';

type DataRow = {
	id: string;
	colIndex: number;
	columns: {
		text?: string;
		jsx?: JSX.Element;
	}[];
};

const StyledTablePagination = styled(TablePagination).attrs({
	component: 'div',
})`
	.MuiTablePagination-selectLabel {
		margin: 0;
	}
	.MuiTablePagination-displayedRows {
		margin: 0;
	}
`;

const gridItemProps = { xs: 12, sm: 6, md: 6 };
const defaultRowsPerPageOptions = [5, 10, 25];

const labelDisplayedRows = ({ from, to, count }) =>
	`${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`;

const useRelevantDataRows = ({
	dataRows,
	searchableData,
	searchedIds,
	sortKey,
	sortType,
	page,
	rowsPerPage,
}: {
	dataRows: DataRow[] | null | undefined;
	searchableData?: { id: string; [key: string]: unknown }[];
	searchedIds?: string[];
	sortKey?: string;
	sortType?: SortType;
	page: number;
	rowsPerPage: number;
}): DataRow[] => {
	const sortKeys = useStringArray(sortKey);

	return React.useMemo(() => {
		if (!dataRows || !dataRows.length) return [];
		let resultDataRows = dataRows;
		if (searchedIds) {
			if (searchedIds.length === 0) {
				resultDataRows = [];
			} else {
				resultDataRows = dataRows.filter((dataRow) => searchedIds.includes(dataRow.id));
			}
		}
		if (sortKeys && sortKeys.length > 0 && searchableData) {
			const sorted = orderBy(
				searchableData.filter((item) => resultDataRows.some((dataRow) => dataRow.id === item.id)),
				sortKeys,
				sortKeys.map(() => (sortType === 'ASCENDING' ? 'asc' : 'desc'))
			);
			resultDataRows = sorted.map((s) => resultDataRows.find((d) => d.id === s.id)) as DataRow[];
		}

		const chunked = chunk(resultDataRows, rowsPerPage)[page];
		return chunked || resultDataRows;
	}, [dataRows, page, rowsPerPage, searchableData, searchedIds, sortKeys, sortType]);
};

const SimpleTable4 = ({
	renderData,
	renderActions,
	numberOfLines,
	p,
	center,
	headRowFontWeight,
	headRowVariant,
	searchKeys,
	hiddenKeys,
	searchableData,
	sortKey,
	rowsPerPageOptions = defaultRowsPerPageOptions,
	hasPagination,
}: {
	renderData?: InputObject[];
	renderActions?: (inputObject) => JSX.Element;
	numberOfLines?: number;
	p?: string;
	center?: boolean;
	headRowFontWeight?: 'bold';
	headRowVariant?: TypographyProps['variant'];
	searchKeys?: string[] | string;
	hiddenKeys?: string[] | string;
	searchableData?: { id: string; [key: string]: unknown }[];
	sortKey?: string;
	rowsPerPageOptions?: number[];
	hasPagination?: boolean;
}) => {
	const isMobile = useIsMobile();

	const hiddenKeysArray = useStringArray(hiddenKeys);
	const sanitizedSearchableData = useArrayOfStringValueObjects(searchableData);
	const maybeSearchKeys = useStringArray(searchKeys);
	const searchableDataKeys = useArrayKeys(searchableData);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [searchedIds, setSearchedIds] = React.useState<string[] | [] | undefined>(undefined);

	const [sortType, setSortType] = React.useState<SortType | undefined>(undefined);
	const handleSort = React.useCallback((newValue) => {
		setSortType(newValue);
	}, []);

	const handleChangePage = React.useCallback((event, newPage) => {
		setPage(newPage);
	}, []);

	const handleChangeRowsPerPage = React.useCallback((event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}, []);

	const headRow = React.useMemo(
		() =>
			!renderData || renderData.length === 0 || typeof renderData[0] !== 'object'
				? null
				: [
						...Object.keys(renderData[0])
							.filter((key) => key !== 'id' && !hiddenKeysArray?.includes(key))
							.map((key) => key),
						...(renderActions ? ['Aktionen'] : []),
				  ],
		[hiddenKeysArray, renderData, renderActions]
	);

	const dataRows = React.useMemo(() => {
		return !renderData
			? renderData
			: (renderData.map((inputObject, index) => {
					const columns = Object.entries(inputObject)
						.filter(([key]) => key !== 'id' && !hiddenKeysArray?.includes(key))
						.map(([_, value]) => {
							if (
								typeof value === 'string' ||
								typeof value === 'number' ||
								typeof value === 'boolean' ||
								value === null ||
								value === undefined
							) {
								return { text: String(value) };
							} else {
								return { jsx: value };
							}
						});

					return {
						id: inputObject.id,
						colIndex: index,
						columns: [...columns, ...(renderActions ? [{ jsx: renderActions(inputObject) }] : [])],
					};
			  }) as { id: string; colIndex: number; columns: { text?: string; jsx?: JSX.Element }[] }[]);
	}, [hiddenKeysArray, renderData, renderActions]);

	const handleSearch = React.useCallback((reportKeys: string[] | []) => {
		setPage(0);
		setSearchedIds(reportKeys);
	}, []);

	const relevantDataRows = useRelevantDataRows({
		dataRows,
		searchableData,
		searchedIds,
		sortKey,
		sortType,
		page,
		rowsPerPage,
	});

	return headRow === undefined || dataRows === undefined ? (
		<CircularProgress />
	) : (
		<>
			{sanitizedSearchableData && (sortKey || searchableDataKeys) ? (
				<Box display="flex" flexDirection="row" width="100%" m="0 0 2rem 0" alignItems="center">
					<Grid container direction={isMobile ? 'column-reverse' : 'row'}>
						{
							<GridItem {...gridItemProps} justifyContent="flex-start">
								{sortKey ? (
									<SelectButton
										label="Sortieren"
										options={options}
										onChange={handleSort}
										variant="mainButton"
									/>
								) : (
									<Box />
								)}
							</GridItem>
						}
						{
							<GridItem {...gridItemProps} justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
								{searchableDataKeys ? (
									<SearchField2
										searchableData={sanitizedSearchableData}
										searchKeys={maybeSearchKeys || searchableDataKeys}
										onSearch={handleSearch}
										reportKey={reportKey}
										placeHolder={(maybeSearchKeys || searchableDataKeys).join(', ')}
										m={isMobile ? '0 0 1rem 0' : undefined}
									/>
								) : (
									<Box />
								)}
							</GridItem>
						}
					</Grid>
				</Box>
			) : null}

			<Box p={p} width="100%">
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{headRow?.map((headString, index) => (
									<TableCell key={`${headString}${index}`}>
										<Box display="flex" width="100%" justifyContent={center ? 'center' : undefined}>
											<Txt
												numberOfLines={numberOfLines}
												variant={headRowVariant}
												fontWeight={headRowFontWeight}
											>
												{headString}
											</Txt>
										</Box>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{relevantDataRows?.map((dataRow, rowIndex) => (
								<TableRow key={`${dataRow.id}${rowIndex}`}>
									{dataRow.columns.map(({ text, jsx }, columnIndex) => (
										<TableCell key={`${dataRow.id}:${rowIndex}:${columnIndex}`}>
											<Box
												display="flex"
												width="100%"
												justifyContent={center ? 'center' : undefined}
											>
												{text ? <Txt numberOfLines={numberOfLines}>{text}</Txt> : jsx}
											</Box>
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<Box m="1rem 0 0 0" />
				{!hasPagination || searchedIds?.length === 0 || dataRows.length === 0 ? null : (
					<StyledTablePagination
						rowsPerPageOptions={rowsPerPageOptions}
						count={searchedIds ? searchedIds.length : dataRows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Zeilen pro Seite"
						labelDisplayedRows={labelDisplayedRows}
					/>
				)}
			</Box>
		</>
	);
};

export default React.memo(SimpleTable4);
