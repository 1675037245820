import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { useSnackbar } from '../utils/snackBar';
import Button from './Button';
import DialogWrapper from './DialogWrapper';
import { DialogButtonRow, DialogHeader } from './Dialog';
import SearchSelectField2, { SearchSelectFieldRef2 } from './SearchSelectField2';
import { useCompanyDepartment } from '../utils/companyDepartment';
import removeFromVolumeLicenses from '../mutations/removeFromVolumeLicenses';
import { useInitializeStateWithDefault } from '../utils/hooks';

const RemoveFromVolumeLicensesDialog = ({
	dismissPortal,
	userId,
	companyDepartmentId,
}: {
	dismissPortal: () => void;
	userId?: string;
	companyDepartmentId?: string;
}): JSX.Element => {
	const snackBar = useSnackbar();

	const [loading, setLoading] = React.useState(false);

	const companyDepartment = useCompanyDepartment(companyDepartmentId);
	const userVolumeLicenses = React.useMemo(
		() =>
			companyDepartment?.volumeLicenses
				?.filter((vLicense) => vLicense.users.some((user) => user.id === userId))
				.sort()
				.map((vLicense) => ({
					id: vLicense.id,
					title: vLicense.products.map((p) => p.title).join(', '),
				})) || [],
		[companyDepartment?.volumeLicenses, userId]
	);
	const defaultValues = React.useMemo(
		() => userVolumeLicenses?.map(({ id }) => id),
		[userVolumeLicenses]
	);

	const [selectedVolumeLicenseIds, setSelectedVolumeLicenseIds, inittedSelectedVolumeLicenseIds] =
		useInitializeStateWithDefault({
			defaultValue: defaultValues,
			finishedInitializing: companyDepartment !== undefined,
		});
	const handleChangeSelectedVolumeLicenseIds = React.useCallback(
		(items) => {
			const vLincenseIds = items.map((item) => item.value);
			setSelectedVolumeLicenseIds(vLincenseIds);
		},
		[setSelectedVolumeLicenseIds]
	);

	const searchSelectFieldRef = React.useRef<SearchSelectFieldRef2>(null);

	const removedVolumeLicenseIds = React.useMemo(
		() =>
			userVolumeLicenses
				.filter((userVLicense) => !selectedVolumeLicenseIds?.includes(userVLicense.id))
				?.map((userVLicense) => userVLicense.id),
		[selectedVolumeLicenseIds, userVolumeLicenses]
	);
	const handleUpdateVolumeLicenseUsers = React.useCallback(async () => {
		setLoading(true);
		const { error } = await removeFromVolumeLicenses({
			volumeLicenseIds: removedVolumeLicenseIds,
			userId: userId!,
		});
		setLoading(false);
		if (error) {
			snackBar({ error });
		} else {
			snackBar({ success: 'Nutzer erfolgreich entfernt' });
			dismissPortal();
		}
	}, [dismissPortal, removedVolumeLicenseIds, snackBar, userId]);

	const initializing = !inittedSelectedVolumeLicenseIds;

	return (
		<DialogWrapper p="2rem">
			{initializing ? (
				<CircularProgress />
			) : (
				<>
					<DialogHeader title="Nutzer-Volumenlizenzen editieren" />
					<SearchSelectField2
						label="Volumenlizenzen"
						multiple
						options={userVolumeLicenses}
						onChange={handleChangeSelectedVolumeLicenseIds}
						m="2rem 0 0 0"
						width="100%"
						ref={searchSelectFieldRef}
						defaultValues={defaultValues}
						labelKey="title"
						valueKey="id"
						disabled={loading}
					/>
					<DialogButtonRow>
						<Button m="0 1rem 0 0" onClick={dismissPortal} loading={loading}>
							Abbrechen
						</Button>
						<Button
							variant="mainButton"
							onClick={handleUpdateVolumeLicenseUsers}
							loading={loading}
							disabled={!removedVolumeLicenseIds?.length}
						>
							Speichern
						</Button>
					</DialogButtonRow>
				</>
			)}
		</DialogWrapper>
	);
};

export default RemoveFromVolumeLicensesDialog;
