import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '../components/ContentWrapper';

import SectionWithoutBackground from '../components/SectionWithoutBackground';
import SectionHeader from '../components/SectionHeader';
import { useCurrentUser } from '../utils/user';
import { Box } from '@mui/material';
import Dialog from '../components/Dialog';
import IconButton from '../components/IconButton';
import InviteToVolumeLicenseDialog from '../components/InviteToVolumeLicenseDialog';
import ReinviteToVolumeLicenseDialog from '../components/ReinviteToVolumeLicenseDialog';
import { getDate } from '../utils/time';
import Card from '../components/Card';
import SimpleTable4 from '../components/SimpleTable4';

const Row = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
`;

const KEY_1 = 'Abteilung';
const KEY_2 = 'Kurse';

const CompanyDepartmentVolumeLicensesListView = () => {
	const currentUser = useCurrentUser();
	const companyVolumeLicenses = currentUser?.companyDepartment?.volumeLicenses;

	const tableData = React.useMemo(
		() =>
			companyVolumeLicenses?.map((volumeLicense) => {
				return {
					'id': volumeLicense.id,
					[KEY_1]: volumeLicense.companyDepartment?.title,
					[KEY_2]: volumeLicense.products?.map((product) => product.title).join(', '),
					'Anzahl Lizenzen': volumeLicense.remainingSeats + volumeLicense.takenSeats,
					'Aktivierte Lizenzen': volumeLicense.takenSeats,
					'Freie Lizenzen': volumeLicense.remainingSeats,
					'Erstellt am': getDate(volumeLicense.createdAt),
					'Beenden in Tagen': volumeLicense.finishInDays || '',
					'Status': volumeLicense.active ? 'Aktiv' : 'Inaktiv',
					'active': volumeLicense.active,
				};
			}) || [],
		[companyVolumeLicenses]
	);

	const handleShowInviteToVolumeLicensesDialog = React.useCallback(
		(id) => {
			const productTitles = companyVolumeLicenses
				?.find((volumeLicense) => volumeLicense.id === id)
				?.products?.map((product) => product.title)
				.join(', ');

			Dialog.render({
				renderContent: ({ dismissPortal }) => (
					<InviteToVolumeLicenseDialog
						dismissPortal={dismissPortal}
						id={id}
						productTitles={productTitles}
					/>
				),
				isLocked: true,
			});
		},
		[companyVolumeLicenses]
	);

	const handleShowReinviteToVolumeLicensesDialog = React.useCallback((id) => {
		Dialog.render({
			renderContent: ({ dismissPortal }) => (
				<ReinviteToVolumeLicenseDialog dismissPortal={dismissPortal} id={id} />
			),
			isLocked: true,
		});
	}, []);

	const renderActions = React.useCallback(
		({ id, active }) => {
			return (
				<Row>
					<IconButton
						m="0 1rem 0 0"
						iconName="PersonAdd"
						onClick={handleShowInviteToVolumeLicensesDialog}
						id={id}
						disabled={!active}
					/>
					<IconButton
						m="0 1rem 0 0"
						iconName="Replay"
						onClick={handleShowReinviteToVolumeLicensesDialog}
						id={id}
						disabled={!active}
					/>
				</Row>
			);
		},
		[handleShowInviteToVolumeLicensesDialog, handleShowReinviteToVolumeLicensesDialog]
	);

	return (
		<ContentWrapper>
			<SectionWithoutBackground flexDirection="column">
				<SectionHeader
					alignItems="flex-start"
					title="Lizenzen"
					subTitle="In diesem Bereich können Sie ihre Lizenzen verwalten. "
				/>
			</SectionWithoutBackground>
			{!companyVolumeLicenses?.length ? null : (
				<SectionWithoutBackground>
					<Card>
						<SimpleTable4
							renderData={tableData}
							numberOfLines={2}
							renderActions={renderActions}
							headRowFontWeight="bold"
							hiddenKeys="active"
							searchKeys={`${KEY_1},${KEY_2}`}
							searchableData={tableData}
							sortKey={KEY_1}
						/>
					</Card>
				</SectionWithoutBackground>
			)}
		</ContentWrapper>
	);
};

export default CompanyDepartmentVolumeLicensesListView;
