import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../utils/hooks';
import {
	useApiFilteredContents,
	useContentDetails,
	useContentInfoBoxContent,
	useRecommendedApiContents,
} from '../utils/content';
import ContentWrapper from '../components/ContentWrapper';
import IconButton from '../components/IconButton';
import Dialog from '../components/Dialog';
import ContentHeader from '../components/ContentHeader';
import Contents from '../components/Contents';
import ProBannerSmallSection from '../components/ProBannerSmallSection';
import { useCurrentUser } from '../utils/user';
import { useSnackbar } from '../utils/snackBar';
import { useContentButtonProps } from '../utils/button';
import SectionWithoutBackground from '../components/SectionWithoutBackground';
import FavouriteIcon from '../components/FavouriteIcon';

const ContentDetailView = (): JSX.Element | null => {
	const navigate = useNavigate();
	const snackBar = useSnackbar();

	const currentUser = useCurrentUser();

	const isAccessPhraseUser = currentUser?.isAccessPhraseUser;

	const { id } = useParams();
	const content = useContentDetails(id);

	const infoBoxContent = useContentInfoBoxContent(content);
	const contentButtonProps = useContentButtonProps(content);

	const relatedSerie = content?.categories.find((cat) => cat.groupName === 'SERIE');
	const relatedSerieType = relatedSerie?.type;
	const firstRelatedCollection = content?.categories.find((cat) => cat.groupName === 'COLLECTION');
	const firstRelatedCollectionType = firstRelatedCollection?.type;

	const relatedContents = useApiFilteredContents({
		categoryTypeFilters: relatedSerieType || firstRelatedCollectionType,
		contentIsActive: true,
		contentIsContainedInProducts: true,
		contentIsDemo: false,
		skip: Boolean(!relatedSerieType && !firstRelatedCollectionType),
		excludeContentIds: id ? [id] : undefined,
	});

	//  Sort related contents by serieOrder
	const sortedRelatedContents = React.useMemo(() => {
		const sortCopy = relatedContents ? [...relatedContents] : [];
		return sortCopy.sort((a, b) =>
			a.serieOrder?.order && b.serieOrder?.order
				? a.serieOrder?.order > b.serieOrder?.order
					? 1
					: -1
				: a.serieOrder?.order
					? -1
					: b.serieOrder?.order
						? 1
						: 0
		);
	}, [relatedContents]);

	const relatedCategoryId = relatedSerie?.id || firstRelatedCollection?.id;

	const recommendedContents = useRecommendedApiContents(currentUser?.audienceType);

	const handleCopyToClipboard = React.useCallback(() => {
		const link = window.location.href;
		navigator.clipboard.writeText(link);
		snackBar({ success: 'Der Link zum Inhalt wurde in Zwischenablage kopiert' });
	}, [snackBar]);

	const renderContent = React.useCallback(() => {
		return (
			<>
				<FavouriteIcon contentId={id!} m="0 2rem 0 0" />
				<IconButton iconName="Share" onClick={handleCopyToClipboard} m="0 2rem 0 0" />
			</>
		);
	}, [handleCopyToClipboard, id]);

	React.useEffect(() => {
		if ((content && !content.active) || content?.accessibilities?.length === 0) {
			Dialog.render({
				title: 'Produktinhalt inaktiv',
				description:
					'Dieser Produktinhalt ist aktuell nicht verfügbar. Für weitere Informationen kontaktiere bitte den Support.',
				isLocked: true,
				buttons: [
					{
						id: '1',
						label: 'Ok',
						onClick: () => navigate('/'),
					},
				],
			});
		}
	}, [content, navigate]);

	return (
		<ContentWrapper>
			{!content || !contentButtonProps || !sortedRelatedContents ? null : (
				<>
					<ContentHeader
						title={content.title}
						shortDescription={content.shortDescription}
						author={content.author}
						buttonText={contentButtonProps.label}
						onClick={contentButtonProps.onClick}
						imageUrl={content.imageUrl}
						descriptionTitle="Beschreibung"
						description={content.description}
						infoBoxTitle="Allgemeine Infos"
						infoBoxContent={infoBoxContent!}
						renderContent={renderContent}
						cooperations={content.cooperations}
						accessibilities={content.accessibilities}
						learningGoals={content.learningGoals}
						why={content.why}
						who={content.who}
						what={content.what}
						techRequirements={content.techRequirements}
						requirements={content.requirements}
						structure={content.structure}
					/>
					{/* {!isAccessPhraseUser ? (
						<>
							{sortedRelatedContents?.length ? (
								<SectionWithoutBackground>
									<Contents
										contents={sortedRelatedContents}
										title={
											relatedSerie
												? 'Absolviere die komplette Reihe!'
												: 'Absolviere die komplette Kollektion!'
										}
										navigateTo={`/category/${relatedCategoryId}`}
										buttonLabel="Mehr entdecken"
									/>
								</SectionWithoutBackground>
							) : null}
							<SectionWithoutBackground>
								<Contents
									contents={recommendedContents}
									title="Das könnte dir auch gefallen"
									maxItems={6}
									navigateTo="/contents"
									buttonLabel="Mehr entdecken"
								/>
							</SectionWithoutBackground>
							<ProBannerSmallSection />
						</>
					) : null} */}
				</>
			)}
		</ContentWrapper>
	);
};

export default ContentDetailView;
