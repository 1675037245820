/* eslint  no-useless-escape: 0 */

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { LATEST_TERMS_VERSION } from '../consts';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	margin: 0 auto;
	padding: 15px;
`;

const TermsOfServiceView = () => {
	const { version } = useParams();
	let versionText;

	if (version && Object.keys(versions).includes(version)) {
		versionText = versions[version];
	} else {
		versionText = versions[String(LATEST_TERMS_VERSION)];
	}
	return (
		<Wrapper>
			<ReactMarkdown>{versionText}</ReactMarkdown>
		</Wrapper>
	);
};

const versions: { [keys: string]: string } = {
	'1': `
# Allgemeine Geschäftsbedingungen (AGB) 

## Allgemeine Geschäftsbedingungen für Online-Schulungen und Download-Produkte (AGB) unter [bildungsfreaks.de](/) 

### Inhaltsverzeichnis 

	

A.              Allgemeine Regelungen zu den Geschäftsbedingungen 

B.              Allgemeine Regelungen zu der Vertragsbeziehung 

C.              Besondere Bedingungen für Verbraucher 

D.              Schlussbestimmungen 

	

Aus Gründen der besseren Lesbarkeit wird auf die gleichzeitige Verwendung der Sprachformen männlich, weiblich und divers (m/w/d) verzichtet. Sämtliche Personenbezeichnungen gelten gleichermaßen für alle Geschlechter.  

	

A. Allgemeine Regelungen zu den Geschäftsbedingungen 

1. Geltungsbereich, Änderungsbefugnis, Vertragsinhalt, Wechsel des Vertragspartners 

1.1      Diese  Allgemeinen Geschäftsbedingungen (AGB) gelten für Geschäfte der visionYOU GmbH, Stahnsdorfer Straße 107 in 14482 Potsdam sowie seiner Partner (im Folgenden „Anbieter“) mit den Vertragspartnern (im Nachfolgenden „Kunde“) für die Leistungen, die im Edu-Potal unter [bildungsfreaks.de](/) angeboten und bereitgestellt werden. 

1.2      Der Anbieter bietet Kunden die Möglichkeit, Mitarbeiterinnen und Mitarbeiter sowie Lehrkräften, Eltern zu den wichtigsten Grundlagen zu Themen der digitalen Bildung zu schulen und Bildungsmaterialien zu erwerben. Dazu zählen unter anderem aber nicht ausschließlich Schulungen zum Datenschutz, Urheberrecht und zur Medienbildung. 

1.3      Grundsätzlich richten sich die Leistungen an Unternehmen, Schulträger, Schulen bzw. Körperschaften des öffentlichen Rechts. Sofern Einzelpersonen als Verbraucher die Leistungen beanspruchen möchten, gelten ergänzend die Regelungen unter C. Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann (§ 13 BGB). 

1.4      Diese AGB sind wesentlicher Bestandteil jedes abgeschlossenen Vertrages. Sie gelten auch für die zukünftigen Geschäftsbeziehungen mit dem Kunden, ohne dass ihre erneute ausdrückliche Einbeziehung erforderlich ist. 

Grundsätzlich gelten in der aufgezählten Reihenfolge die nachfolgenden vertragswesentlichen Regelungen: 

- Einzelvertrag (insb. Angebot und Annahme); 

- die vorliegenden AGB und 

- die Allgemeinen Nutzungsbedingungen ANB. 

	

Die jeweils gültige Fassung ist im Internet unter der Internetadresse [bildungsfreaks.de](/terms/) einseh- und jederzeit abrufbar. Der Kunde hat dabei auch die Möglichkeit, die AGB sowie ANB auszudrucken oder zu speichern (pdf-Datei). 

1.5      Abweichende Geschäftsbedingungen des Kunden sowie Änderungen und Ergänzungen haben nur Gültigkeit, soweit sie vom Anbieter anerkannt sind. Dies gilt auch, wenn den Allgemeinen Geschäftsbedingungen des Kunden nicht ausdrücklich widersprochen worden ist. 

1.6      Der Anbieter wird das Online-Angebot auf bildungsfreaks.de weiterentwickeln, um ein für seine Nutzer relevantes Inhalteangebot und neue Funktionen zur Verfügung zu stellen. Soweit sich durch Änderungen an der Plattform der bei Abschluss der jeweiligen Mitgliedschaft bestehende Gesamtcharakter der Plattform, insbesondere hinsichtlich der angebotenen Themen, des Gesamtumfangs der angebotenen Inhalte oder der Funktionalitäten ändern sollte, ist ein Nutzer berechtigt, sein Konto durch Erklärung in Textform (z.B. E-Mail) zu kündigen. 

1.7      Künftige Änderungen dieser Nutzungsbedingungen werden dem Nutzer spätestens sechs Wochen vor dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens in Textform (z. B. per E-Mail) angeboten. Die Zustimmung durch den Nutzer gilt als erteilt (Zustimmungsfiktion), wenn dieser seine Ablehnung nicht vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der Änderungen gegenüber dem Anbieter in Textform anzeigt. Auf das Widerspruchsrecht und die Rechtsfolgen des Schweigens wird der Nutzer im Falle der Änderung der Nutzungsbedingungen gesondert hingewiesen. Mit Ausnahme von Änderungen, die zum Zwecke der Umsetzung zwingender Änderungen geltender Gesetze und Verordnungen oder der höchstrichterlichen Rechtsprechung erfolgen (einschließlich von Änderungen der Höhe der gesetzlichen Umsatzsteuer), findet die vorstehende Zustimmungsfiktion keine Anwendung (a) bei Änderungen, die die Hauptleistungspflichten des Vertrags und die Entgelte für Hauptleistungspflichten betreffen, (b) bei Änderungen von Entgelten, die auf eine über das vereinbarte Entgelt für die Hauptleistung hinausgehende Zahlung des Nutzers gerichtet sind, (c) bei Änderungen, die dem Abschluss eines neuen Vertrages gleichkommen, oder (d) bei Änderungen, die das bisher vereinbarte Verhältnis von Leistung und Gegenleistung erheblich zugunsten des Anbieters verschieben würden; in diesen Fällen wird visionYOU die Zustimmung des Nutzers zu den Änderungen auf andere Weise einholen. 

1.8      Der Anbieter behält sich das Recht vor, kostenlos zur Verfügung stellte Konten jederzeit ohne Angabe von Gründen durch Erklärung in Textform zu kündigen. 

B.               Allgemeine Regelungen zu der Vertragsbeziehung 

2. Vertragsangebot, Vertragsschluss und Vertragsanpassungen 

2.1      Jedwede Präsentationen und sonstigen Leistungsbeschreibungen insbesondere unter dem Internetauftritt [bildungsfreaks.de](/) sind unverbindlich. 

2.2      Die wesentlichen vertraglichen Regelungen sind in einem Einzelvertrag festzuhalten. Der Kunde gibt die gewünschte Leistung (Anzahl der Nutzer, voraussichtlicher Leistungszeitraum etc.) sowie die notwenigen Daten an. Das kann dem Anbieter übermittelt werden. Hat ein Dritter (insb. Vertriebspartner vom Anbieter) beim Vertragsschluss mitgewirkt, erkennt der Anbieter Einwendungen des Kunden nicht an, die der Kunde aus einem zusätzlichen Vertragsverhältnis mit dem Dritten herleitet. 

2.3      Vor der Abgabe des Angebotes besteht für den Kunden die Möglichkeit sämtliche Angaben nochmals zu überprüfen, ändern bzw. zu korrigieren, um insbesondere Eingabefehler zu erkennen bzw. zu berichtigen. 

2.4      Ein Vertrag kommt erst zu Stande, wenn der Anbieter das Angebot des Kunden vorbehaltlos angenommen hat oder mit den geschuldeten Erfüllungshandlungen (z.B. Bereitstellung der konkreten Leistungen) beginnt. Der Kunde verzichtet in letzterem Fall auf den Zugang der Annahmeerklärung. 

2.5      Dem Anbieter sind unverzüglich alle für die Geschäftsbeziehung wesentlichen Tatsachen anzuzeigen, insbesondere Änderungen des Namens, der Anschrift, des Gegenkontos, der Verfügungs- oder Verpflichtungsfähigkeit des Kunden oder der für ihn vertretungsberechtigten Personen sowie bekannt gegebenen Vertretungs- oder Verfügungsbefugnisse (insb. Ansprechpartner). Unterlässt der Kunde die Mitteilung der Änderung seiner Vertragsdaten schuldhaft, hat er die Kosten für die Ermittlung der zur Ausführung des Vertragsverhältnisses notwendigen Daten zu tragen. 

2.6      Sollte sich herausstellen, dass der Kunde dem Anbieter fehlerhafte und/oder unvollständige Informationen (z.B. zur Erfüllung der Nutzungsvoraussetzungen) über die Erbringung der vertragsgegenständlichen Leistungen mitgeteilt hat oder trotz deren Anforderung wesentliche Informationen über die Erbringung der vertragsgegenständlichen Leistungen nicht vollumfänglich oder teilweise zugänglich gemacht hat und der Anbieter als Folge der mangelhaften Informationsbeschaffung Mehrkosten entstehen, die vor Vertragsschluss nicht absehbar waren, ist der Anbieter berechtigt, Nachverhandlungen mit dem Ziel einer angemessenen und dem Kunden zumutbaren Anpassung der Vergütung und/oder Leistungsbeschreibung zu fordern. Sollten sich die Vertragsparteien nicht einigen können, ist der Anbieter berechtigt die vertragsgegenständlichen Leistungen auszusetzen und/oder den Vertrag ganz oder teilweise aus wichtigem Grund zu kündigen. 

3. Allgemeine Leistungspflichten 

3.1      Der Kunde erhält vom Anbieter Zugang zu der Onlineschulung bzw. zu den Download-Produkten. Einzelheiten des konkreten Nutzungsumfanges sind gemeinsam abzustimmen. 

3.2      Die Dienstleistungen werden grundsätzlich über die Plattform bildungsfreaks erbracht. Eine Einbindung in ein kundeninternes Lernmanagementsystem ist mit vorheriger Zustimmung des Anbieters möglich. 

3.3      Der Kunde verpflichtet sich alle Nutzer gesondert auf die Einhaltung der Nutzungsbestimmungen (vgl. [bildungsfreaks.de](/terms/) hinzuweisen sowie die Einhaltung der Vorgaben sicherzustellen. 

3.4      Der Kunde hat dem Anbieter alle zur Ausführung der Leistungen erforderlichen Informationen und Unterlagen (insbesondere sofern angefordert Schulungsteilnehmer) rechtzeitig mitzuteilen bzw. zur Verfügung zu stellen, etwa vereinbarte Anzahlungen vereinbarungsgemäß zu zahlen, Genehmigungen und Freigaben zu erteilen sowie sonst erforderliche Mitwirkungshandlungen vorzunehmen. 

3.5      Leistungstermine und -fristen sind nur verbindlich, wenn sie vom Anbieter bestätigt worden sind. Der Kunde verpflichtet sich, die Notwendigkeit etwaiger Terminverschiebungen rechtzeitig mitzuteilen, um den Anbieter eine entsprechende Disponierung zu ermöglichen. 

3.6      Sind zur Herstellung der Leistungsbereitschaft/ Gebrauchstauglichkeit der von dem Anbieter geschuldeten Leistung Mitwirkungshandlungen des Kunden erforderlich (beispielhaft die Aufrechterhaltung und Kompatibilität von IT-Hardware um den Zugriff auf die Webseite zu ermöglichen, so werden diese Leistungen von dem Anbieter nicht geschuldet. 

3.7      Der Anbieter weist den Kunden ausdrücklich darauf hin, dass der Datenschutz für Datenübertragungen in „offenen Netzen“, wie dem Internet, nach dem derzeitigen Stand der Technik nicht umfassend gewährleistet werden kann. Der Kunde weiß, dass der Anbieter Verkehrs- und Bestandsdaten des Kunden aus technischer Sicht jederzeit einsehen kann. Auch Dritte sind unter Umständen technisch in der Lage, unbefugt in die Netzsicherheit einzugreifen und den Datenverkehr einzusehen. Für die Sicherheit und die Sicherung der gespeicherten Daten ist der Kunde verantwortlich. 

3.8      Der Anbieter kann den Zugang zu den Leistungen (insbesondere den Zugriff auf die Webseite) vorübergehend einstellen oder beschränken, sofern die Sicherheit des Netzbetriebes, die Aufrechterhaltung der Netzintegrität, insbesondere die Vermeidung schwerwiegender Störungen des Netzes, der Interoperabilität der Leistungen und/oder datenschutzrechtliche Anforderungen dies erfordern. 

3.9      Die Leistungen des Anbieters entbinden den Kunden nicht von seiner Pflicht, die üblichen und anerkannten Sicherheitsstandards einzuhalten, wie z. B. die Verwendung von regelmäßig aktualisierten Anti-Viren-Programmen, eine Plausibilitätsprüfung bei eingehenden Daten, die Datensicherung (es sei denn der Anbieter hat diese Leistungen für den Kunden übernommen) sowie die regelmäßige Änderung von Passwörtern und eine übliche Zugangs- und Zugriffskontrolle. 

3.10    Unvorhersehbare, unvermeidbare und außerhalb des Einflussbereichs des Anbieters liegende und nicht zu vertretende Ereignisse wie höhere Gewalt entbinden diesen für deren Dauer von der Pflicht zur Leistung. Vereinbarte Leistungsfristen verlängern sich um die Dauer der Störung; vom Eintritt der Störung wird der Kunde in angemessener Weise unterrichtet. 

3.11    Der Kunde stellt den Anbieter von allen Ansprüchen Dritter (einschließlich der Kosten der Rechtsverfolgung) frei, welches aufgrund von Verstößen gegen die vorstehenden Pflichten bzw. die Allgemeinen Nutzungsbedingungen für Teilnehmer bestehen bzw. geltend gemacht werden. 

4. Zugriff und Mindestalter 

4.1      Für einige Aktivitäten auf bildungsfreaks.de, wie den Zugriff auf bestimmte Inhalte, muss der Nutzer ein Konto anlegen. Nutzer müssen zur Einrichtung eines Kontos auf bildungsfreaks.de und zur Nutzung der Dienste mindestens 18 Jahre alt sein. Wenn der Nutzer jünger als 18 Jahre ist, aber das erforderliche Mündigkeitsalter für die Nutzung von Online-Diensten an seinem Wohnort bereits erreicht hat (ab 16 Jahren je nach landesrechtlichen Vorgaben), darf der Nutzer kein Konto einrichten. Er kann jedoch ein Elternteil oder einen Erziehungsberechtigten darum bitten, ein Konto zu eröffnen und ihn beim Zugriff auf Inhalte zu helfen, der für den Nutzer geeignet ist. Wenn der Nutzer das für die Nutzung der Online-Dienste erforderliche Mündigkeitsalter noch nicht erreicht hat, darf er kein Konto auf bildungsfreaks.de einrichten. Wenn wir feststellen, dass ein Nutzer ein Konto unter Verstoß gegen diese Bestimmungen eingerichtet hat, werden wir das Konto schließen. 

5. Lizenzvereinbarungen, Urheber- und Nutzungsrechte 

5.1      Der Kunde darf die Leistungen des Anbieters für eigene Zwecke nutzen. Dritten darf er die Leistungen nur dann zur Verfügung stellen, wenn der Anbieter schriftlich eingewilligt hat. 

5.2      Der Kunde erwirbt bei einer Bereitstellung der Leistungen durch den Anbieter keine Eigentumsrechte. 

5.2.1   Ergänzend zu den nachfolgenden Regelungen gelten die Allgemeinen Nutzungsbedingungen (ANB) des Anbieters. Der Kunde stellt sicher, dass alle Nutzer die Vorgaben der ANB beachten und einhalten. 

5.2.2   Dem Kunden werden  

- nicht ausschließliche, 

- auf die Durchführung der Online-Angebote beschränkte, 

- nicht übertragbare sowie nicht unterlizenzierbare, Rechte eingeräumt die Leistungen 

- zu nutzen, dass heißt sie ablaufen zu lassen und zu diesem Zweck zu speichern. 

5.2.3   Die vorstehende Rechtseinräumung umfasst nicht die Leistungen zu eigenen Zwecken oder für Dritte zur Bearbeitung, Änderung (sowie sonstige Umgestaltung), Vervielfältigung, Veröffentlichung und sonstige Verbreitung und Verwertung jedweder Art Dritten zugänglich zu machen sowie nicht das Recht, die Nutzungsrechte zu übertragen und zeitlich und inhaltlich beschränkte oder unbeschränkte Unterlizenzen zu erteilen. 

5.2.4   Abweichend von den Regelungen der Ziffern 5.1 sowie 5.2.3 kann die Nutzung, Weitergabe und / oder Bearbeitung einzelner Inhalte ausdrücklich erlaubt sein, sofern sie mit einer entsprechenden CC-Lizenz versehen sind. Solche Inhalte enthalten einen Hinweis mit der jeweiligen CC-Lizenz, inklusive Link zur Lizenz. 

5.2.5.  Der Kunde darf Datensicherungen nach den Regeln der Technik betreiben. Der Kunde ist verpflichtet, Urheberrechtsvermerke des Anbieters oder Dritten weder zu verändern noch zu entfernen. Er ist nicht berechtigt, die vertragsgegenständlichen Leistungen in anderer Weise als in den Bestimmungen beschrieben zu nutzen, zu kopieren, zu bearbeiten, zu übertragen, in eine andere Ausdrucksform umzuwandeln (insbesondere Reverse Engineering oder Dekompilieren) oder in anderer Weise zu übersetzen, sofern eine solche Umwandlung nicht durch ausdrückliche gesetzliche Regelungen unabdingbar vorgesehen ist. 

5.3      Der Anbieter ist berechtigt, die Übereinstimmung der tatsächlichen Nutzung der von Ihr gelieferten bzw. zur Verfügung gestellten vertragsgegenständlichen Leistungen beim Kunden zu überprüfen. 

6. Schutzrechtsverletzung/ Freistellungsanspruch 

6.1      Macht ein Dritter gegenüber dem Kunden Ansprüche wegen der Verletzung eines gewerblichen Schutzrechts oder Urheberrechts durch die Nutzung der geschuldeten Leistungen des Anbieters geltend und wird deren Nutzung hierdurch beeinträchtigt oder untersagt, gelten die nachfolgenden Bestimmungen. 

6.2      Der Anbieter wird nach seiner Wahl und auf seine Kosten entweder die vereinbarten Leistungen so ändern oder ersetzen, dass sie das Schutzrecht nicht verletzen, aber im Wesentlichen der vereinbarten Leistung in für den Kunden zumutbarer Weise entsprechen oder den Kunden von Lizenzentgelten gegenüber dem Schutzrechtsinhaber oder Dritten freistellen. 

6.3      Voraussetzungen für die Haftung nach Ziffer 6.2 sind, dass der Kunde den von der Geltendmachung von Ansprüchen Dritter unverzüglich verständigt, die behauptete Schutzrechtsverletzung nicht anerkennt und jegliche Auseinandersetzung einschließlich etwaiger außergerichtlicher Regelungen den Anbieter überlässt oder nur im Einvernehmen mit dieser führt. Stellt der Kunde die Nutzung aus Schadensminderungs- oder sonstigen wichtigen Gründen ein, ist er verpflichtet, den Dritten darauf hinzuweisen, dass mit der Nutzungseinstellung ein Anerkenntnis der behaupteten Schutzrechtsverletzung nicht verbunden ist. 

6.4      Soweit der Kunde die Schutzrechtsverletzung zu verschulden hat, der Anspruch Dritter darauf beruht, dass der vom Anbieter geschuldete Leistungsinhalt ohne deren Kenntnis geändert, auf eine sonstige Art und Weise bearbeitet und nicht mit vom zur Verfügung gestellten Leistungen genutzt wurde, sind Ansprüche gegen den Anbieter ausgeschlossen. 

6.5      Soweit anwendbar bleiben gesetzlich zwingende Haftungsregelungen bzw. 7. hiervon unberührt. 

7. Haftung 

7.1      Bei Verletzungen der Allgemeinen Nutzungsbedingungen haftet der Kunde grundsätzlich nach den allgemeinen Haftungsregeln. 

7.2      Der Anbieter haftet, gleich aus welchem Rechtsgrund, auf Schadenersatz oder Ersatz vergeblicher Aufwendungen in voller Höhe nur für Schäden des Kunden durch vorsätzliches oder grob fahrlässiges Verhalten, arglistigem Verschweigen eines Mangels, im Fall der Übernahme ausdrücklicher Garantien sowie zugesicherten Eigenschaften der Beschaffenheit und/oder Haltbarkeit, bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, für Ansprüche aus Produkthaftung sowie im Fall zwingender gesetzlicher Regelungen. 

7.3      Bei der fahrlässigen Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) haftet der Anbieter - unbeschadet der in Absatz 2 genannten Fälle - nur begrenzt auf den vertragstypischen, bei Vertragsschluss vernünftigerweise vorhersehbaren Schaden. Bei Kardinalpflichten handelt es sich um Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglichen, deren Verletzung die Erreichung des Vertragszweckes gefährdet und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf. 

7.4      Mehrere Schadensfälle, die die gleiche Schadensursache haben, gelten als ein Schadensereignis (Fortsetzungszusammenhang/ Tateinheit). Der Anbieter haftet, unbeschadet der in 7.1 und 7.2 genannten Fälle, nicht für entgangenen Gewinn, mittelbare Schäden, Mangelfolgeschäden und Ansprüche Dritter mit Ausnahme von Ansprüchen aus der Verletzung von Schutzrechten Dritter sowie für auftretende Mängel, die im Zusammenhang mit einer durch den Kunden vorgenommenen oder sonst veranlassten Änderung der Leistungen des Anbieters oder sonstigen Fremdeinflüssen stehen und die aus dem Risikobereich des Kunden stammen. Es obliegt dem Kunden nachzuweisen, dass auftretende Mängel nicht kausal auf einer Änderung der Systemumgebung oder sonstigen Fremdeinflüssen beruhen. 

7.5      Für den Verlust von Daten oder Programmen haftet der Anbieter, unbeschadet der in 7.1 und 7.2 genannten Fälle, lediglich bis zu derjenigen Schadenshöhe die auch bei regelmäßiger Datensicherung eingetreten wäre. Die vorstehende Haftungsbegrenzung gilt mithin insbesondere, als der Schaden darauf beruht, dass der Kunde es unterlassen hat, regelmäßige Datensicherungen durchzuführen und dadurch sicherzustellen, dass verlorengegangene Daten mit vertretbarem Aufwand wiederhergestellt werden können, es sei denn der Anbieter hat die Datensicherung für den Kunden übernommen. 

7.6      Der Anbieter stellt die vertragsgegenständlichen Leistungen zur Nutzung durch den Kunden lediglich zur Verfügung und haftet nicht für Schäden, die dem Kunden durch die Nutzung dieser (Zweckverfehlung – bzw. Erreichung eines Schulungserfolges) entstehen. 

7.7      Im Übrigen ist die Haftung des Anbieters für Sach- und Vermögensschäden (insbesondere entgangenen Gewinn, vergebliche Aufwendungen, mittelbare Schäden sowie Mangelfolgeschäden) ausgeschlossen. Soweit anwendbar bleiben gesetzlich zwingende Haftungsregelungen hiervon unberührt. 

7.8      Soweit die Haftung der Anbieter gegenüber dem Kunden beschränkt oder ausgeschlossen ist, gilt dies entsprechend für gesetzliche Vertreter, Arbeitnehmer, freie Mitarbeiter und sonstige Erfüllungsgehilfen der Anbieter. 

8. Verjährung 

8.1      Nach den gesetzlichen Vorschriften verjähren Ansprüche beruhend auf einem vorsätzlichen oder grob fahrlässigen Handeln von Anbieter, eines gesetzlichen Vertreters oder Erfüllungsgehilfen von Anbieter sowie Ansprüche für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. 

8.2      Für alle übrigen vertraglichen und gesetzlichen Ansprüche gegenüber Anbieter beträgt die Verjährungsfrist ein (1) Jahr. Die gleiche Frist gilt für sonstige Gewährleistungsrechte des Kunden. 

9. Datenschutz und Vertraulichkeit** 

9.1      Der Anbieter verpflichtet sich alle vertraulichen Informationen zeitlich unbegrenzt vertraulich zu behandeln und nur im Rahmen der vereinbarten Zweckbestimmung zu verwenden sowie die geltenden Bestimmungen des Datenschutzes und der Datensicherheit zu wahren. 

9.2      Im Rahmen der Abwicklung von Zahlungen, wie auch der Leistungen, können personenbezogene Daten des Kunden an die jeweiligen Empfänger zweckgebunden weitergegeben und durch diese verarbeitet werden. Eine Nutzung für fremde Geschäftszwecke (z.B. Werbung und Adresshandel) erfolgt nicht. 

C. Besondere Bedingungen für Verbraucher 

10.1    Der Anbieter verpflichtet sich alle vertraulichen Informationen zeitlich unbegrenzt vertraulich zu behandeln und nur im Rahmen der vereinbarten Zweckbestimmung zu verwenden sowie die geltenden Bestimmungen des Datenschutzes und der Datensicherheit zu wahren. 

10.2    Verbrauchern (vgl. Punkt 1.3) steht es frei, die Vertragserklärung nach den in der Widerrufserklärung niedergelegten Bedingungen vgl. 10.4 zu widerrufen. 

10.3    Der Kunde stimmt ausdrücklich zu, dass der Anbieter mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt und dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert. 

10.4    Widerrufserklärung  
	
Widerrufsbelehrung   

Widerrufsrecht 

Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen in Textform (z.B. Brief, E-Mail) widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in Textform, jedoch nicht vor Vertragsschluss und auch nicht vor Erfüllung unserer Informationspflichten gemäß Artikel 246 § 2 in Verbindung mit § 1 Absatz 1 und 2 EGBGB sowie unserer Pflichten gemäß § 312g Absatz 1 Satz 1 BGB in Verbindung mit Artikel 246 § 3 EGBGB. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs. 

Der Widerruf ist zu richten an den Anbieter: 

visionYOU GmbH, Stahnsdorfer Straße 107 in 14482 Potsdam 

E-Mail: kontakt@bildungsfreaks.de 

	

Widerrufsfolgen 

Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und ggf. gezogene Nutzungen (z.B. Zinsen) herauszugeben. Können Sie uns die empfangene Leistung sowie Nutzungen (z.B. Gebrauchsvorteile) nicht oder teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren beziehungsweise herausgeben, müssen Sie uns insoweit Wertersatz leisten. Dies kann dazu führen, dass Sie die vertraglichen Zahlungsverpflichtungen für den Zeitraum bis zum Widerruf gleichwohl erfüllen müssen. Verpflichtungen zur Erstattung von Zahlungen müssen innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für Sie mit der Absendung Ihrer Widerrufserklärung, für uns mit deren Empfang. 

Besondere Hinweise 

Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von beiden Seiten auf Ihren ausdrücklichen Wunsch vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben. 

Ende der Widerrufsbelehrung 

	

Muster-Widerrufsformular 

Wenn Sie den Vertrag nach Maßgabe der vorstehenden Wiederrufsbelehrung widerrufen wollen, dann können Sie dieses Formular ausfüllen und an uns zurücksenden. Die Verwendung des Formulars ist aber nicht zwingend. 

	

An … 

	

Hiermit widerrufe(n) ich/wir (\*) den von mir/uns (\*) abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung: 

Bestellt am (\*) 

Name des/der Verbraucher(s) 

Anschrift des/der Verbraucher(s) 

Unterschrift des/der Verbraucher(s) (n ur bei Mitteilung auf Papier) 

Datum 

	(*) Unzutreffendes streichen. 

	

D. Schlussbestimmungen 

11. Schlussbestimmungen 

11.1    Änderungen oder Ergänzungen der Bedingungen bedürfen der Schriftform. Sollte der Anbieter nicht auf der vollständigen und/oder teilweisen Einhaltung bzw. Erfüllung einer der Bedingungen oder Bestimmungen sowie der ergänzenden Regelungen bestehen, ist dies nicht als Anerkenntnis der Verletzungshandlung bzw. Verzicht auf eine künftige Anwendung der betreffenden Bedingung, Bestimmung, Option, des betreffenden Rechts oder Rechtsbehelfs zu verstehen. 

11.2    Der Kunde kann gegenüber Vergütungsansprüchen des Anbieters nur mit rechtskräftig festgestellten oder von Anbieter anerkannten Forderungen aufrechnen. 

11.3    Die Abtretung oder Verpfändung von dem Kunden gegenüber dem Anbieter zustehenden Ansprüchen oder Rechten ist ohne Zustimmung der Anbieter ausgeschlossen. 

11.4    Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des internationalen Privatrechts (insbesondere des UN-Kaufrechtes – United Nations Convention on Contracts for the International Sale of Goods, CISG bzw. Kollisionsrechtes, IPR). 

Erfüllungsort (sowie Gerichtsstand für den Fall das der Kunde Kaufmann, juristische Personen des öffentlichen Rechts oder öffentlich-rechtliche Sondervermögen ist) für alle Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist der Sitz von Anbieter. Der Anbieter ist darüber hinaus berechtigt, den Kunden an seinem allgemeinen Gerichtsstand zu verklagen. Ein etwaiger ausschließlicher Gerichtsstand bleibt unberührt. 

11.5    Die Nichtigkeit, Undurchsetzbarkeit oder Unwirksamkeit einzelner Bestimmungen der Bedingungen, auch sofern diese später aufgenommen oder in einem Nachtrag geregelt werden, berührt die Gültigkeit der übrigen Bedingungen nicht. Anstelle der unwirksamen, nichtigen oder undurchsetzbaren Bedingung gilt eine Bedingung als vereinbart, die, soweit rechtlich möglich, dem am nächsten kommt, was nach dem Sinn und Zweck der unwirksamen, nichtigen oder undurchsetzbaren Bedingungen wirtschaftlich gewollt ist. Gleiches gilt für unbeabsichtigte Regelungslücken; in einem solchem Fall gilt eine Bedingung als vereinbart, die dem am nächsten kommt, was nach dem Sinn und Zweck des vorliegenden Vertrages geregelt worden wäre, wenn die Parteien von der Regelungslücke gewusst hätten; oder sollte eine Bedingung hinsichtlich einer Zeitspanne oder eines festgelegten Verhaltens unwirksam sein. 

	

	

# Allgemeine Nutzungsbedingungen für Online-Angebote auf [bildungsfreaks.de](/) (ANB) 

Aus Gründen der besseren Lesbarkeit wird auf die gleichzeitige Verwendung der Sprachformen männlich, weiblich und divers (m/w/d) verzichtet. Sämtliche Personenbezeichnungen gelten gleichermaßen für alle Geschlechter. 

1. Allgemeiner Geltungsbereich der ANB 

1.1      Diese Allgemeinen Nutzungsbedingungen (ANB) gelten für die Online-Angebote (u. a. Schulungen und Download-Produkte) der visionYOU GmbH mit den Nutzern auf [bildungsfreaks.de](/) 

1.2      Die visionYOU GmbH, Stahnsdorfer Straße 107 in 
	14482 Potsdam ermöglicht gemeinsam mit Partnern Mitarbeiterinnen und Mitarbeiter sowie Lehrkräften zu den wichtigsten Grundlagen zu Themen der digitalen Bildung zu schulen und Bildungsmaterialien zu erwerben. 

1.3      Die ANB sind wesentlicher Bestandteil der Zurverfügungstellung der Nutzung. Die jeweils gültige Fassung ist im Internet unter der Internetadresse [bildungsfreaks.de](/terms/) einsehbar. Der Nutzer hat dabei auch die Möglichkeit, die ANB auszudrucken oder zu speichern. 

2. Leistungsinanspruchnahme / Abgrenzungen 

2.1      Der Nutzer erhält vom Anbieter den Zugang zu der Onlineschulung bzw. zu den interaktiven Programmen sowie Download-Produkten. Es steht ihm frei, dass den ihm zugeteilte Zugangsrecht zeitlich flexibel zu nutzen und die Schulung für die Dauer des vereinbarten Nutzungszeitraums (auch mehrfach) zu absolvieren.  

2.2      Die Dienstleistungen werden grundsätzlich auf der Plattform bildungsfreaks.de der visionYOU erbracht. Eine Herausgabe der Schulungsdatei ist nicht möglich. 

2.3      Die konkreten Nutzungsdaten des Nutzers werden bei dem Anbieter gespeichert und ausschließlich im Rahmen der vorgegeben Zweckbestimmung verarbeitet. Der Nutzer kann unter kontakt@bildungsfreaks.de mit dem Service des Anbieters in Verbindung treten. 

2.4       Sind zur Herstellung der Leistungsbereitschaft / Gebrauchstauglichkeit der von dem Anbieter geschuldeten Leistung Mitwirkungshandlungen des Nutzers erforderlich (beispielhaft die Aufrechterhaltung und Kompatibilität von IT-Hardware um den Zugriff auf die Webseite zu ermöglichen), so werden diese Leistungen von dem Anbieter nicht geschuldet. 

2.5      Der Anbieter weist den Nutzer ausdrücklich darauf hin, dass der Datenschutz für Datenübertragungen in „offenen Netzen“, wie dem Internet, nach dem derzeitigen Stand der Technik nicht umfassend gewährleistet werden kann. Der Nutzer weiß, dass der Anbieter Verkehrs- und Bestandsdaten des Nutzers aus technischer Sicht jederzeit einsehen kann. Auch Dritte sind unter Umständen technisch in der Lage, unbefugt in die Netzsicherheit einzugreifen und den Datenverkehr einzusehen. Für die Sicherheit und die Sicherung der gespeicherten Daten ist der Nutzer verantwortlich. 

2.6      Der Anbieter kann den Zugang zu den Leistungen (insbesondere den Zugriff auf die Webseite) vorübergehend einstellen oder beschränken, sofern die Sicherheit des Netzbetriebes, die Aufrechterhaltung der Netzintegrität, insbesondere die Vermeidung schwerwiegender Störungen des Netzes, der Interoperabilität der Leistungen und/oder datenschutzrechtliche Anforderungen dies erfordern. 

2.7       Unvorhersehbare, unvermeidbare und außerhalb des Einflussbereichs des Anbieters liegende und nicht zu vertretende Ereignisse wie höhere Gewalt entbinden diesen für deren Dauer von der Pflicht zur Leistung. Vereinbarte Leistungsfristen verlängern sich um die Dauer der Störung; vom Eintritt der Störung wird der Nutzer in angemessener Weise unterrichtet. 

3. Allgemeine Pflichten des Nutzers 

3.1       Der Nutzer verpflichtet sich, vom Anbieter zum Zwecke des Zugangs zu deren Leistungen ggf. erhaltene Passwörter bzw. Zugangsschlüssel streng geheim zu halten, den Anbieter unverzüglich zu informieren, sobald er davon Kenntnis erlangt, dass unbefugten Dritten das Passwort bzw. der Zugangsschlüssel bekannt ist sowie unverzüglich zu ändern oder durch den Anbieter ändern zu lassen, wenn er Anlass zu der Vermutung hat, dass unberechtigte Dritte hiervon Kenntnis erlangt haben. 

Sollten infolge des Verschuldens des Nutzers Dritte durch Missbrauch der Zugangsdaten Leistungen des Anbieters nutzen, haftet der Nutzer unter anderem für die Vergütung als auch daraus erwachsende Schadensersatzansprüche. 

Durch die von ihm im Zusammenhang mit der Leistungserbringung durch den Anbieter veranlassten Maßnahmen darf der Nutzer nicht gegen gesetzliche Verbote, die guten Sitten und Rechte Dritter (z.B. Marken, Namens-, Urheber-, Datenschutzrechte usw.) verstoßen. 

3.3       Es obliegt dem Nutzer, adäquate Datensicherungen durchzuführen und die Leistungsumgebung bzw. IT-Systeme ordnungsgemäß zu pflegen und zu warten, soweit dies nicht Bestandteil der vom Anbieter zu erbringenden vertragsgegenständlichen Leistungen ist. 

3.4       Die Leistungen des Anbieters entbinden den Nutzer nicht von seiner Pflicht, die üblichen und anerkannten Sicherheitsstandards einzuhalten, wie z. B. die Verwendung von regelmäßig aktualisierten Anti-Viren-Programmen, eine Plausibilitätsprüfung bei eingehenden Daten, die Datensicherung (sowie die regelmäßige Änderung von Passwörtern und eine übliche Zugangs- und Zugriffskontrolle. 

4. Nutzungsrechte- und Nutzungsumfang 

4.1       Der Anbieter ist Eigentümer der bildungsfreaks Plattform und Dienste. Dazu gehört auch der von unseren Mitarbeitern erstellte Content. Ohne Befugnis dürfen diese nicht manipuliert oder verwendet werden. 

4.2       Dem Nutzer werden   

nicht ausschließliche, 

auf die Durchführung der Onlineschulung für die Dauer des vereinbarten Leistungszeitraums beschränkte, 

nicht übertragbare sowie nicht unterlizenzierbar Rechte eingeräumt, die Leistungen 

zu nutzen, das heißt, sie ablaufen zu lassen und zu diesem Zweck zu speichern. 

4.2.1    Der Nutzer darf die Leistungen nicht kopieren, modifizieren oder davon abgeleitete Werke schaffen, ausgenommen (i) soweit ihm dies aus­drücklich vom Anbieter gestattet wurde oder (ii) soweit dies erforderlich ist, um die Leistungen bestimmungsgemäß zu nutzen. 

4.2.2    Es ist dem Nutzer nicht gestattet, die Leistungen des Anbieters als eigenständige Leistungen gegenüber Dritten zu erbringen. Es ist im Weiteren nicht erlaubt, (i) Unterlizenzen an den Leistungen zu erteilen (insbesondere die Übertragung oder Weitereinräumung einer personenbezogenen Lizenz in einer Weise, dass mehrere Nutzer die Lizenz unter Überschreitung der Anzahl der gewährten Lizenzen gemeinsam nutzen, (ii) sie  zu gewerblichen Zwecken an Dritte zu vermieten oder zu verleasen oder (iii) die Rechte zur Nutzung der Leistungen (insbesondere das Recht zur Vervielfältigung) ohne vorherige schriftliche Zustim­mung des Anbieters, die nicht entgegen den Grundsätzen von Treu und Glauben verweigert werden darf, direkt oder indirekt an andere natürliche oder juristische Personen ab­zutreten oder auf andere Weise zu übertragen; soweit dies nicht ausdrücklich geregelt ist (iv) die Leistungen zum Benchmarking, Erfassen oder zur Veröffentlichung von Daten oder Analysen im Zusammenhang mit der Leistung zu benutzen oder ein Produkt zu entwickeln, das leistungsgegenständlichen Inhalten konkurriert; 

4.2.3    Der Nutzer darf die Leistungen bzw. Leistungsteile (insbesondere die vorhandenen Dateien bzw. Daten) nicht zurückübersetzen [reverse engineering oder disassembling], dekompilieren oder auf andere Weise versu­chen, eigenständige Leistungen daraus abzuleiten. 

4.3       In sämtlichen Kopien der Leistungen, die er in Einhaltung der hier geregelten Beschränkungen anfertigt, und in allen Bearbeitungen derselben hat der Nutzer die Hinweise auf Urheberrechte, Marken oder sonstige geschützte Rechte der Anbieter wiederzugeben und mit aufzunehmen. 

4.4      Content (z. B. Materialien, Bilder oder Kurse), der unter einer CC-Lizenz steht, darf abweichend von den Ziffern 4.1 bis 4.3 entsprechend seiner eingeräumten Nutzungsrechte verwendet werden. Dieser Content ist sichtbar mit einem Hinweis zur CC-Lizenz inkl. Link zur Lizenz zur Einsicht der Nutzungsrechte gekennzeichnet. 

5. Schlussbestimmungen 

5.1      Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des internationalen Privatrechts (insbesondere des UN-Kaufrechtes – United Nations Convention on Contracts for the International Sale of Goods, CISG bzw. Kollisionsrechtes, IPR). 

5.2      Die Nichtigkeit, Undurchsetzbarkeit oder Unwirksamkeit einzelner Bestimmungen der Bedingungen, auch sofern diese später aufgenommen oder in einem Nachtrag geregelt werden, berührt die Gültigkeit der übrigen Bedingungen nicht. Anstelle der unwirksamen, nichtigen oder undurchsetzbaren Bedingung gilt eine Bedingung als vereinbart, die, soweit rechtlich möglich, dem am nächsten kommt, was nach dem Sinn und Zweck der unwirksamen, nichtigen oder undurchsetzbaren Bedingungen wirtschaftlich gewollt ist. Gleiches gilt für unbeabsichtigte Regelungslücken; in  einem solchem Fall gilt eine Bedingungen als vereinbart, die dem am nächsten kommt, was nach dem Sinn und Zweck des vorliegenden Vertrages geregelt worden wäre, wenn die Parteien von der Regelungslücke gewusst hätten; oder sollte eine Bedingung hinsichtlich einer Zeitspanne oder eines festgelegten Verhaltens unwirksam sein. 


Version 1.0 vom 01.03.2023
`,
	'2': `
**Allgemeine Geschäftsbedingungen auf bildungsfreaks.de**

Inhaltsverzeichnis

I. Allgemeine Nutzungsbedingungen

II. Besondere Nutzungsbedingungen für kostenpflichtige Services

III. Besondere Nutzungsbedingungen für Content Creator

## I - ALLGEMEINE NUTZUNGSBEDINGUNGEN
1. **Allgemeines**
	1. **Inhalt.** Diese allgemeinen Nutzungsbedingungen gemeinsam mit den besonderen Nutzungsbedingungen für kostenpflichtige Leistungen und den besonderen Nutzungsbedingungen für Content Creator (gemeinsam die „**Bedingungen**“) regeln den Zugriff auf und die Nutzung der durch die visionYOU GmbH, Stahnsdorfer Straße 107 14482 Potsdam („**Betreiber**“) angebotenen Webseiten https://bildungsfreaks.de/ und darauf angebotenen Informationen und Dienstleistungen (gemeinsam die „**Plattform**“) durch nichtregistrierte Besucher dieser Webseite („**Webseiten-Besucher**“) sowie natürliche oder juristische Personen, die den Registrierungsprozess für ein Nutzerkonto auf der Plattform vollständig abgeschlossen haben („**Registrierte Nutzer**“; zusammen mit den Webseiten-Besuchern die „**Nutzer**“). 
	2. **Zielgruppe.** Die Plattform und die darauf angebotenen Dienste richten sich an natürliche oder juristische Personen, die sie in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit nutzen (Unternehmer i.S.v. § 14 BGB) sowie deren autorisiertes Personal und an natürliche Personen, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können (Verbraucher i.S.v. § 13 BGB). 
	3. **Geltung der Nutzungsbedingungen.** Durch die Bestätigung dieser Bedingungen erklärt sich der Nutzer damit einverstanden, dass er mit der Geltung dieser Bedingungen einverstanden ist, sofern der Nutzer ein Registriertes Unternehmen  (wie unter Ziffer I.3.4 beschrieben) oder eine Unternehmensgruppe anlegt, dass er zur rechtsgeschäftlichen Vertretung des Registrierten Unternehmens berechtigt ist und dass er zum Zeitpunkt der Bestätigung dieser Bedingungen das 18. Lebensjahr vollendet hat bzw. seine Eltern oder Erziehungsberechtigten (wie unter Ziffer I.3.3 beschrieben) die Bestätigung für ihn abgegeben haben.
2. **Die Plattform** 
	1. **Services**. In Bezug auf Anwendungen und Themen im Bereich Bildungswesen ermöglicht die Plattform, soweit jeweils für die jeweilige Nutzergruppe freigeschaltet, den Zugriff auf folgende Leistungen (nachfolgend insgesamt als „**Service**“ bezeichnet): 
		1. **E-Learning-Angebote**: Die Plattform stellt Lern- und Ausbildungsinhalte in Form von multimedialen und interaktiven Lerneinheiten („**E-Learning-Angebote**“) zu verschiedenen Themenbereichen bereit. Die E-Learning-Angebote können vom Betreiber oder einem Dritten, einschließlich anderen Nutzern, erstellt und/oder bereitgestellt werden. Nutzer können die verschiedenen Module der E-Learnings durchlaufen. Registrierten Nutzern ist es zudem möglich ihren Fortschritt zu dokumentieren und entsprechende Nachweise zu erwerben. Der Betreiber behält sich vor, bestimmte E-Learning-Angebote kostenpflichtig und/oder nur einzelnen Nutzergruppen (siehe Ziffer I.3.2) zugänglich zu machen. Für kostenpflichtige E-Learning Angebotegelten zusätzlich zu diesen allgemeinen Nutzungsbedingungen die besonderen Nutzungsbedingungen für kostenpflichtige Leistungen (siehe Ziffer II).       
		2. **Share Services**: Bestimmten Nutzergruppen (wie in Ziffer I.3.2 definiert) wird die Möglichkeit gegeben, E-Learning-Angebote und/oder einzelne Inhalte oder herunterladbare Materialien der E-Learning-Angebote mit einer beschränkten Anzahl an Nutzern, d.h. Registrierter Nutzer oder nicht-registrierter Nutzer, („**Teilnehmer**“) zu teilen („**Share Services**“). Die Anzahl der Teilnehmer, mit denen E-Learning-Angebote und/oder einzelne Inhalte oder herunterladbare Materialien der E-Learning-Angebote geteilt werden können, kann von Nutzergruppe zu Nutzergruppe variieren und von der Zahlung eines Entgelts abhängig sein. Die Einzelheiten ergeben sich aus den Angaben in der Servicebeschreibung, einschließlich in Preisübersichten, auf der Plattform. Im Rahmen der Share Services wird dem Registrierten Nutzer für eine begrenzte Anzahl an Teilnehmern ein Link zu den für Share Services zulässigen Inhalten bereitgestellt. Diesen Link kann der Registrierte Nutzer mit den Teilnehmern teilen (bspw. via E-Mail). Teilnehmer rufen den Link auf und müssen sich mit einem zufälligen Kürzel anmelden, um einen individuellen Zugang zum Kurs und den E-Learning-Angeboten zu erhalten, ohne sich hierfür jedoch registrieren zu müssen. Über den Link und die Eingabe seines Kürzels kann der Teilnehmer für einen beschränkten Zeitraum wiederholt auf das geteilte E-Learning-Angebot mit dem individuellen Lernfortschritt zurückgreifen.
		3. **Content** **Creation**: Bestimmten Nutzergruppen (wie in Ziffer I.3.2 definiert) wird die Möglichkeit gegeben eigene E-Learning-Angebote zu erstellen und/oder erstellen zu lassen und sie Nutzern auf der Plattform zugänglich zu machen oder durch den Betreiber zugänglich machen zu lassen. Entsprechende E-Learning-Angebote werden als vom jeweiligen Registrierten Nutzer erstellt gekennzeichnet. Für die Erstellung und Bereitstellung von E-Learning-Angeboten gelten zusätzlich zu diesen allgemeinen Nutzungsbedingungen die besonderen Nutzungsbedingungen für Content Creator (siehe Ziffer III).

		Einige der vorstehend genannten Services sind zum Zeitpunkt der Veröffentlichung dieser Bedingungen noch in der Entwicklung und werden erst zu einem späteren Zeitpunkt oder lediglich für begrenzte Nutzergruppen freigeschaltet. Weitere Services können vom Betreiber jederzeit angeboten werden, wobei diese durch separate Leistungsbeschreibungen und/oder spezielle Nutzungsbedingungen näher ausgestaltet werden können. Die Nutzung dieser weiteren Services setzt in diesem Fall voraus, dass sich der Nutzer mit der Geltung dieser zusätzlichen Bestimmungen einverstanden erklärt; diese werden dann Bestandteil dieser Bedingungen bzw. des Nutzungsvertrags (wie unter Ziffer II.1.2 definiert). Bei keiner der Beschreibungen der Services und keiner der Bestimmungen dieser Bedingungen handelt es sich seitens des Betreibers um eine zugesicherte Eigenschaft, Beschaffenheitsvereinbarung oder Garantie.

	2. **Links**. Der Service kann Hyperlinks zu Angeboten und Diensten Dritter enthalten. Durch Folgen eines solchen Links öffnet sich die Webseite oder App des jeweiligen Drittanbieters. Der Betreiber hat keinen Einfluss auf den Inhalt von Webseiten oder Apps Dritter und wird weder Vertragspartei noch übernimmt er anderweitig Verpflichtungen aus etwaigen Drittvereinbarungen, denen der Nutzer über solche Webseiten oder Apps zustimmt. Der Betreiber ist grundsätzlich weder in der Lage noch verpflichtet, Webseiten oder Apps Dritter zu überprüfen, wird jedoch Hyperlinks zu rechtswidrigen Inhalten im Einklang mit anwendbarem Recht entfernen, wenn er von einer solchen Rechtswidrigkeit Kenntnis erlangt.
	3. **Drittinhalte**. Die Plattform erlaubt es dem Nutzer, auf bestimmte Inhalte von Dritten zuzugreifen (z.B. Inhalte, die von anderen Registrierten Nutzern erstellt oder eingestellt werden und über die Plattform abrufbar sind, oder über Frames einbezogenen Video-Content) („**Dritteinhalte**“). 
		1. Die Nutzung von Drittinhalten kann zusätzlich Lizenz- und/oder Nutzungsbedingungen des jeweiligen Dritten („**Drittbedingungen**“) unterliegen. Der Nutzer hat sicherzustellen, dass die Nutzung der Drittinhalte im Einklang mit den Drittbedingungen erfolgt. 
		2. Der Nutzer stellt den Betreiber von allen Schäden frei, die dem Betreiber aus der Verletzung von Drittbedingungen durch den Nutzer entstehen. 
		3. Der Betreiber ist für Drittinhalte nicht verantwortlich und ist grundsätzlich nicht verpflichtet, von Dritten bereitgestellte Informationen vor ihrer Freischaltung auf Vollständigkeit und Richtigkeit zu überprüfen. Er behält sich jedoch vor, im Einzelfall oder bei Vorliegen einer entsprechenden Rechtspflicht zu überprüfen, ob derartige Inhalte gegen diese Bestimmungen oder geltendes Recht verstoßen. Die Quelle der über die Plattform bereitgestellten Drittinhalte wird jeweils angezeigt. Drittinhalte werden vom Nutzer nach seiner eigenen Wahl und in seiner eigenen Verantwortung verwendet. 
	4. **Meldung rechtswidriger Inhalte**. Die Meldung rechtswidriger Inhalte durch Nutzer kann über folgende E-Mail-Adresse an den Betreiber erfolgen: kontakt@bildungsfreaks.de 
	5. **Nachunternehmer**. Der Betreiber darf bezüglich der Bereitstellung der Plattform, einschließlich deren Inhalte, Unteraufträge erteilen bzw. Nachunternehmer einbeziehen. 
	6. **Verfügbarkeit**. 
		1. Die Nutzung der Plattform setzt für jedes hierzu eingesetzte Endgerät eine funktionierende Internetverbindung voraus, für deren Nutzung für den Nutzer ggf. gesonderte Kosten seitens seines Telekommunikationsanbieters anfallen. Die Geschwindigkeit der Services hängt von der Geschwindigkeit der eingesetzten Internetverbindung ab. Die Bereitstellung einer Internetverbindung ist nicht Bestandteil der Services. Die Datenkommunikation über das Internet ist nach dem derzeitigen Stand der Technik nicht fehlerfrei und nicht jederzeit verfügbar. Der Betreiber ist nicht für eine hierauf basierende eingeschränkte Nutzbarkeit der Services verantwortlich. Die Nutzung der Plattform setzt ferner einen gängigen Browser in einer aktuellen Version voraus. 
		2. Der Betreiber ist bemüht, die Nutzung der Plattform während üblicher Geschäftszeiten (werktags zwischen 8:00 Uhr und 18:00 Uhr) im Monatsdurchschnitt mit einer Verfügbarkeit von 98,5 % zu ermöglichen. Hiervon ausgenommen sind Zeiten, in denen die Services oder einzelne Funktionalitäten aufgrund von technischen oder sonstigen Problemen, die der Betreiber nicht zu vertreten hat (z.B. durch das Internet bedingten Störungen oder aufgrund höherer Gewalt), oder aufgrund von Wartungsarbeiten in angemessenem Umfang nicht oder nur eingeschränkt zu erreichen sind. Auf Wartungen, die die Funktionsfähigkeit der Services nicht nur unwesentlich beeinträchtigen, wird der Betreiber die Registrierten Nutzer soweit möglich (d.h. soweit die Arbeiten nicht berechtigterweise kurzfristig durchgeführt werden müssen) mit einem Vorlauf von 48 Stunden in der Plattform hinweisen (z.B. durch Einblendung entsprechender Mitteilungen beim Einloggen). Die Plattform ist auch außerhalb der vorgenannten üblichen Geschäftszeiten nutzbar, allerdings werden hierfür keine Verfügbarkeitszusagen gemacht. 
		3. Der Betreiber behält sich vor, den Betrieb der Plattform jederzeit ganz oder in Teilen einzustellen. Die Einstellung wird den Registrierten Nutzern mit Vorlauf von mindestens vierzehn (14) Kalendertagen mitgeteilt. Für die Art der Mitteilung gilt Ziffer I.12.4 Sofern der Registrierte Nutzer kostenpflichtige Services in Anspruch nimmt, werden ihm etwaige durch die Einstellung zu viel bezahlte Entgelte unverzüglich erstattet. 
3. **Registrierung, Benutzerkonto Unternehmensgruppe**
	1. **Registrierung.** Bestimmte Funktionalitäten der Plattform können nur von Registrierten Nutzern genutzt werden (z.B. Inhalte einstellen oder solche Inhalte sehen, die nur für Registrierte Nutzer freigegeben sind). Hierzu muss der Nutzer bei der Registrierung für die Services ein Nutzerkonto einrichten und unterhalten („**Nutzerkonto**“). Um den Status eines Registrierten Nutzers zu erlangen, muss der Nutzer alle zur Registrierung erforderlichen Angaben (E-Mail-Adresse, Name und Vorname sowie ggf. Name der Organisation) gemacht und die Registrierung komplett abgeschlossen haben. Die Registrierung ist vollständig abgeschlossen, wenn der Nutzer den in der Bestätigungsmail hinterlegten Verifizierungscode auf der Webseite eingibt. Mit Abschluss der Registrierung durch Akzeptieren dieser Bedingungen kommt zwischen dem Registrierten Nutzer und dem Betreiber ein Vertrag über die Nutzung der Plattform und der darauf angebotenen Services („**Nutzungsvertrag**“) zustande.  
	2. **Nutzergruppen.** Für die Registrierung ist es erforderlich, dass sich der Nutzer für eine der angebotenen Nutzerspezifikationen („**Nutzergruppe**“) eingetragen hat, derzeit: 
		1. „**Lehrer**“: sind jegliche natürlichen Personen (z.B. angestellte, verbeamtete, berufene oder freie Lehr- und Ausbildungskräfte oder sonstige Mitarbeiter einer Lehr- oder Ausbildungseinrichtung), die die Plattform und/oder darauf bereitgestellte Services für die eigene oder fremde gewerbliche oder selbständige berufliche Tätigkeit im Rahmen der Vorbereitung und Durchführung von Lehrveranstaltungen, Seminaren, Kongressen, Kollegien oder sonstige Bildungs- und Weiterbildungstätigkeit nutzen. 
		2. „**Schüler**“: sind jegliche natürlichen Personen, die das 18. Lebensjahr vollendet oder das entsprechende Mündigkeitsalter für die Nutzung der Services erreicht haben und die Plattform und/oder die Services für ihre schulische oder berufliche Fortbildung nutzen. 
		3. „**Mitarbeiter**“: sind jegliche natürliche Personen, die die Plattform und/oder darauf bereitgestellte Services als Angestellter, Organ oder freier Mitarbeiter eines Unternehmens für eigene oder fremde gewerbliche oder nicht-gewerbliche Zwecke, einschließlich der eigenen Fort- und Weiterbildung, nutzen. 
		4. „**Unternehmen**“: sind juristische Personen oder öffentlich-rechtliche Einrichtungen (z.B. Schulen), die die Plattform und/oder darauf bereitgestellte Services für eigene gewerbliche oder nicht-gewerbliche Zwecke, einschließlich der Fort- und Weiterbildung von Mitarbeitern oder Schülern nutzen.
		5. „**Interessierte**“: sind jegliche sonstige natürliche oder juristische Personen, die die Plattform und/oder darauf bereitgestellte Services für gewerbliche oder nicht gewerbliche Zwecke nutzen. 
	3. **Volljährigkeit.** Soweit ein Nutzer das 18. Lebensjahr noch nicht vollendet hat, darf der Nutzer allein kein Nutzerkonto einrichten. Er kann jedoch ein Elternteil oder einen Erziehungsberechtigten darum bitten, ein Nutzerkonto zu eröffnen und ihn beim Zugriff auf Inhalte zu helfen, der für den Nutzer geeignet ist. Wenn der Betreiber feststellt, dass ein Registrierter Nutzer ein Nutzerkonto unter Verstoß gegen diese Bedingungen eingerichtet hat, wird das Nutzerkonto umgehend geschlossen.
	4. **Registrierte Unternehmen.** Soweit ein Registrierter Nutzer die Services nicht für sich persönlich in Anspruch nimmt, sondern als Mitarbeiter oder Beauftragter eines Unternehmens oder einer Bildungseinrichtung, für das er tätig ist, akzeptiert er diese Bedingungen bei der Registrierung sowohl in eigenem Namen als auch im Namen dieses Unternehmens oder der Bildungseinrichtung („**Registriertes Unternehmen**“). Registrierte Unternehmen sind von dem in diesen Bedingungen verwendeten Begriff der Registrierte Nutzer mit umfasst. Jeder Registrierte Nutzer, der demselben Registrierten Unternehmen zugeordnet werden kann, gehört zu derselben Unternehmensgruppe („**Unternehmensgruppe**“).
	5. **Überprüfung.** Der Betreiber ist berechtigt, aber nicht verpflichtet, die Nutzung der Plattform von dem Nachweis des Registrierten Nutzers in Bezug auf die gemachten Angaben abhängig zu machen. Der Betreiber kann diese Überprüfung sowohl bei der Registrierung vornehmen als auch jederzeit danach. Der Betreiber behält sich das Recht vor, die Registrierung nicht durchzuführen, insbesondere wenn (i) die bei der Registrierung gemachten Angaben nicht verifiziert werden können, (ii) sich aus den bei der Registrierung bestehenden Umständen oder den gemachten Angaben ergibt, dass das Nutzerkonto mit überwiegender Wahrscheinlichkeit nicht dem Zweck der Plattform (Konsumption von Bildungsinhalten wie Ziffer I.2.1 beschrieben) dienen soll, (iii) sich herausstellt, dass der Nutzer das für die Nutzung der Services erforderliche Mündigkeitsalter noch nicht erreicht hat oder die Registrierung eines minderjährigen Nutzers nicht durch einen Elternteil oder einen Erziehungsberechtigten erfolgt ist oder (iv) das vom Betreiber vorausgesetzte Professionalitäts- oder Qualitätslevel zur Aufrechterhaltung eines insgesamt hohen Standards der Plattform vom Nutzer nicht erreicht wird. Ein Anspruch der Nutzer auf Registrierung besteht nicht.
4. **Weitere Pflichten der Nutzer**
	1. **Pflichten des Nutzers**. Der Registrierte Nutzer stellt sicher, dass (i) sämtliche vom Registrierten Nutzer bei der Registrierung oder als Teil des Services an den Betreiber übermittelten Informationen, einschließlich der Kontaktdaten, zutreffend und aktuell sind und diese Informationen während der gesamten Laufzeit vom Registrierten Nutzer auf dem aktuellen Stand gehalten werden, (ii) der Registrierte Nutzer sämtliche personenbezogenen Daten von Dritten, die der Registrierte Nutzer als Teil der Services übermittelt oder anderweitig verarbeitet, nur im Einklang mit dem geltenden Recht übermittelt und verarbeitet und erforderliche Einwilligungen eingeholt hat, (iii) das von ihm übermittelte Material und, soweit gemäß dieser Bedingungen zulässig, zu veröffentlichende Inhalte rechtmäßig sind und keine Rechte Dritter (etwa geistigen Eigentumsrechte, Urheberrechte oder Markenrechte), oder Verpflichtungen gegenüber Dritten verletzen; und (iv)  vom Registrierten Nutzer hochgeladene Dateien frei von Viren oder sonstigen potentiell für die Plattform oder andere Nutzer schädlichen Komponenten sind.
	2. **Sperrung und Löschung von Nutzerkonten**. Nutzerkonten, die unter Angabe falscher Informationen angelegt werden (sog. „Fake Accounts“), werden vom Betreiber gelöscht, wenn ihm dieser Umstand bekannt wird. Der Registrierte Nutzer hat nach der Löschung vierzehn (14) Tage Zeit, das gelöschte Nutzerkonto unter Übersendung eines Nachweises der Richtigkeit der gemachten Angaben wieder zu reaktivieren. Der Betreiber ist ferner zur sofortigen Sperrung eines Nutzerkontos berechtigt, wenn der begründete Verdacht besteht, dass die gespeicherten Nutzerdaten rechtswidrig sind und/oder Rechte Dritter verletzen. Ein begründeter Verdacht für eine Rechtswidrigkeit und/oder eine Rechtsverletzung liegt insbesondere dann vor, wenn Gerichte, Behörden und/oder sonstige Dritte den Betreiber darüber in Kenntnis setzen. Der Betreiber wird die Sperre aufheben, sobald der Verdacht entkräftet ist. Soweit dies dem Betreiber unter Abwägung der beiderseitigen Interessen zumutbar ist, wird er die Sperrung erst nach erfolglosem Ablauf einer zur Abhilfe bestimmten Frist oder nach erfolgloser Abmahnung gegenüber dem betroffenen Registrierten Nutzer vornehmen. 
	3. **Vertraulichkeit von Login-Daten**. Der Registrierte Nutzer willigt ein, Zugangsdaten wie die Kombination von Login-Daten und Passwort streng vertraulich zu behandeln. Die Weitergabe seiner Login-Daten an einen Dritten ist dem Registrierten Nutzer nicht gestattet. Der Registrierte Nutzer ist verpflichtet, den Betreiber umgehend zu informieren, wenn es Anhaltspunkte dafür gibt, dass ein Nutzerkonto von Dritten missbraucht wurde. Das Nutzerkonto ist nicht übertragbar.
	4. **Keine missbräuchliche Nutzung.** Der Registrierte Nutzer verpflichtet sich, die Plattform nur in der in Ziffer I.2.1 aufgeführten Weise zu verwenden, insbesondere keine auf der Plattform bereitgestellten Inhalte weiterzuverarbeiten oder zu kopieren. Die Nutzung hat ferner im Einklang mit den geltenden gesetzlichen Bestimmungen zu erfolgen. Der Registrierte Nutzer ist allein verantwortlich, sich über die gesetzlichen Bestimmungen der Bundesrepublik Deutschland zu informieren und diese einzuhalten. Liegt eine missbräuchliche oder unrechtmäßige Nutzung der Plattform vor und erlangt der Betreiber hiervon Kenntnis, behält sich dieser (unbeschadet weiterer Ansprüche gegen den Registrierten Nutzer) vor den Registrierten Nutzer von der Teilnahmemöglichkeit an der Plattform auszuschließen.
	5. **Feedback** **und Vorschläge.** Wenn ein Nutzer dem Betreiber Vorschläge, Empfehlungen oder Ideen (insgesamt „**Vorschläge**“) unterbreitet, wie die Plattform verbessert werden könnte, dürfen der Betreiber und seine Lizenznehmer diese Vorschläge im Verhältnis zum Nutzer ohne jegliche Einschränkung und ohne die Pflicht zur Zahlung einer Vergütung an den Nutzer weltweit und zeitlich unbeschränkt nutzen (einschließlich aber nicht begrenzt auf die Umsetzung dieser Vorschläge innerhalb der Services). 
5. **Entgelt** 
	1. **Kosten der Plattformnutzung.** Die Registrierung für und Nutzung der unter Ziffer I.2.1 beschriebenen Services ist derzeit kostenlos. Der Betreiber und sein Rechtsnachfolger behalten sich jedoch vor, zu einem späteren Zeitpunkt für die Nutzung der Plattform und/oder einzelner Funktionen bzw. Services ein Entgelt zu erheben, auch wenn diese zuvor kosten zur Verfügung standen. In diesem Fall werden die Registrierten Nutzer aufgefordert, innerhalb einer angemessenen Frist, die sechs (6) Wochen nicht unterschreiten wird, zu entscheiden, ob sie die Plattform bzw. die betroffenen Services kostenpflichtig nutzen wollen, oder ob sie die Nutzung einstellen wollen. Die Zahlungsmodalitäten werden zusammen mit dem Entgelt festgelegt. Die Aufforderung zur Zustimmung zur Einführung eines Entgelts kann seitens des Betreibers mit der Aufforderung zur Zustimmung zu zusätzlichen Bedingungen und/oder mit der Zustimmung zu Leistungsänderungen an den Services verbunden werden. Entscheidet sich der Registrierte Nutzer gegen eine Weiternutzung oder unterbleibt die Zustimmung innerhalb der vom Betreiber hierfür gesetzten Frist, steht dem Betreiber bzw. seinem Rechtsnachfolger ein besonderes Kündigungsrecht des Nutzungsvertrags zu, das innerhalb von vierzehn (14) Tagen nach Ablauf der für die Zustimmung gesetzten Frist erklärt werden muss. Die Kündigung wird zum Ende des auf die Kündigung folgenden Monats wirksam. 
	2. **Kosten von Services.** Services können vom Betreiber kostenpflichtig ausgestaltet werden. In diesem Fall kann der Registrierte Nutzer die Leistung nur gegen Zahlung des entsprechenden Entgelts unter Nutzung der vom Betreiber angebotenen Zahlungsmethoden in Anspruch nehmen. Der Registrierte Nutzer wird vor Nutzung oder Freischaltung der entsprechenden Services auf deren Kostenpflichtigkeit hingewiesen. Für Kostenpflichtige Services (wie unter Ziffer II.2 definiert) gelten die Bestimmungen in Ziffer II vorrangig.  
	3. **Zahlungsmodalitäten.** Sofern der Registrierte Nutzer einen kostenpflichtigen Service in Anspruch nimmt, gilt für zukünftige Preisanpassungen für bereits vereinbarte Leistungen das Folgende: Der Betreiber darf die auf der Grundlage dieses Vertrages zu zahlenden Preise am Anfang jedes Kalenderjahres nach billigem Ermessen der Entwicklung der Kosten anpassen, die für die Preisberechnung maßgeblich sind. Eine Preiserhöhung darf 7% des jeweils zuvor zu zahlenden Preises nicht übersteigen. Eine Preiserhöhung kommt in Betracht und eine Preisermäßigung ist vorzunehmen, wenn 

		a) sich die Kosten für die Beschaffung von Energie, Hostingleistungen, zur Leistungserbringung erforderlicher Soft- oder Hardware oder sonstiger technischer Infrastruktur oder Ressourcen, die der Betreiber selbst von Vorlieferanten oder sonstigen Dritten bezieht, erhöhen oder absenken, 

		b) bei Änderungen des Arbeitskostenindex für Deutschland oder 

		c) wenn Änderungen der rechtlichen Rahmenbedingungen (z.B. durch die weitere Regulierung von Hostingleistungen) zu einer veränderten Kostensituation führen. 

		Steigerungen bei einer Kostenart, z.B. den Strombezugskosten, dürfen nur in dem Umfang für eine Preiserhöhung herangezogen werden, in dem kein Ausgleich durch etwaig rückläufige Kosten in anderen Bereichen erfolgt. Bei Kostensenkungen, z.B. der Strombezugskosten, sind vom Betreiber die Preise zu ermäßigen, soweit diese Kostensenkungen nicht durch Steigerungen in anderen Bereichen ganz oder teilweise ausgeglichen werden. Der Betreiber wird bei der Ausübung seines billigen Ermessens die jeweiligen Zeitpunkte einer Preisänderung so wählen, dass Kostensenkungen nicht nach für den Registrierten Nutzer ungünstigeren Maßstäben Rechnung getragen werden als Kostenerhöhungen, also Kostensenkungen mindestens in gleichem Umfang preiswirksam werden wie Kostenerhöhungen. Die beschwerte Partei kann durch Mitteilung in Textform (für den Betreiber siehe Ziffer I.12.4) binnen drei (3) Wochen ab Zugang der Mitteilung über die Anpassung des Preises den betroffenen Service oder den Nutzungsvertrag kündigen. Zur Klarstellung wird festgehalten, dass der angepasste Preis als vom Registrierten Nutzer akzeptiert gilt, sofern er diese Kündigung nicht innerhalb der genannten Frist vornimmt und er in der Mitteilung über die Anpassung des Preises auf sein Kündigungsrecht sowie die Rechtsfolge seines Schweigens hingewiesen wurde. Zur weiteren Klarstellung: Diese Klausel hindert den Betreiber nicht an seiner freien Preisgestaltung für zukünftige, vom Registrierten Nutzer noch nicht vertraglich verbindlich kostenpflichtig bestellte Leistungen.

6. **Nutzungsrechte** 
	1. **Geistiges Eigentum und beschränkte Nutzungsrechte an der Plattform**. Sämtliche geistigen Eigentumsrechte an der Plattform und der zu ihrem Betrieb und der Erbringung der Services eingesetzten Technologie oder Schutzrechten verbleibt bei dem Betreiber bzw. seinen jeweiligen Lizenzgebern. Außer in dem in diesen Bedingungen ausdrücklich geregelten Umfang werden den Nutzern keinerlei Nutzungs- oder Verwertungsrechte an der zum Betrieb und der Erbringung der Services eingesetzten Technologie oder Schutzrechten gewährt. Gesetzlich zwingende Regeln des Urheberrechts bleiben hiervon unberührt. Vorbehaltlich der Einhaltung dieser Bedingungen räumt der Betreiber dem Registrierten Nutzer das nicht-ausschließliche, nicht unterlizenzierbare, nicht übertragbare und zeitlich auf die Laufzeit des Nutzungsvertrags beschränkte Recht ein, die Plattform und ihre Funktionalitäten im Rahmen der vertragsgemäßen Nutzung der Services im hierfür erforderlichen Umfang zu nutzen. 
	2. **Plattformmaterial**. Nutzer erhalten ein beschränktes, nicht-exklusives, nicht übertragbares und nicht unterlizenzierbares Recht, auf der Plattform zum jeweiligen Zeitpunkt verfügbare Informationen, Beiträge und Inhalte („**Plattforminhalte**“) im Rahmen der von der Plattform zur Verfügung gestellten technischen Möglichkeiten nur für eigene, interne Zwecke auf ihren Endgeräten unverändert abzurufen und anzusehen und soweit die Funktionalitäten der Plattform dies im Einzelfall ermöglichen und dies durch diese Bedingungen oder im Falle von Drittinhalten durch die auf diese Drittinhalte anwendbaren Drittbestimmungen oder nach zwingendem anwendbarem Recht gestattet ist, Plattforminhalte herunterzuladen, zu vervielfältigen und zu nutzen. Jegliche anderweitige Verwendung der Plattforminhalte bedarf der vorherigen ausdrücklichen schriftlichen Zustimmung des Betreibers. Insbesondere ist es den Nutzern untersagt, Plattforminhalte zu eigenen kommerziellen oder nicht-kommerziellen Zwecken oder für Dritte zu bearbeiten, zu ändern (sowie sonstige Umgestaltung), in eine andere Ausdrucksform umzuwandeln (insbesondere Reverse Engineering oder Dekompilieren), zu übersetzen, zu veröffentlichen oder sonst zu verbreiten oder zu verwerten oder Dritten für jegliche Nutzung zugänglich zu machen oder zur Verfügung zu stellen, sowie Nutzungsrechte zu übertragen und zeitlich und inhaltlich beschränkte oder unbeschränkte Unterlizenzen zu erteilen, soweit dies nicht durch die Funktionen der Plattform unterstützt wird (bspw. Teilen von Inhalten mit Schülern) und durch diese Bestimmungen oder im Falle von Drittinhalten durch die auf diese Drittinhalte anwendbaren Drittbestimmungen oder zwingende gesetzliche Regelungen ausdrücklich gestattet ist. Nicht zu den Plattforminhalten gehören Bezahlte Inhalte; auf diese** sind ausschließlich die Regelungen der Ziffer II.6 anwendbar.
	3. **Rechteinhaberhinweise**. In sämtlichen Kopien der Leistungen, die der Nutzer in Einhaltung der hier geregelten Beschränkungen anfertigt, und in allen Bearbeitungen derselben hat der Nutzer die Hinweise auf Urheberrechte, Marken oder sonstige geschützte Rechte des Betreibers oder seiner Lizenzgeber wiederzugeben und mit aufzunehmen. 
7. **Haftung von Betreiber**
	1. **Schaden.** Der Betreiber haftet für Schäden und vergebliche Aufwendungen der Nutzer (im Folgenden zusammen als „**Schäden**“ bezeichnet) gemäß den gesetzlichen Bestimmungen, soweit nicht nachfolgend Abweichendes bestimmt ist. 
	2. **Vertragsverletzung.** Im Fall einer schuldhaften Verletzung von wesentlichen Vertragspflichten, soweit diese nicht grob fahrlässig oder vorsätzlich erfolgt, ist die Haftung des Betreibers auf die bei Vertragsschluss vorhersehbaren Schäden, die typischerweise bei Verträgen über Leistungen dieser Art entstehen, beschränkt. Eine wesentliche Vertragspflicht ist hierbei eine Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des Nutzungsvertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut und vertrauen darf. Der Betreiber haftet nicht für Schäden, die durch eine schuldhafte Verletzung von nicht wesentlichen Vertragspflichten verursacht werden, soweit diese nicht grob fahrlässig oder vorsätzlich erfolgt. 
	3. **Mangel der Services.** Die Haftung für Schäden, die durch einen Mangel der Services verursacht werden, der bereits zum Zeitpunkt des Abschlusses der Bedingungen bestand, ist ausgeschlossen, vorausgesetzt die Schäden wurden nicht fahrlässig oder vorsätzlich von Betreiber verursacht.
	4. **Verlust von Daten.** Für den Verlust von Daten haftet der Betreiber insoweit nicht, als der Schaden darauf beruht, dass es der Nutzer unterlassen hat, angemessene Datensicherungen durchzuführen und dadurch sicherzustellen, dass verloren gegangene Daten mit vertretbarem Aufwand wiederhergestellt werden können (§ 254 BGB).
	5. **Haftungsausschluss.** Eine über die Ziffern I.7.1 bis I.7.4 hinausgehende Haftung des Betreibers für Schäden des Nutzers (unabhängig von der Rechtsnatur des Anspruchs, ob aus Vertrag, unerlaubter Handlung oder anderweitig) ist, vorbehaltlich Ziffer I.7.6, ausgeschlossen.
	6. **Unbeschränkte Haftung.** Die gesetzliche Haftung des Betreibers gemäß § 1 des Produkthaftungsgesetzes, für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, für arglistig verschwiegene Mängel, für Schäden, die auf Vorsatz oder grober Fahrlässigkeit beruhen, und für Schäden, für die der Betreiber aufgrund der Übernahme einer Garantie verschuldensunabhängig einzustehen hat, bleibt von den vorstehenden Haftungsbegrenzungen und -ausschlüssen unberührt.
	7. **Persönliche Haftung.** Die vorstehende Haftungsbeschränkung gilt auch für die persönliche Haftung von Mitarbeitern, leitenden Angestellten, gesetzlichen Vertretern und Erfüllungsgehilfen des Betreibers.
8. **Vertraulichkeit**
	1. **Verschwiegenheitsverpflichtung.** Jede Partei verpflichtet sich, die von der jeweils anderen Partei im Rahmen eines Nutzungsvertrags erhaltenen vertraulichen Informationen auch über die Beendigung der Nutzungsvertrags hinaus vertraulich zu behandeln. Das bedeutet insbesondere, dass die zur Verschwiegenheit verpflichtete Partei („**Verschwiegenheitspflichtige**“) diese Informationen weder selbst noch durch Mitarbeiter an Dritte bekanntzugeben oder sonst für andere als die vertraglich zwischen den Parteien vereinbarten Zwecke zu nutzen hat. Als „**Vertrauliche Informationen**“ im Sinne dieser Vereinbarung gelten alle Informationen, die ausdrücklich als vertrauliche Informationen kenntlich gemacht oder bezeichnet werden und nicht unter die Ausnahmen nach Ziffer I.8.2 fallen. 
	2. **Ausnahmen.** Diese Verpflichtung zum Schutze Vertraulicher Informationen beinhaltet nicht solche Informationen, die 
		1. nachweislich vor ihrer Übermittlung bereits dem Verschwiegenheitspflichtigen zugänglich waren bzw. ohne sein Verschulden während der Geltungsdauer dieser Vereinbarung öffentlich bekannt wurden oder werden (zur Klarstellung: Auf der Plattform der Öffentlichkeit zugänglich gemachte Inhalte gelten nicht als Vertrauliche Informationen); 
		2. sich der Verschwiegenheitspflichtige selbst erschlossen oder die er unabhängig entwickelt hat, vorausgesetzt, dass dies durch schriftliche Aufzeichnungen oder auf sonstige Weise belegt wird und keine in dieser Vereinbarung festgelegten Pflichten unterlaufen werden;
		3. der Verschwiegenheitspflichtige von einem Dritten erhalten hat, der berechtigt ist, diese Informationen uneingeschränkt offen zu legen; oder
		4. durch schriftliche Erklärung der die Vertraulichen Informationen offenlegenden Partei gegenüber dem Verschwiegenheitspflichtigen ausdrücklich freigegeben wurden.
	3. **Weitergabe von vertraulichen Informationen.** Jede Partei wird ihren Angestellten oder Beratern Vertrauliche Informationen nur soweit zugänglich machen, als dies nach dem Vertragszweck dieser Vereinbarung erforderlich ist und soweit der Empfänger ebenfalls zur Vertraulichkeit verpflichtet ist. Jede Partei ist zur Weitergabe von vertraulichen Informationen berechtigt, soweit sie aufgrund einer Rechtsvorschrift oder behördlicher Anordnung dazu verpflichtet ist, die andere Partei über die beabsichtigte Weitergabe schriftlich informiert hat (sofern dies zulässig ist) und die nach Gesetz vorgesehenen und angemessenen Vorkehrungen getroffen hat, um den Umfang der Weitergabe so gering wie möglich zu halten.
9. **Datenschutz**
	1. **Datenschutzbestimmungen.** Der Betreiber wird die von ihm im Rahmen dieser Bedingungen verarbeiteten personenbezogenen Daten nach Maßgabe der Datenschutzbestimmungen (einschließlich der Cookie-Richtlinie) der Plattform behandeln, die unter <https://bildungsfreaks.de/data-privacy> verfügbar sind. 
	2. **Datenschutzverpflichtung.** Jeder Nutzer ist selbst für seine Einhaltung aller Bestimmungen der anwendbaren Datenschutzgesetze verantwortlich, einschließlich der Einhaltung von Auskunfts- und Informationspflichten gegenüber seinen Mitarbeitern, Vertragspartnern oder sonstigen betroffenen Personen, sowie die Einhaltung einschlägiger Löschfristen. Der Betreiber ist nicht dafür verantwortlich, wenn Nutzer die Services auf eine Weise nutzen, die gegen geltendes Datenschutzrecht verstößt. 
10. **Laufzeit und Kündigung**
	1. **Vertragslaufzeit.** Der Nutzungsvertrag wird auf unbestimmte Zeit abgeschlossen. Er beginnt mit der Registrierung des jeweiligen Registrierten Nutzers gemäß Ziffer I.3.1 und endet mit der Schließung bzw. Löschung des Nutzerkontos („**Nutzungsvertragslaufzeit**“). Registrierte Nutzer und der Betreiber werden in diesen Bedingungen jeweils auch als „**Partei**“ bezeichnet. Webseiten-Besucher haben sich bei ihrer Nutzung der Plattform an die auf sie anwendbaren Bedingungen zu halten.
	2. **Kündigung.** Der Nutzungsvertrag kann (i) vom Betreiber unter Einhaltung einer Frist von einem (1) Monat zum Ende eines Kalendermonats und (ii) vom Registrierten Nutzer jederzeit ordentlich gekündigt werden. Der Registrierte Nutzer kündigt den Nutzungsvertrag, indem er sein Nutzerkonto löscht. Das Recht jeder Partei, den Nutzungsvertrag bei Vorliegen der gesetzlichen Voraussetzungen aus wichtigem Grund zu kündigen, bleibt unberührt. Zur fristlosen Kündigung ist der Betreiber insbesondere berechtigt, wenn der Registrierte Nutzer fällige Zahlungen trotz Mahnung und Nachfristsetzung nicht leistet oder diese Bedingungen verletzt und die Verletzung trotz Aufforderung durch den Betreiber nicht in angemessener Zeit beseitigt. Eine Kündigung durch den Betreiber muss schriftlich oder in Textform gemäß Ziffer I.12.4 erfolgen.
	3. **Laufzeit einzelner Services.** Einzelne Services können eine von der Nutzungsvertragslaufzeit abweichende Laufzeit vorsehen (z.B. Subscriptions auf Monats- oder Jahresbasis). Laufzeit, (ggf. automatische) Erneuerung und Kündigungsmöglichkeiten richten sich dann nach den für den jeweiligen Service geltenden Zusatzbestimmungen. Bei einer Kündigung des Nutzungsvertrags durch den Registrierten Nutzer enden auch entgeltliche Services zum Zeitpunkt des Wirksamwerdens der Kündigung. Ein etwaiges noch verbleibendes ungenutztes Guthaben wird dem Registrierten Nutzer unverzüglich erstattet. Bei einer ordentlichen Kündigung des Nutzungsvertrags durch den Betreiber gelten die Bedingungen des Nutzungsvertrags für die Restlaufzeit des jeweiligen Service weiter, soweit sie auf den Service Anwendung finden, mit der Maßgabe, dass die Laufzeit des jeweiligen Services nicht automatisch verlängert wird, sondern als gekündigt gilt und zum Ende der Restlaufzeit endet.   
	4. **Folgen der Beendigung.** Nach Beendigung des Nutzungsvertrags werden die von dem Registrierten Nutzer bereitgestellten Daten in dessen Nutzerkonto gelöscht. Abweichend hiervon bewahrt der Betreiber diese Informationen auch nach Vertragsende auf, soweit der Betreiber hierzu gesetzlich verpflichtet ist oder hieran ein berechtigtes Interesse hat und die Aufbewahrung nach anwendbarem Datenschutzrecht gestattet ist. Aggregierte Daten, die durch die Nutzung generiert wurden (z.B. Statistiken), oder Daten, an denen der Betreiber ein fortbestehendes eigenes Nutzungsrecht hat, werden grundsätzlich auch nach Vertragsende weiterhin aufbewahrt. Über die Verarbeitung personenbezogener Daten durch den Betreiber einschließlich der Übermittlung an Dritte und den Rechten des Nutzers als Betroffener informiert der Betreiber in seinen Datenschutzbestimmungen.
11. **Widerrufsbelehrung**

	Ist der Registrierte Nutzer Verbraucher (§ 13 BGB) und wird der Nutzungsvertrag oder ein sonstiger Vertrag im Sinne dieser Bedingungen im Wege des Fernabsatzes gemäß § 312c BGB geschlossen, gelten für diesen Vertrag die nachfolgenden Bestimmungen.

	1. **Widerrufsrecht**. Der Registrierte Nutzer hat das Recht, binnen vierzehn (14) Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in Textform, jedoch nicht vor Vertragsschluss und auch nicht vor Erfüllung der Informationspflichten des Betreibers gemäß § 312d Abs. 1 BGB i.V.m. Artikel 246a § 1 Absatz 2 Satz 1 Nummer 1 EGBGB. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs. 
	2. **Ausübung des Widerrufs**. Um sein Widerrufsrecht auszuüben, muss der Registrierte Nutzer den Betreiber, visionYOU GmbH, Stahnsdorfer Straße 107 14482 Potsdam, <kontakt@bildungsfreaks.de>, +49 (0) 331 58256033 mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder eine E-Mail) über seinen Entschluss, diesen Vertrag zu widerrufen, informieren. Der Registrierte Nutzer kann dafür das beigefügte Muster-Widerrufsformular verwenden. Zur Wahrung der Widerrufsfrist reicht es aus, dass der Registrierte Nutzer die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.

		Muster-Widerrufsformular

		Wenn Sie den Vertrag nach Maßgabe der vorstehenden Widerrufsbelehrung widerrufen wollen, dann können Sie dieses Formular ausfüllen und an uns zurücksenden. Die Verwendung des Formulars ist aber nicht zwingend.

		An …

		Hiermit widerrufe(n) ich/wir () den von mir/uns () abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung:

		Bestellt am (\*)

		Name des/der Verbraucher(s)

		Anschrift des/der Verbraucher(s)

		Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)

		Datum

		*(\*) Unzutreffendes streichen.* 


	3. **Folgen des Widerrufs.** 
		1. Wenn der Registrierte Nutzer den Vertrag widerruft, hat ihm der Betreiber alle Zahlungen, die er von dem Registrierten Nutzer erhalten hat, unverzüglich und spätestens binnen vierzehn (14) Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf des Vertrags eingegangen ist. Für diese Rückzahlung verwendet der Betreiber dasselbe Zahlungsmittel, das der Registrierte Nutzer bei der ursprünglichen Transaktion eingesetzt hat, es sei denn, mit dem Registrierten Nutzer wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden dem Registrierten Nutzer wegen dieser Rückzahlung Entgelte berechnet. 
		2. Mit Ausübung des Widerrufsrechts hat es der Registrierte Nutzer ferner zu unterlassen, die Plattform oder Produkte des Betreibers selbst zu nutzen oder einem Dritten zur Verfügung zu stellen. Er hat hierfür Maßnahmen zu treffen, um den Zugriff zu verhindern. Der Betreiber verpflichtet sich, die Inhalte, die der Registrierte Nutzer bei der Nutzung der Plattform oder der Produkte des Betreibers bereitgestellt oder erstellt hat, nicht weiternutzen. Für die Folgen des Widerrufs bzgl. der personenbezogenen Daten des Registrierten Nutzers finden die Datenschutzbestimmungen Anwendung. 
	4. **Erlöschen des Widerrufsrechts.** Das Widerrufsrecht erlischt (i) bei einem unentgeltlichen Vertrag über die Bereitstellung von nicht auf einem körperlichen Datenträger befindlichen digitalen Inhalten, wenn der Betreiber mit der Vertragserfüllung begonnen hat und (ii) bei einem entgeltlichen Vertrag über die Lieferung von nicht auf einem körperlichen Datenträger befindlichen digitalen Inhalten dann, wenn der Betreiber mit der Ausführung des Vertrags begonnen hat, nachdem der Registrierte Nutzer ausdrücklich zugestimmt hat, dass der Betreiber mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist beginnt, und seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.
12. **Änderung der Nutzungsbedingungen**
	1. **Keine abweichenden Geschäftsbedingungen des Nutzers.** Diese Bedingungen enthalten abschließend die zwischen dem Betreiber und dem Nutzer geltenden Bedingungen für die Nutzung der Plattform und der Services durch den Nutzer. Von diesen Bedingungen abweichende Regelungen gelten nur dann, wenn diese vom Betreiber in Schriftform ausdrücklich bestätigt werden.
	2. **Änderungen der Bedingungen und der Nutzungsverträge**
		1. **Änderungen.** Der Betreiber behält sich das Recht vor, die Bedingungen (einschließlich der Zusatzbedingungen, die für einen bestimmten Service relevant sind) jederzeit mit Wirkung für die Zukunft zu ändern oder zu ergänzen. Die geänderten Bedingungen werden auf der Webseite mit dem Datum der „letzten Aktualisierung“ veröffentlicht. Webseiten-Besucher haben sich regelmäßig über die jeweils aktuelle Version der Bedingungen zu informieren. 
		2. **Mitteilung der Änderung des Nutzungsvertrags.** Jegliche Änderung oder Ergänzung der bestehenden Nutzungsverträge werden Registrierten Nutzern in Textform (vgl. Ziffer I.12.4) mindestens vier (4) Wochen vor Wirksamwerden mitgeteilt; die Mitteilung gibt den Tag des Inkrafttretens einer solchen Änderung oder Ergänzung an. Der Registrierte Nutzer ist verpflichtet, sich laufend über etwaige Mitteilungen im Rahmen der Services informiert zu halten. 
		3. **Zustimmungsfiktion und Widerspruch.** Soweit der Betreiber die Anpassung der Nutzungsverträge vornimmt, um sie an gesetzliche Änderungen, technische Entwicklungen oder Neuerungen, geänderte Marktanforderungen oder Änderungen der Services anzupassen, gilt folgendes: Der Registrierte Nutzer ist berechtigt, jedweder Änderung oder Ergänzung der Bedingungen bis zum Tag des Inkrafttretens gemäß Ziffer I.12.2.2 nach Erhalt der Mitteilung über eine derartige Änderung oder Ergänzung in Textform zu widersprechen. Erfolgt der Widerspruch rechtzeitig, ist jede Partei berechtigt, den Nutzungsvertrag aus wichtigem Grund zu kündigen; diese Kündigung tritt am Tag des Inkrafttretens der Änderung oder Ergänzung, die Anlass zum Widerspruch gab, in Kraft. Widerspricht der Registrierte Nutzer nicht innerhalb der Widerspruchsfrist, so gilt die Änderung oder Ergänzung als vom Registrierten Nutzer angenommen (Zustimmungsfiktion) und wird Bestandteil des Nutzungsvertrags. In seiner Mitteilung wird der Betreiber den Registrierten Nutzer über das vorstehend aufgeführte Recht innerhalb von vier (4) Wochen zu widersprechen, das Recht beider Parteien, die Bedingungen im Fall eines solchen Widerspruchs zu kündigen und die rechtlichen Folgen bei nicht erfolgtem Widerspruch informieren. Die Zustimmungsfiktion findet keine Anwendung:

			a) bei Änderungen, die die Hauptleistungspflichten des Nutzungsvertrags betreffen, 

			b) bei Änderungen von bereits vereinbarten Entgelten (diesbezüglich gilt Ziffer I.5.3), 

			c) bei Änderungen, die dem Abschluss eines neuen Vertrages gleichkommen, oder 

			d) bei Änderungen, die das bisher vereinbarte Verhältnis von Leistung und Gegenleistung erheblich zugunsten Betreibers verschieben würden; 

			in diesen Fällen wird der Betreiber die Zustimmung des Registrierten Nutzers zu den Änderungen auf andere Weise einholen.

		4. **Ausdrückliche Annahme.** In allen anderen Fällen der Änderung wird der Betreiber dem Registrierten Nutzer eine geänderte Bedingung im Zuge seiner nächsten Anmeldung auf der Plattform oder auf andere geeignete Weise (z.B. mittels eines per E-Mail versandten Links) zur Annahme oder Ablehnung anbieten. Lehnt der Registrierte Nutzer die Änderung ab, hat der Betreiber das Recht, den Nutzungsvertrag durch Kündigung entsprechend Ziffer I.10.2 zu beenden. Es bleibt dem Betreiber unbenommen, diese Art der Vertragsänderung auch bei den unter Ziffer I.12.2.3 geregelten Fällen anzuwenden.
	3. **Rechtsnachfolge / Betreiberwechsel.** Der Betreiber ist berechtigt, seine Position als Vertragspartei der Nutzungsverträge mit allen sich daraus ergebenden Rechten und Pflichten unverändert auf einen Dritten („**Rechtsnachfolger**“) zu übertragen, der fortan als Anbieter der Services auftritt. Der Betreiber wird die Übertragung den Registrierten Nutzer mit Vorlauf von mindestens vier (4) Wochen in Textform (siehe Ziffer I.12.4) unter Angabe des Übertragungszeitpunktes mitteilen. In diesem Fall steht dem Registrierten Nutzer ein sofortiges außerordentliches Kündigungsrecht zu. Widerspricht der Registrierte Nutzer dem Parteiwechsel nicht innerhalb des Zeitraums bis zu seinem Wirksamwerden durch Kündigung, so gilt seine Zustimmung zur Änderung der Vertragspartei als erteilt. In seiner Mitteilung wird der Betreiber den Registrierten Nutzer über das vorstehend aufgeführte Recht, den Nutzungsvertrag zu kündigen, und die rechtlichen Folgen bei nicht erfolgtem Widerspruch informieren. Sofern der Registrierte Nutzer kostenpflichtige Services in Anspruch nimmt, werden ihm etwaige durch die Beendigung zu viel bezahlte Entgelte erstattet.
	4. **Mitteilungen**. Der Registrierte Nutzer erklärt sich einverstanden, dass ihm der Betreiber auf folgende Weise Mitteilungen und Nachrichten zukommen lassen kann: (i) auf der Plattform, etwa durch Einblendung entsprechender Mitteilungen beim Einloggen oder als Nachricht im Nutzerkonto oder (ii) an die vom Registrierten Nutzer bereitgestellten Kontaktangaben (z. B. E-Mail-Adresse, Handy-Nummer, Postanschrift). Der Registrierte Nutzer ist verpflichtet, die Kontaktangaben in seinem/ihrem Nutzerkonto aktuell zu halten.
13. **Schlussbestimmungen**
	1. **Anwendbares Recht und Gerichtsstand**. Auf diese Bedingungen und den Nutzungsvertrag und ihre Auslegung findet ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss seiner Kollisionsnormen Anwendung. Das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Warenkauf findet keine Anwendung. Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit dieser Bedingung ist, soweit rechtlich zulässig, der Sitz des Betreibers, wobei es dem Betreiber jedoch wahlweise gestattet ist, Klage am Sitz des Nutzers einzureichen. 
	1. **Aufrechnung**. Die Aufrechnung des Nutzers gegenüber dem Betreiber ist nur mit einer unbestrittenen oder rechtskräftig festgestellten Gegenforderung des Nutzers zulässig. Entsprechendes gilt für die Ausübung etwaiger Leistungsverweigerungs- oder Zurückbehaltungsrechte des Nutzers gegenüber dem Betreiber. Die Ausübung eines etwaigen Zurückbehaltungsrechts des Nutzers setzt zusätzlich voraus, dass sein Gegenanspruch auf demselben Vertragsverhältnis beruht.
	1. **Verjährung**.  Ansprüche beruhend auf einem vorsätzlichen oder grobfahrlässigen Handeln des Betreibers, eines gesetzlichen Vertreters oder Erfüllungsgehilfen des Betreibers, Ansprüche für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und Ansprüche aufgrund des Produkthaftungsgesetztes verjähren gemäß den gesetzlichen Vorschriften. Für alle übrigen vertraglichen und gesetzlichen Ansprüche gegenüber dem Betreiber beträgt die Verjährungsfrist ein (1) Jahr. Gewährleistungsansprüche bleiben hiervon unberührt.  
	1. **Verbraucherschlichtungsstelle.** Die Europäische Kommission stellt unter http://ec.europa.eu/consumers/odr/ eine Plattform zur Online-Streitbeilegung für Verbraucherstreitigkeiten bereit. Der Betreiber ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle jedoch weder bereit noch verpflichtet.
	1. **Salvatorische Klausel.** Sollten einzelne Bestimmungen dieser Bedingungen unwirksam oder nicht durchsetzbar sein oder werden, so berührt dies die Wirksamkeit bzw. Durchsetzbarkeit der übrigen Bestimmungen eines auf Basis dieser Bedingungen zwischen dem Betreiber und dem Registrierten Nutzer abgeschlossenen Vertrags nicht. Die Parteien werden eine unwirksame oder nicht durchsetzbare Bestimmung oder Regelungslücke durch eine wirksame und durchsetzbare Bestimmung ersetzen, die dem wirtschaftlichen Zweck der unwirksamen oder nicht durchsetzbaren Bestimmung am nächsten kommt.



## II - BESONDERE NUTZUNGSBEDINGUNGEN FÜR KOSTENPFLICHTIGE SERVICES
1. **Anwendungsbereich**
	1. Der Betreiber hat bestimmte Services kostenpflichtig ausgestaltet. In diesem Fall können die Registrierten Nutzer der zum Erwerb der Services zugelassenen Nutzergruppen die Leistung nur gegen Zahlung des entsprechenden Entgelts unter Nutzung der vom Betreiber angebotenen Zahlungsmethoden in Anspruch nehmen. Der Registrierte Nutzer wird vor Nutzung oder Freischaltung der entsprechenden Services auf deren Kostenpflichtigkeit hingewiesen. Es gelten jeweils die Preise, wie sie bei der Buchung bzw. Verlängerung der entsprechenden Services veröffentlicht sind bzw. dem Nutzer angezeigt werden.
	1. Die Bestimmungen dieser Ziffer II gelten ausschließlich für Registrierte Nutzer, die kostenpflichtige Services der Plattform gemäß den Bedingungen nutzen („**Zahlende Nutzer**“). Die Allgemeinen Nutzungsbedingungen in Ziffer I gelten in Ergänzung zu den Bestimmungen dieser besonderen Nutzungsbedingungen für kostenpflichtige Leistungen dieser Ziffer II, welche insoweit Vorrang haben, als es ihren Anwendungsbereich betrifft. 
2. **Mitgliedschaft** 

	**Mitgliedschaften.** Zahlenden Nutzern, die eine kostenpflichtige Mitgliedschaft gemäß dieser Ziffer II.2 abgeschlossen haben („**Mitgliedschaft**“), sind alle auf der Plattform für die jeweilige Nutzergruppe unter der jeweils gebuchten Mitgliedschaft zum jeweiligen Zeitpunkt verfügbaren kostenpflichtigen Services, einschließlich der Bezahlten Inhalte (wie in Ziffer II.6.1 definiert), sowie erweiterten Funktionalitäten der Share Services („**Kostenpflichtige Services**“) zugänglich. Die Mitgliedschaft ist ein Vertrag zwischen dem Betreiber und dem jeweiligen Zahlenden Nutzer.

	1. **Buchung der Mitgliedschaft.** Mitgliedschaften können nur von eingeschränkten Nutzergruppen abgeschlossen werden. Der Abschluss von Mitgliedschaften erfolgt  über die entsprechenden Funktionen der Plattform oder durch schriftliche Vereinbarung zwischen dem Zahlenden Nutzer und dem Betreiber. Im Falle des Abschlusses der Mitgliedschaft über die Plattform gibt ein Nutzer alle für den Abschluss der Mitgliedschaft erforderlichen Daten (Name, Vorname, Name der Institution) in die jeweiligen Masken ein, schließt die Registrierung durch Betätigung des entsprechend beschrifteten Buttons ab und wird zur Zahlung an den vom Betreiber eingesetzten Zahlungsdienstleister weitergeleitet. Das neue Mitglied erhält im Anschluss eine E-Mail-Bestätigung über den Abschluss der Mitgliedschaft. 
	2. **Laufzeit.** Sofern zwischen dem Zahlenden Nutzer und dem Betreiber nicht anderweitig schriftlich vereinbart, haben Mitgliedschaften die jeweils im Rahmen ihres Abschlusses auf der Plattform angegebene anfängliche Laufzeit. Soweit sie nicht durch den Zahlenden Nutzer oder Betreiber spätestens vor Ablauf der anfänglichen oder verlängerten Laufzeit in Textform (z.B. per E-Mail) oder mittels einer auf der Plattform angebotenen Kündigungs-Schaltfläche gekündigt wird, verlängert sich die Mitgliedschaft automatisch um einen weiteren Monat. Gesetzliche Rechte beider Parteien zur außerordentlichen Kündigung aus wichtigem Grund und des Zahlenden Nutzers zur vorzeitigen Vertragsbeendigung aus Mängelgewährleistung bleiben unberührt. 
	3. **Mitgliedschaftsbeitrag.** Für die Mitgliedschaft zahlt der Zahlende Nutzer jeweils die bei Abschluss der Mitgliedschaft auf der Plattform ausgewiesenen Vergütungsbeträge (der „**Mitgliedschaftsbeitrag**“). Die auf der Plattform für Mitgliedschaften angegebenen Beträge verstehen sich in Euro und einschließlich der jeweils gültigen gesetzlichen Mehrwertsteuer. Der Mitgliedschaftsbeitrag wird mit Abschluss bzw. Verlängerung einer Mitgliedschaft für die jeweilige Laufzeit der Mitgliedschaft insgesamt im Voraus fällig. 
3. **Einzelkäufe**
	1. **Vertragsschluss**. Aus dem Gesamtprogramm des Betreibers können entsprechende gekennzeichnete Kostenpflichtige Services (z.B. Lizenzen für einzelne Kurse) oder  downloadfähige Bezahlte Inhalte (wie in Ziffer II.6.1 definiert) einzeln erworben werden. Der Vertrag zwischen dem Zahlenden Nutzer und dem Betreiber kommt mit der verbindlichen Bestellung des ausgewählten Kostenpflichtigen Service oder der downloadfähigen Bezahlten Inhalte und der Bestätigung der Buchung / des Kaufs über die entsprechenden Funktionen der Plattform oder durch schriftliche Vereinbarung zwischen dem Zahlenden Nutzer und dem Betreiber zustande. Im Falle des Erwerbs über die Plattform gibt der Zahlender Nutzer alle für den Abschluss der Buchung / des Kaufs erforderlichen Daten (Name, Vorname, Name der Institution) in die jeweiligen Masken ein, schließt die Buchung / den Kauf durch Betätigung des entsprechend beschrifteten Buttons ab und wird zur Zahlung an den vom Betreiber eingesetzten Zahlungsdienstleister weitergeleitet. Im Anschluss erhält der Zahlende Nutzer eine E-Mail-Bestätigung über den Abschluss der Buchung / des Kaufs.
	2. **Zugang**. Nachdem die Buchung / der Kauf erfolgreich durchgeführt wurde und die Zahlung erfolgt ist, steht dem Zahlenden Nutzer der Zugang zum jeweils gebuchten Kostenpflichtigen Service und/oder der Download etwaiger downloadfähiger Bezahlter Inhalte unmittelbar zur Verfügung. 
	3. **Laufzeit der im Einzelkauf erworbenen Kostenpflichtigen Services**. Sofern zwischen dem Zahlenden Nutzer und dem Betreiber nicht anderweitig schriftlich vereinbart, haben die im Wege des Einzelkauf erworbenen Kostenpflichtigen Services jeweils den im Rahmen ihres Abschlusses auf der Plattform angegebenen Umfang und sind, sofern im Rahmen des Abschlusses des jeweiligen Kostenpflichtigen Services auf der Plattform nicht anders angegebenen für mindestens dreißig (30) Tage abrufbar bzw. bei als downloadfähig gekennzeichneten Bezahlten Inhalt herunterladbar. Gesetzliche Rechte beider Parteien zur außerordentlichen Kündigung aus wichtigem Grund und des Zahlender Nutzers zur vorzeitigen Vertragsbeendigung aus Mängelgewährleistung bleiben unberührt.  Der Zugang zu den im Wege des Einzelkauf erworben Kostenpflichtigen Services endet mit Ablauf der im Rahmen ihres Abschlusses auf der Plattform angegebene Laufzeit. Eine automatische Verlängerung der Laufzeit erfolgt nicht. Möchte der Zahlende Nutzer den jeweiligen Kostenpflichtigen Service weiterhin in Anspruch nehmen, kann dieser - vorbehaltlich seiner Verfügbarkeit – nach Ende der Laufzeit vom Zahlenden Nutzer neu erworben werden.  
	4. **Gefahrenübergang bei downloadfähigen Bezahlten Inhalten**. Sofern der Zahlende Nutzer im Wege des Einzelkaufs als solche gekennzeichnete downloadfähige Bezahlte Inhalte erworben hat (Kauf digitaler Produkte), die der Betreiber unmittelbar zur Verfügung gestellt hat, geht das Risiko für den erfolgreichen Abschluss der Download-Vorgänge des Zahlenden Nutzers sowie für den Verlust Bezahlter Inhalte, die der Zahlende Nutzer heruntergeladen hat, einschließlich eines Verlustes aufgrund einer Dateibeschädigung oder eines Geräte- oder Festplattenabsturzes, auf den Zahlenden Nutzer über. Erworbene Bezahlte Inhalte stehen in der Regel weiterhin über das Nutzerkonto des Zahlenden Nutzers zur Verfügung, können aber aufgrund möglicher lizenzrechtlicher Beschränkungen von Inhaltsanbietern oder aus anderen Gründen nicht mehr verfügbar sein. Betreiber haftet dem Zahlenden Nutzer gegenüber nicht, wenn Bezahlte Inhalte für einen weiteren Download nicht mehr zur Verfügung stehen. Eine vollständige oder teilweise Rückzahlung von bereits bezahlten Gebühren ist in diesem Fall nicht möglich. Dementsprechend obliegt es dem Zahlenden Nutzer, Bezahlte Inhalte nach dem Kauf unverzüglich herunterzuladen und Sicherungskopien anzufertigen. Wenn der Zahlende Nutzer einen Download auch nach Durchsicht unserer Online-Hilfe-Seiten nicht durchführen kann, ist der Kundenservice des Betreibers zu kontaktieren. Zahlende Nutzer in der Europäischen Union haben für einen Zeitraum von zwei (2) Jahren ab der Lieferung der Ware Gewährleistungsrechte, wenn die beim Betreiber erworbene Ware sich als mangelhaft oder nicht wie beschrieben erweist. 
	5. **Preise.**	 Die Preise der im Wege des Einzelkaufs / der Buchung erworbenen Kostenpflichtigen Services und downloadfähigen Bezahlten Inhalten ergeben sich aus den Preisangaben bei den jeweiligen Kostenpflichtigen Services oder downloadfähigen Bezahlten Inhalten. Sämtliche Preise verstehen sich inklusive der gesetzlichen Mehrwertsteuer.
4. **Zahlungsmodalitäten.** 
	1. Sofern zwischen dem Zahlenden Nutzer und dem Betreiber nicht anderweitig schriftlich vereinbart, erfolgt der Zahlungsprozess für den angegebenen Mitgliedschaftsbeitrag bzw. den angegebenen Kaufpreis der Einzelkäufe mittels der auf der Plattform angegebenen Zahlungsoptionen über externe Zahlungsdienstleister, an die der Zahlende Nutzer von der Plattform gegebenenfalls weitergeleitet wird. Für die Zahlung gelten die allgemeinen Geschäftsbedingungen des Zahlungsdienstleisters. Weitere Details dazu können auf der Unterseite [Zahlung und Versand](/payment-terms) eingesehen werden. Eine Stornierung von Bestellvorgängen ist nur möglich, soweit Kostenpflichtige Services noch nicht genutzt wurden und Bezahlte Inhalte noch nicht vollständig heruntergeladen wurden. 
	2. Wurden oder werden Kostenpflichtige Services vom Registrierten Unternehmen zur Nutzung durch die Unternehmensgruppe erworben, hat das Registrierte Unternehmen das Recht, den Zugriff durch Registrierte Nutzer dieser Unternehmensgruppe auf solche Kostenpflichtigen Services zu kontrollieren. Bei Nichtzahlung des Entgelts für gebuchte Kostenpflichtigen Services durch das Registrierte Unternehmen werden die entsprechenden Services – unbeschadet etwaiger weiterer Ansprüche des Betreibers – für die jeweiligen Registrierten Nutzer der Unternehmensgruppe nicht erbracht.
	3. Sofern nicht anderweitig schriftlich vereinbart (E-Mail ist ausreichend), kann der Zahlende Nutzer, der Kostenpflichtige Services erworben hat oder im Falle einer Unternehmensgruppe, das Registrierte Unternehmen, das Kostenpflichtige Services erworben hat unter „Mein Konto” eine Übersicht seiner getätigten Bestellungen einsehen und drei (3) Monate lang die Belege herunterladen. Eine weitere Rechnungslegung findet nicht statt.  
5. **Zugang zu Inhalten und Funktionen**
	1. **Zugang.** Die Kostenpflichtigen Services werden Zahlenden Nutzern über deren Nutzerkonto auf der Plattform unmittelbar nach der Buchung der Kostenpflichtigen Services** gemäß Ziffer II.2** zugänglich gemacht. Zur Nutzung bestehen gegebenenfalls technische Voraussetzungen, die durch Endgerät, Software und Internetverbindung des Nutzers erfüllt sein müssen. Diese technischen Voraussetzungen können auf der Plattform eingesehen werden. Der Download und die dauerhafte Speicherung der Inhalte des Kostenpflichtigen Services auf Endgeräten von Nutzern ist ausschließlich in dem in diesen Bedingungen und den auf die jeweiligen Drittinhalte anwendbaren Drittbedingungen angegebenen Umfang gestattet. 
	2. **Aktualisierungen.** Der Betreiber stellt sicher, dass er während der Laufzeit der Mitgliedschaft Aktualisierungen (einschließlich Sicherungsaktualisierungen) vornimmt, die erforderlich sind, um die Vertragsmäßigkeit der Kostenpflichtigen Services zu erhalten. Hierüber wird der Zahlende Nutzer entsprechend Ziffer I.12.4 in Kenntnis gesetzt. 
6. **Nutzungsrechte** 
	1. **Nutzungsrecht.** Zahlende Nutzer erhalten für die Dauer der gebuchten Kostenpflichtigen Services oder, im Falle einer Mitgliedschaft, für die Dauer ihrer Mitgliedschaft ein beschränktes, nicht-ausschließliches, nicht übertragbares und nicht unterlizenzierbares Recht, die vom jeweiligen Zahlenden Nutzer erworbenen und auf der Plattform zum jeweiligen Zeitpunkt für den Zahlenden Nutzer bereitgestellten Inhalte („**Bezahlte Inhalte**“) im Rahmen der von der Plattform zur Verfügung gestellten technischen Möglichkeiten nur für eigene, interne Zwecke (i) auf ihren Endgeräten unverändert abzurufen und anzusehen und (ii) soweit die Funktionalitäten der Plattform dies im Einzelfall ermöglichen (bspw. Teilen von Inhalten mit Schülern) und dies durch diese Bedingungen oder im Falle von Drittinhalten durch die auf diese Drittinhalte anwendbaren Drittbestimmungen (jeweils als solche gekennzeichnet) oder nach zwingendem anwendbarem Recht gestattet ist, die jeweiligen Bezahlten Inhalte herunterzuladen, zu vervielfältigen und zu nutzen. 
	2. **Anderweite Verwendung.** Jegliche anderweitige Verwendung der Plattforminhalte bedarf der vorherigen ausdrücklichen schriftlichen Zustimmung des Betreibers. Insbesondere ist es den Nutzern untersagt, Plattforminhalte zu eigenen kommerziellen oder nicht-kommerziellen Zwecken oder für Dritte zu bearbeiten, zu ändern (sowie sonstige Umgestaltung), in eine andere Ausdrucksform umzuwandeln (insbesondere Reverse Engineering oder Dekompilieren), zu übersetzen, zu veröffentlichen oder sonst zu verbreiten oder zu verwerten oder Dritten für jegliche Nutzung zugänglich zu machen oder zur Verfügung zu stellen, sowie Nutzungsrechte zu übertragen und zeitlich und inhaltlich beschränkte oder unbeschränkte Unterlizenzen zu erteilen, soweit dies nicht durch die Funktionen der Plattform unterstützt wird (bspw. im Rahmen der Share Services nach I.2.1.2) und durch diese Bestimmungen oder im Falle von Drittinhalten durch die auf diese Drittinhalte anwendbaren Drittbestimmungen (jeweils als solche gekennzeichnet) oder zwingende gesetzliche Regelungen ausdrücklich gestattet ist. 
7. **Schutzrechtsverletzung/ Freistellungsanspruch**
	1. **Schutzrechtsverletzung.** Macht ein Dritter gegenüber dem Nutzer Ansprüche wegen der Verletzung eines gewerblichen Schutzrechts oder Urheberrechts durch die Nutzung der Kostenpflichtigen Services oder der Bezahlten Inhalte geltend und wird deren Nutzung hierdurch beeinträchtigt oder untersagt, gelten die nachfolgenden Bestimmungen.
	2. **Änderung der vereinbarten Leistungen.** Der Betreiber wird nach seiner Wahl und auf seine Kosten entweder die vereinbarten Leistungen so ändern oder ersetzen, dass sie das Schutzrecht nicht verletzen, aber im Wesentlichen der vereinbarten Leistung in für den Kunden zumutbarer Weise entsprechen oder den Kunden von Lizenzentgelten gegenüber dem Schutzrechtsinhaber oder Dritten freistellen. 
	3. **Haftungsvoraussetzungen.** Voraussetzungen für die Haftung nach Ziffer II.7.1 sind, dass der Zahlende Nutzer den Betreiber von der Geltendmachung von Ansprüchen Dritter unverzüglich verständigt, die behauptete Schutzrechtsverletzung nicht anerkennt und jegliche Auseinandersetzung einschließlich etwaiger außergerichtlicher Regelungen dem Betreiber überlässt oder nur im Einvernehmen mit diesem führt. Stellt der Zahlende Nutzer die Nutzung aus Schadensminderungs- oder sonstigen wichtigen Gründen ein, ist er verpflichtet, den Dritten darauf hinzuweisen, dass mit der Nutzungseinstellung ein Anerkenntnis der behaupteten Schutzrechtsverletzung nicht verbunden ist.
	4. **Haftungsausschluss.** Soweit der Zahlende Nutzer die Schutzrechtsverletzung zu verschulden hat oder der Anspruch Dritter darauf beruht, dass der vom Betreiber geschuldete Leistungsinhalt ohne dessen Kenntnis geändert, auf eine sonstige Art und Weise bearbeitet und nicht mit den vom Betreiber zur Verfügung gestellten Leistungen genutzt wurde, sind Ansprüche gegen den Anbieter ausgeschlossen. 
	5. **Geltung anderer Haftungsregelungen.** Soweit anwendbar bleiben gesetzlich zwingende Haftungsregelungen bzw. Ziffer I.7.6 hiervon unberührt.
8. **Widerrufsbelehrung**

	Ist der Zahlende Nutzer Verbraucher (§ 13 BGB) und wird ein Vertrag über   Kostenpflichtige Services dieser Ziffer II im Wege des Fernabsatzes gemäß § 312c BGB geschlossen, gelten für diesen Vertrag die Bestimmungen der Ziffer I.11.

## III - BESONDERE NUTZUNGSBEDINGUNGEN FÜR CONTENT CREATOR
1. **Anwendungsbereich; Vertragsschluss**
	1. **Geltung.**  Sofern Registrierte Nutzer über die entsprechenden Funktionen der Plattform E-Learnings erstellen oder selbst oder durch den Betreiber auf der Plattform zur Verfügung stellen („**Content Creation**“) gelten die Bestimmungen dieser Ziffer III zusätzlich zu den allgemeinen Nutzungsbedingungen gemäß Ziffer I und gehen diesen insoweit vor, als es ihren Anwendungsbereich betrifft. 
	2. **Content Creator.** Der Betreiber behält sich vor, die Content Creation Services lediglich eingeschränkte Nutzergruppen oder einzelnen Registrierten Nutzern zur Verfügung zu stellen („**Content Creator**“).
	3. **Vertragsschluss.** Mit der Content Creation (wie in Ziffer III.2.1 definiert) kommt zwischen dem Content Creator und dem Betreiber ein Vertrag über die Nutzung der Content Creation Services in Bezug auf den jeweils Bereitgestellten Content zustande („**Content Creation Vertrag**“). 
2. **Pflichten des Content Creator**
	1. **Verantwortlichkeit**. Der Content Creator ist für die bereitgestellten Inhalte, einschließlich Lektionen, Quizze, Programmierübungen, Praxistests, Aufgaben, Materialien, Antworten, Content der Kurs-Landing-Page, Labs, Wissenstests und Mitteilungen sowie jeglichen Verlinkungen auf Drittseiten oder die Einbindung von Drittinhalten über embedding oder framing sowie für die Inhalte seines eigenen Profils („**Bereitgestellter Content**“) allein verantwortlich. Er stellt sicher, dass die von ihm erbrachten Leistungen auf dem branchespezifischen Stand sind und den allgemeinen Standards von Lehrinhalten entsprechen. Der Betreiber prüft den vom Content Creator Bereitgestellten Content nicht und ist zur anlasslosbezogenen Prüfung auch nicht verpflichtet.  
	2. **Qualifikation des Content Creator**. Der Content Creator sichert dem Betreiber zu, dass er über die erforderlichen Qualifikationen, Nachweise und Fachkenntnisse (einschließlich der erforderlichen Ausbildung, Schulung und Fähigkeiten) verfügt, um den Unterricht zu halten und die Leistungen zu erbringen, die er im Rahmen des Bereitgestellten Contents anbietet. Der Betreiber behält sich das Recht vor die Qualifikation des Content Creator durch Vorlage entsprechender Nachweise zu überprüfen und bei Nichtvorliegen oder Unstimmigkeiten den Content Creation Vertrag zu kündigen.  
	3. **Pflichten in Bezug auf Bereitgestellten Content.** Der Content Creator sichert dem Betreiber zu, dass 
		1. er Eigentümer des Bereitgestellten Contents ist und über alle erforderlichen Zustimmungen, Lizenzen oder Genehmigungen verfügt, um Betreiber zur Nutzung des Bereitgestellten Contents gemäß den Bedingungen zu ermächtigen;
		2. der  Bereitgestellte Content keine Rechte Dritter, einschließlich Rechte am geistigen Eigentum und Persönlichkeitsrechte Dritter verletzt 
		3. der Bereitgestellte Content nicht gegen anwendbares Recht, einschließlich der Regelungen des Telemediengesetzes und des Jugendschutzgesetzes, Berufsordnungen und Verhaltenskodexes verstößt; und 
		4. der Bereitgestellte Content frei von unangemessenen, beleidigenden, rassistischen, aufhetzenden, extremistischen, sexistischen, pornografischen, falschen, irreführenden oder rechtsverletzenden Inhalten ist und keine Anzeigen, Werbematerialien oder sonstigen (kommerzielle) Aufforderungen enthält. 
3. **Nutzungsrechte**
	1. **Einräumung von Nutzungsrechten.** Mit der Einstellung des Bereitgestellten Contents auf der Plattform oder Übermittlung des Bereitgestellten Contents an den Betreiber räumt der Content Creator dem Betreiber an dem Bereitgestellten Content hiermit das weltweite, nicht-ausschließliche, unterlizenzierbare, gebührenfreie und vollständig abgegoltene Recht ein, den Bereitgestellten Content (i) zum Zweck des Angebots, der Erbringung, der Vermarktung, der Bewerbung, der Demonstration und dem Verkauf der Services oder der Plattform zu nutzen, einschließlich des Rechts, den Bereitgestellten Content zu vervielfältigen, zu verbreiten und anderen Nutzern und Dritten öffentlich zugänglich zu machen, soweit dies für die Bereitstellung der Services durch den Betreiber erforderlich ist, sowie (ii) zu bearbeiten, anzupassen oder zu ergänzen, zu übersetzen, mit anderen Werken und Leistungen zu verbinden und daraus andere Werke abzuleiten oder erstellen zu lassen. Der Betreiber wird die vom Content Creator erstellten Inhalte nicht für andere Zwecke als die hier genannten verwenden. Der Betreiber erhebt keinen Eigentumsanspruch auf die vom Content Creator erstellten Inhalte. Der Betreiber ist ferner berechtigt, den Bereitgestellten Content im Rahmen von Unterlizenzen direkt oder über Dritte bereitzustellen. 
	2. **Persönlichkeitsrechte**. Der Content Creator erteilt dem Betreiber hiermit die Erlaubnis, seinen Namen, sein Bild, sein Foto und/oder seine Stimme in Verbindung mit dem Angebot, der Erbringung, der Vermarktung, der Bewerbung, der Demonstration und dem Verkauf der Services in Bezug auf den Bereitgestellten Content zu verwenden.
	3. **Marken des Betreibers.** Der Content Creator ist berechtigt, die Marke des Betreibers zu verwenden, wenn ihm dies für einzelne Fälle ausdrücklich und mindestens in Textform gestattet ist. Die Marke darf ausschließlich im Zusammenhang mit der Bewerbung des Bereitgestellten Contents verwendet werden. Auf keinen Fall darf die Marke auf irreführende, herabsetzende oder rechtswidrige Weise verwendet werden. Im Übrigen gelten die Regelungen unter Ziffer I. Der Betreiber behält sich vor, jederzeit und ohne Angabe von Gründen eine Einstellung der Verwendung zu verlangen.
4. **Freistellung**
	1. **Haftungsfreistellung.** Unbeschadet der anderweitig bestehenden Haftung des Content Creators wird der Content Creator den Betreiber auf erstes Anfordern von sämtlichen Ansprüchen freistellen, die andere Nutzer oder sonstige Dritte gegenüber dem Betreiber geltend machen wegen eines Verstoßes des Content Creators gegen die Verpflichtungen und Zusicherungen des Content Creators gemäß Ziffer III.2. 
	2. **Prozedere**. Der Nutzer übernimmt im Rahmen der Freistellungen nach Ziffer III.4.1 die Kosten der notwendigen Rechtsverteidigung des Betreibers einschließlich sämtlicher Gerichts- und Anwaltskosten in gesetzlicher Höhe. Dies gilt nicht, wenn und soweit der Content Creator die jeweilige Verletzung nicht zu vertreten hat. Der Content Creator ist verpflichtet, dem Betreiber für den Fall einer Inanspruchnahme durch Dritte unverzüglich, wahrheitsgemäß und vollständig alle Informationen zur Verfügung zu stellen, die für die Prüfung der Ansprüche und eine Verteidigung erforderlich sind.
5. **Entgelt** 

	Sofern zwischen dem Content Creator und dem Betreiber im Einzelfall nicht anderweitig in Textform vereinbart, ist die Nutzung (i) der Content Creation Services für den Content Creator derzeit kostenlos, und (ii) des Bereitgestellten Contents durch den Betreiber in dem in diesen Bedingungen angegebenen Umfang für den Betreiber ebenfalls kostenlos.  

6. **Laufzeit und Kündigung** 
	1. **Laufzeit.** Der Content Creation Vertrag läuft auf unbestimmte Zeit und endet spätestens mit der Schließung bzw. Löschung des Nutzerkontos des Content Creators, sofern er nicht vorher gemäß Ziffer III.6.2 gekündigt wird. 
	2. **Kündigung.** Der Content Creation Vertrag kann (i) vom Betreiber unter Einhaltung einer Frist von einem (1) Monat zum Ende eines Kalendermonats und (ii) vom Content Creator jederzeit ordentlich gekündigt werden. Der Content Creator kündigt den Content Creation Vertrag durch Mitteilung in Textform (z.B. per E-Mail) oder mittels einer auf der Plattform ggf. angebotenen Kündigungs-Schaltfläche. Das Recht jeder Partei, einen Content Creation Vertrag bei Vorliegen der gesetzlichen Voraussetzungen aus wichtigem Grund zu kündigen, bleibt unberührt. Zur außerordentlichen fristlosen Kündigung ist der Betreiber insbesondere berechtigt, wenn der Bereitgestellte Content (i) nicht etwaigen vom Betreiber bereitgestellten Richtlinien, gesetzlichen Bestimmungen oder diesen Bedingungen entspricht, (ii) Rechte Dritter verletzt, oder (iii) nicht den Qualitätsstandards des Betreibers entspricht oder negative Auswirkungen auf Nutzer hat. Eine Kündigung durch den Betreiber erfolgt durch Mitteilung gemäß Ziffer I.12.4 Der Betreiber ist zur Schließung des Nutzerkontos des Content Creators berechtigt, sofern (i) ein Verstoß gegen die in III.2bezeichneten Verpflichtungen vorliegt oder begründet vermutet wird, (ii) das Risiko besteht, dass der Betreiber oder die Plattform aufgrund des Verhaltens des Content Creators in einem negativen Licht erscheinen oder öffentlich in Verruf geraten oder lächerlich gemacht werden könnten, (iii) die Nutzung der Services durch einen Content Creator auf eine Weise, die unlauterem Wettbewerb entspricht, zum Beispiel durch Bewerbung eines Geschäfts außerhalb der Plattform. Der Betreiber behält sich außerdem das Recht vor, den Content Creator bei wiederholten Verstößen zu sperren und seine Teilnahmemöglichkeit an den Services der Plattform auszuschließen. 
	3. **Folgen der Kündigung**. Im Falle der ordentlichen Kündigung durch den Content Creator oder den Betreiber erlöschen die Rechte des Betreibers gemäß Ziffer III.3.1 und Ziffer III.3.2 zur Vergabe von Unterlizenzen an neue Nutzer dreißig (30) Tage nach Wirksamwerden der Kündigung. Jegliche (i) vor Wirksamwerden der Kündigung gewährten Rechte haben jedoch im Einklang mit den Bestimmungen der jeweiligen dem Nutzer gewährten Lizenzen weiterhin Bestand, und (ii) das Recht des Betreibers zur Nutzung des betreffenden Bereitgestellten Contents für Marketingzwecke erlischt ein (1) Jahr nach Kündigung. Im Falle der Kündigung des Content Creation Vertrags oder Schließung des Nutzerkontos durch den Betreiber aus wichtigem Grund ist der Betreiber berechtigt die betroffenen Bereitgestellten Inhalte mit sofortiger Wirkung zu sperren. 


Version 2.0 vom 11.07.2023

`,
};

export default TermsOfServiceView;
