import React from 'react';
import styled from 'styled-components';
import { useNavigate } from '../utils/hooks';
import { Typography, Box } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';

const TxtLimit = styled.div<{ $numberOfLines: number }>`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: ${(p) => p.$numberOfLines};
	-webkit-box-orient: vertical;
`;

export interface TxtProps extends TypographyProps {
	label?: string;
	m?: string;
	numberOfLines?: number;
	lineBreaks?: boolean;
	flex?: string;
	navigateTo?: string;
	navigateToExternal?: boolean;
	onClick?: () => void;
	noUnderLine?: boolean;
	color?: string;
	span?: boolean;
}

const StyledBox = styled(Box)<{ $clickable?: string; $noUnderLine?: boolean; $color: string }>`
	cursor: ${(p) => (p.$clickable ? 'pointer' : '')};
	text-decoration: ${(p) => (p.$clickable && !p.$noUnderline ? 'underline' : '')};
	text-decoration-color: ${(p) => p.$color};
`;

const Txt = ({
	label,
	m,
	children,
	numberOfLines,
	lineBreaks,
	navigateTo,
	navigateToExternal,
	onClick,
	noUnderLine,
	color,
	span, // this is necessary to support for markdown
	width,
	...rest
}: TxtProps) => {
	const navigate = useNavigate();
	const handleNavigate = React.useCallback(() => {
		if (navigateTo?.includes('_blank')) {
			const path = navigateTo.replace('_blank', '');
			window.open(path, '_blank');
		} else if (navigateTo?.includes('mailto:')) {
			// @ts-ignore
			window.location = navigateTo;
		} else if (navigateToExternal) {
			window.open(navigateTo!, '_blank');
		} else {
			navigate(navigateTo!);
		}
	}, [navigate, navigateTo, navigateToExternal]);

	const handleClick = React.useCallback(() => {
		onClick?.();
	}, [onClick]);

	return (
		<StyledBox
			m={m}
			whiteSpace={lineBreaks ? 'pre-line' : 'normal'}
			onClick={navigateTo ? handleNavigate : handleClick}
			$clickable={navigateTo || onClick}
			$noUnderline={noUnderLine}
			$color={color}
			component={span ? 'span' : 'div'} // this is necessary to support for markdown
			width={width}
		>
			{numberOfLines ? (
				<TxtLimit $numberOfLines={numberOfLines}>
					<Typography component={span ? 'span' : 'div'} color={color} {...rest}>
						{label || children}
					</Typography>
				</TxtLimit>
			) : (
				<Typography
					component={span ? 'span' : 'div'} // this is necessary to support for markdown
					color={color}
					{...rest}
				>
					{label || children}
				</Typography>
			)}
		</StyledBox>
	);
};

export default React.memo(Txt);
